import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CARACTERISTICAS_CREDITO, CARACTERISTICAS_DEBITO, PREGUNTAS, TARIFAS_CREDITO, TARIFAS_DEBITO } from "src/app/common/constants/pepper-debito.constants";
import { ApiOfertasService } from "src/app/services/api-ofertas/api-ofertas.service";
import { ApiPepperBackendService } from "src/app/services/api-pepper-backend/api-pepper-backend.service";
import { NavigateService } from "src/app/services/navigate/navigate.service";
import { ParametrosService } from "src/app/services/parametros/parametros.service";
import { PepperService } from "src/app/services/pepper/pepper.service";
import { ReportesService } from "src/app/services/reportes/reportes.service";
import { UtilsService } from "src/app/services/utils/utils.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-tipo-tarjeta',
  templateUrl: './tipo-tarjeta.component.html',
  styleUrls: ['./tipo-tarjeta.component.scss']
})
export class TipoTarjetaComponent implements OnInit, OnDestroy {

  @ViewChild('viewCuentaSimple') content!: ElementRef;

  subscriptionKey: string = environment.subscriptionKeyWebComponents.cuentaSimple.value;
  commerceId: string = environment.subscriptionKeyWebComponents.cuentaSimple.commerceId;
  credential: string = environment.subscriptionKeyWebComponents.cuentaSimple.credential;

  tarjetaDebitoActiva: boolean = false;
  tarjetaCreditoActiva: boolean = false;
  aprobados: boolean = false;
  isMemoria = false;

  titulo: string = '';
  jwt: string = '';
  amout: string = '0';
  legales: string = ''

  loader = true;
  tycDataModal: boolean = false;

  private element: HTMLElement | null = null;

  activeTab: string = 'caracteristicas';

  caracteristicasDebito = CARACTERISTICAS_DEBITO;
  caracteristicasCredito = CARACTERISTICAS_CREDITO;

  tarifasDebito = TARIFAS_DEBITO;
  tarifasCredito = TARIFAS_CREDITO;

  preguntasCredito = PREGUNTAS;

  constructor(
    private multiOfertas: ApiOfertasService,
    private pepper: PepperService,
    private navigate: NavigateService,
    private parametros: ParametrosService,
    private reportes: ReportesService,
    private utils: UtilsService,
    private backendService: ApiPepperBackendService
  ) { }

  async ngOnInit() {
    const multiOffers = this.multiOfertas.multiOffers;
    this.aprobados = multiOffers!.offerTC && multiOffers!.offerTD;
    this.amout = multiOffers?.amount ? multiOffers?.amount : '0';
    this.validarCodigoEnMemoria();
    if (this.aprobados && this.isMemoria){
      this.titulo = '<strong>¡Tenemos un producto para ti!</strong>';
    }else{
      this.titulo = this.aprobados ? `<strong>¡Tienes una Tarjeta <br> de Crédito y Débito aprobada!</strong>` : '<strong>¡Tenemos un producto para ti!</strong>';
    }

    if (this.aprobados) {
      this.tarjetaCreditoActiva = true;
      this.tarjetaDebitoActiva = false;
    } else {
      this.tarjetaCreditoActiva = multiOffers!.offerTC;
      this.tarjetaDebitoActiva = multiOffers!.offerTD;
    }
    this.validarCliente()
    await this.generarToken();
    this.listenerIbkAccount();
    await this.generarReporte();
  }

  validarCliente() {
    if (this.pepper.validateCampaign()) {
      this.tarjetaDebitoActiva = false;
      this.aprobados = false;
    }
  }

  btnCredito() {
    this.tarjetaCreditoActiva = true;
    this.tarjetaDebitoActiva = false;
  }

  btnDebito() {
    this.tarjetaCreditoActiva = false;
    this.tarjetaDebitoActiva = true;
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  reloadApp(): void {
    window.location.reload()
  }

  handleTycDataCloseModal() {
    this.tycDataModal = false
  }

  openTycDataModal(e: Event) {
    e.preventDefault()
    this.tycDataModal = true
  }

  getContentHubData(fieldName: string, fieldKey: string, onErrorValue: string = '') {
    try {

      let chData: any = this.pepper.getContentData()

      if (!chData[fieldName]) return onErrorValue
      chData = chData[fieldName]

      if (!chData[fieldKey]) return onErrorValue

      return chData[fieldKey]
    } catch (e) {
      return onErrorValue;
    }
  }

  get contentTerminosyCondiciones() {
    return this.tarjetaCreditoActiva ? this.parametros.config['TerminosyCondiciones'] : this.parametros.config['TerminosyCondicionesDebito']
  }

  get contentLegales() {
    return this.tarjetaCreditoActiva ? this.getContentHubData('partner_legal', 'value', '') : this.getContentHubData('partner_legal_debit', 'value', '');
  }

  get contentDescuento() {
    return this.tarjetaCreditoActiva ? this.getContentHubData('partner_principal_benefit', 'value', '') : this.getContentHubData('partner_principal_benefit_debit', 'value', '');
  }

  get tipoDescuento() {
    return (this.contentDescuento as string).split(' ')
  }

  get contentRequerimiento() {
    return this.tarjetaCreditoActiva ? this.getContentHubData('partner_principal_benefit_requirement_tc', 'value', '') : this.getContentHubData('partner_principal_benefit_requirement_td', 'value', '');
  }

  get businessName() {
    return this.pepper.getSelectedStore()?.toUpperCase()
  }

  toggleVisibility(index: number): void {
    this.preguntasCredito[index].visible = !this.preguntasCredito[index].visible;
  }

  async obtenerTarjetaCredito() {
    await this.reportes.addToReport({
      req_status: 'Finalizado',
      properties: {
       tarjetaSeleccion: 'Credito'
      }
    });
    this.reportes.restartReqId();
    this.navigate.to('/home');
  }

  async cargarTokenCuentaSimple() {
    try {
      const token = await this.backendService.getTokenCuentaSimple();
      this.jwt = token['access_token'];
    } catch (error) {
      console.log(error);
      this.navigate.toServiceError('ERROR_TOKEN_CUENTA_SIMPLE');
    }
  }

  async generarToken() {
    if (!this.multiOfertas.multiOffers?.offerTD) return;
    await this.cargarTokenCuentaSimple();
    this.loader = false;
  }

  async generarReporte(){
    await this.reportes.addToReport({
      req_status: 'Proceso',
      properties: {
        pagina: 'Multi-Ofertas',
        device: this.utils.detectBrowser(),
        tarjetaCredito:this.multiOfertas.multiOffers?.offerTC ? 'SI' : 'No',
        tarjetaDebito: this.multiOfertas.multiOffers?.offerTD ? 'SI' : 'No'
      }
    })
  }

  private handleAccountCreated(event: Event): void {
    // Asegura que el tipo de 'event.detail' es 'AccountEventDetail'.
    const detail = (event as CustomEvent<any>).detail;
    console.log(detail);
  }

  private listenerIbkAccount(): void {
    this.element = document.getElementById('account-wc');

    if (this.element) {
      console.log('entroooo')
      this.element.addEventListener('accountCreated', this.handleAccountCreated);
    }
  }

  ngOnDestroy(): void {
    if (this.element) {
      this.element.removeEventListener('accountCreated', this.handleAccountCreated);
    }
  }

  validarCodigoEnMemoria(){
    const codigoMemoria = sessionStorage.getItem('codigoValidacion');
    if (codigoMemoria && codigoMemoria === this.parametros.config['CODIGO_VALIDACION'] ){
      this.isMemoria = true
    }else {
      this.isMemoria = false
    }
  }

}