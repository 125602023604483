import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ApiAltaService } from 'src/app/services/api-alta/api-alta.service';
import { ApiPepperBackendService } from 'src/app/services/api-pepper-backend/api-pepper-backend.service';
import { ParametrosService } from 'src/app/services/parametros/parametros.service';
import { PepperService } from 'src/app/services/pepper/pepper.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import { Subject } from 'rxjs';
import { ApiCalificacionService } from 'src/app/services/api-calificacion/api-calificacion.service';

@Component({
  selector: 'app-datos-tarjeta',
  templateUrl: './datos-tarjeta.component.html',
  styleUrls: ['./datos-tarjeta.component.scss']
})
export class DatosTarjetaComponent implements OnInit {

  @ViewChild('viewPepper') content!: ElementRef;

  type_modal_active = false
  card_modal_active = false

  //Antiguo
  timeout = false
  startTimerSubject: Subject<void> = new Subject<void>();
  type: string = ''
  name: string = ''
  month: string = ''
  year: string = ''
  csc: string = ''
  cvv: string = ''
  line: string = '0';
  hasVisualizacion: boolean

  //Obligatorios
  card: string = ''
  codigoUnico: string = ''
  subscriptionKey: string = environment.subscriptionKeyWebComponent.value;
  sessionToken: string = '';

  //Opcional
  title: string = 'Esta es tu tarjeta digital';
  subTitle: string = '';
  coupon: string | undefined;
  countdownSeconds: number = 0;

  currentFlow: string | undefined

  showCard: boolean | undefined = false;
  loading: boolean = true;

  partnerName: string = ''
  outOfZone: boolean = false

  constructor(
    private alta: ApiAltaService,
    private pepper: PepperService,
    private calificacion: ApiCalificacionService,
    private parametros: ParametrosService,
    private navigate: NavigateService,
    private reporte: ReportesService,
    private renderer: Renderer2,
    private backendService: ApiPepperBackendService,
    private utils: UtilsService
  ) {
    this.hasVisualizacion = parametros.config['FLAG_VISUALIZACIONPLATAFORMA'];
  }

  async ngOnInit(): Promise<void> {
    this.initializeData();
    await this.loadCupon();
  }

  initializeData() {
    this.outOfZone = !!this.pepper.isOutOfZone()
    this.currentFlow = this.pepper.getSelectedFlow()
    this.partnerName = this.pepper.getContentData()?.partner_name['value'];

    const datosTarjeta = this.alta.cardData
    this.card = this.hasVisualizacion ? this.maskCardNumber(datosTarjeta.numeroTarjeta) : datosTarjeta.numeroTarjeta
    this.codigoUnico = datosTarjeta.codigoUnico

    //antiguo
    const proposal = this.calificacion.proposal
    const oferta = this.pepper.getSelectedCard()
    this.type = proposal.productType.toLowerCase()
    this.name = oferta.nombreTipo
    this.line = this.alta.credito
    this.month = datosTarjeta.fechaVencimiento.substring(4, 6)
    this.year = datosTarjeta.fechaVencimiento.substring(0, 4)
    this.csc = datosTarjeta.codigo4csc
    this.cvv = datosTarjeta.cvv2

    this.countdownSeconds = this.currentFlow === 'online' ? 10 * 60 : 3 * 60;
    this.subTitle = this.isOnline ?
      `Recuerda que solo es válida para un único uso y tiene una línea de crédito de S/ ${this.alta.credito}.`
      : `Recuerda que puedes hacer un uso máximo de S/ ${this.alta.credito} de línea de crédito.`
  }

  async handleTypeOnboardingCloseModal() {
    this.showCard = true;
    if (this.hasVisualizacion) {
      await this.loadTokenValidacion();
      this.generateWebComponent()
    }
    this.type_modal_active = false
    await this.reporte.addToReport({
      properties: {
        visualizacionTc: "SI",
        device: this.utils.detectBrowser()
      }
    })
  }

  maskCardNumber(cardNumber: string): string {
    // Eliminar espacios en blanco o guiones que pudiera tener el formato de la tarjeta
    const cleanCardNumber = cardNumber.replace(/\s|-/g, '');

    // Revelar los primeros seis y los últimos cuatro dígitos del número de tarjeta y enmascarar el resto
    const maskedSection = cleanCardNumber.slice(6, -4).replace(/\d/g, '*');
    const visibleSectionStart = cleanCardNumber.slice(0, 6);
    const visibleSectionEnd = cleanCardNumber.slice(-4);

    return `${visibleSectionStart}${maskedSection}${visibleSectionEnd}`;
  }

  startCountdown() {
    this.startTimerSubject.next();
  }

  handleTimeout() {
    this.name = '-'
    this.card = '0000000000000000'
    this.month = '-'
    this.year = '-'
    this.csc = '-'
    this.cvv = '-'
    this.timeout = true
  }

  handleCardOnboardingCloseModal() {
    this.card_modal_active = false
    this.type_modal_active = true
  }

  async loadCupon() {
    try {
      const storeName = this.pepper.getSelectedStore()
      if (storeName === undefined) throw new Error('No store name found')
      const coupon = await this.parametros.getCouponCodeForStore(storeName)
      this.coupon = coupon
    } catch (e) {
      this.coupon = undefined
    } finally {
      let reportePayload = {
        req_status: 'Finalizado',
        properties: {
          pagina: 'Resumen',
          codigoPromocional: this.coupon ? this.coupon : '',
          visualizacionTc: 'SI',
          device: this.utils.detectBrowser()
        }
      }
      await this.reporte.addToReport(reportePayload)
      this.loading = false
    }
  }

  async showModal() {
    this.card_modal_active = true
    await this.reporte.addToReport({
      properties: {
        flagVenta: "SI",
        device: this.utils.detectBrowser()
      }
    })
  }

  get isCardless() {
    return this.pepper.getSelectedFlow() === 'cardless'
  }

  get isOnline() {
    return this.pepper.getSelectedFlow() === 'online'
  }

  get emailAlternativo() {
    const correoExitente = this.pepper.currentPerson().emailAddress.find((item) => item.emailAddress === this.pepper.getEmailAlternativo())
    return correoExitente ? false : true;
  }

  //Construye el Web Component
  generateWebComponent(): void {
    const accountChild = this.content.nativeElement.children[0];
    if (accountChild) {
      this.renderer.removeChild(this.content.nativeElement, accountChild);
    }
    const webComponent = this.renderer.createElement('ibk-cards-viewer');
    webComponent.setAttribute('customer-id', this.codigoUnico);
    webComponent.setAttribute('card-number', this.card);
    webComponent.setAttribute('subscription-key', this.subscriptionKey);
    webComponent.setAttribute('session-token', this.sessionToken);
    webComponent.setAttribute('title', this.title);
    webComponent.setAttribute('sub-title', this.subTitle);
    if (this.coupon !== undefined) webComponent.setAttribute('coupon', this.coupon);
    webComponent.setAttribute('count-down', this.countdownSeconds);
    webComponent.setAttribute('x-channel', 'PEP');
    this.renderer.appendChild(this.content.nativeElement, webComponent);
    this.listenerIbkCardsViewer()
  }

  //Escucha los eventos del Web Component
  private listenerIbkCardsViewer(): void {
    const target = this.content.nativeElement.children[0]
    const eventName = 'handlerOperationResults'
    this.renderer.listen(target, eventName, res => {
      const { detail } = res
      console.warn('[==>] ', detail)
    })
  }

  // Carga el token del Web Component
  private async loadTokenValidacion() {
    try {
      const token = await this.backendService.getTokenValidacion();
      this.sessionToken = token['access_token'];
    } catch (error) {
      this.navigate.toServiceError('ERROR_VISUALIZACION_TARJETA');
    }

  }

}
