import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { groupBy, padStart } from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../utils/utils.service';
import { CouponResponse } from './responses/CouponResponse';
import { Estructura } from './responses/EstructuraResponse';
import { ParametroDetalle } from './responses/ParametroDetalleResponse';
import { ParametroDetalleXEstructura } from './responses/ParametrosDetalleXEstructura';
import { Ubigeo } from './responses/Ubigeo';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {

  private estructuras = {} as  Record<string, string> 
  private _lineaParametro = {} as Record<string, string>
  private _emailAlternativo = {} as Record<string, string>
  private headers = {} as Record<string, HttpHeaders>
  private _config = {} as Record<string, any>
  private _operators = {} as Record<string, string>
  private _campaignNumber = {} as Record<string, string>
  private _qradar = {} as Record<string, string>
  private _parameterError = {} as Record<string, string>
  private _allSocios: ParametroDetalleXEstructura[] = []
  private _cardNames: {
    type: Record<string, string>,
    typeFlipped: Record<string, string>,
    subtype: Record<string, string>,
    subtypeFlipped: Record<string, string>,
  } = {
    type: {},
    typeFlipped: {},
    subtype: {},
    subtypeFlipped: {},
  }
  initializing = true
  sessionId: string;

  constructor(
    private http: HttpClient,
    private utils: UtilsService
  ) {    
    const params = this.utils.getPepperUrlCombination()
    const tipo = params.hsede
    const d = new Date()
    this.sessionId = `PEPPER${tipo?.toUpperCase()}_${d.getFullYear()}${padStart(d.getMonth() + 1 + '', 2, '0')}${padStart(d.getDate() + '', 2, '0')}${padStart(d.getHours() + '', 2, '0')}${padStart(d.getMinutes() + '', 2, '0')}${padStart(d.getSeconds() + '', 2, '0')}${padStart(d.getMilliseconds() + '', 2, '0')}`
  }

  public getSessionId () {
    return this.sessionId
  }

  async init (): Promise<void> {

    const rawEstructuras = await this.getEstructuras()

    rawEstructuras.forEach(item => {
      this.estructuras[item.codigo] = item.idEstructura
    })

    const idServicios = {} as Record<string, string>;

    const allRawServices = await this.getFrontendServicesWithHeaders()

    allRawServices.forEach(service => {
      this.headers[service.nombre] = new HttpHeaders();
      idServicios[service.idParametro] = service.nombre
    })

    const response = await Promise.all([
      this.getAllConfig(),
      this.getOperators(),
      this.populateCardNames(),
      this.getPartnerLineParameter(),
      this.getConfigQradar(),
      this.getRuleParameterQradar(),
    ])

    const allRawConfig = response[0] 
    allRawConfig.forEach(conf => {
      conf.parametro_detalles.forEach(detalle => {
        let valor;
        switch (detalle.valor) {
          case 'true': valor = true; break;
          case 'false': valor = false; break;
          case 'null': valor = null; break;
          default: valor = detalle.valor; break;
        }
        this._config[detalle.codigo] = valor
      })    
    })

    const allRawHeaders = allRawServices
    const ip = await this.utils.getCurrentIP(this.config['PATH_URL_GETIP']) + '';
    allRawHeaders.forEach(header => {
      header.parametro_detalles.forEach(detalleHeader => {
        const service_name = idServicios[header.idParametro];
        let valor = detalleHeader.valor === undefined ? '' : detalleHeader.valor

        switch (valor) {
          case '{PEPPERTYPE_YYYYMMDDHmiSSsss}': valor = this.sessionId; break;
          case '{IP/IMEI}': valor = ip!; break;
          default: break;
        }
        this.headers[service_name] = this.headers[service_name].set(detalleHeader.codigo, valor)

      })     
    });
    this.findCampaignNumber();
    this.initializing = false;

  }


  private async getEstructuras () {
    const request$ = this.http.get<Estructura[]>(environment.services.parametros.getEndpointUrl('estructura'), {
      headers: new HttpHeaders().set(environment.subscriptionApiHeader.key, environment.subscriptionApiHeader.value)
    })
    const raw = await firstValueFrom(request$);
    return raw;
  }

  private async getFrontendServicesWithHeaders () {
    const response = await this.getParametrosDetailFromEstructura('API_HEADERS')

    return response;
  }

  private async getAllConfig () {

    const request: ParametroDetalleXEstructura[] = await this.getParametrosDetailFromEstructura('CONFIG_GENERAL')

    return request;
  }

  public async getOperators () {
    const operators: Record<string, string> = {}

    const response = await this.getParametrosDetailFromEstructura('OPERADOR')

    response.forEach(operator => {
      const valueItem = operator.parametro_detalles.find(d => d.codigo === 'id')
      const keyItem = operator.parametro_detalles.find(d => d.codigo === 'name')

      if (keyItem && valueItem) operators[keyItem.valor] = valueItem.valor

    })

    this._operators = operators;
  }

  public async getConfigQradar() {
    const allConfig: Record<string, string> = {}

    const response = await this.getParametrosDetailFromEstructura('CONFIG_QRADAR')

    response.forEach(operator => {
      operator.parametro_detalles.forEach(detalle => {
        if (detalle.codigo && detalle.valor) allConfig[detalle.codigo] = detalle.valor
      })
    })
    this._qradar = allConfig;
  }

  public async getRuleParameterQradar() {
    const parameterError: Record<string, string> = {}

    const response = await this.getParametrosDetailFromEstructura('PARAMETRO_REGLA');

    const errorRules = response.find(elem => elem.nombre.includes('PARAMETRO_REGLA_8'));
    if (errorRules) {
      errorRules.parametro_detalles.forEach(ruler => {
        const valueItem = ruler.valor
        const keyItem = ruler.codigo
        if (keyItem && valueItem) parameterError[keyItem] = valueItem;
      });
    }
    this._parameterError = parameterError;
  }

  public async findCampaignNumber() {
    const campaignNumber: Record<string, string> = {}
    const parametrosUrl = this.utils.getPepperUrlCombination();
    const socios = this._allSocios.filter(p => p.nombre === parametrosUrl.htienda?.toUpperCase());
    socios.forEach( socio => {
      const currentSocio = socio.parametro_detalles.find(d => (d.codigo === 'Sede' && d.valor === parametrosUrl.hsede));
      if (currentSocio) {
        const campaign = socio.parametro_detalles.find(d => d.codigo === 'CampaignNumber');
        if (campaign) campaignNumber[campaign.codigo] = campaign.valor
      } 
    })
    this._campaignNumber = campaignNumber;
  }

  public async getPartnerLineParameter () {
    const currentPartnerLine: Record<string, string> = {}
    const response = await this.getParametrosDetailFromEstructura('SOCIO');
    this._allSocios= response;
    const parametrosUrl = this.utils.getPepperUrlCombination();
    const currentPartners = response.filter(p => p.nombre === parametrosUrl.htienda?.toUpperCase());
    currentPartners.forEach(partner => {
      const sede = partner.parametro_detalles.find(d => (d.codigo === 'Sede' && d.valor === parametrosUrl.hsede));
      if (sede) {
        const lineaCliente = partner.parametro_detalles.find(d => d.codigo === 'TopeLineaCliente');
        const lineaNoCliente = partner.parametro_detalles.find(d => d.codigo === 'TopeLineaNoCliente');
        const emailAlternativo = partner.parametro_detalles.find(d => d.codigo === 'EmailAlternativo');
        this._emailAlternativo['emailAlternativo'] = emailAlternativo ? emailAlternativo.valor : '';
        if (lineaCliente && lineaNoCliente) {
          currentPartnerLine[lineaCliente.codigo] = lineaCliente.valor;
          currentPartnerLine[lineaNoCliente.codigo] = lineaNoCliente.valor; 
        }
      }
    });
    this._lineaParametro = currentPartnerLine;
  }

  public getHeaders (nombreApi: string): HttpHeaders {
    if (nombreApi === 'PEPPER_GENERAL') {
      return new HttpHeaders().set(environment.subscriptionApiHeader.key, environment.subscriptionApiHeader.value)
    }

    if (typeof this.headers[nombreApi] === 'undefined') throw new Error('No headers found for ' + nombreApi)
    
    let headers = this.headers[nombreApi]
    if (headers.get('X-INT-Timestamp') !== null) {
      const d = new Date()
      const aux = headers.set('X-INT-Timestamp', `${d.getFullYear()}-${padStart(d.getMonth() + 1 + '', 2, '0')}-${padStart(d.getDate() + '', 2, '0')}T${padStart(d.getHours() + '', 2, '0')}:${padStart(d.getMinutes() + '', 2, '0')}:${padStart(d.getSeconds() + '', 2, '0')}`)
      headers = aux
    }
    return headers
  }

  public async getParametrosDetailFromEstructura (estructuraName: string): Promise<ParametroDetalleXEstructura[]> {
    if (!this.estructuras[estructuraName]) throw new Error (`Unrecognized estructura ${estructuraName}`)

    const estructuraId = this.estructuras[estructuraName]

    const request$ = this.http.get<ParametroDetalleXEstructura[]>(environment.services.parametros.getEndpointUrl('parametroDetallePorEstructura'), {
      params: new HttpParams().set('idEstructura', estructuraId),
      headers: new HttpHeaders().set(environment.subscriptionApiHeader.key, environment.subscriptionApiHeader.value)
    })

    const raw: ParametroDetalleXEstructura[] = await firstValueFrom(request$);
    return raw;

  }

  public async getUbigeos (province: string = '00', district: string = '00'): Promise<Ubigeo[]> {

    const request$ = this.http.get<Ubigeo[]>(environment.services.parametros.getEndpointUrl('ubigeo'), {
      params: new HttpParams().appendAll({
        ubigeoProvince: province,
        ubigeoDistrict: district,
      }),
      headers: new HttpHeaders().set(environment.subscriptionApiHeader.key, environment.subscriptionApiHeader.value)
    })

    const raw: Ubigeo[] = await firstValueFrom(request$);
    return raw;

  }

  public async getDepartamentos () {
    const request$ = this.http.get<Ubigeo[]>(environment.services.parametros.getEndpointUrl('ubigeo'), {
      params: new HttpParams().appendAll({
        ubigeoProvince: '00',
        ubigeoDistrict: '00',
      }),
      headers: new HttpHeaders().set(environment.subscriptionApiHeader.key, environment.subscriptionApiHeader.value)
    })

    const raw: Ubigeo[] = await firstValueFrom(request$);
    return raw;
  }

  public async getProvincias (ubigeoDepartmen: string) {
    const request$ = this.http.get<Ubigeo[]>(environment.services.parametros.getEndpointUrl('ubigeo'), {
      params: new HttpParams().appendAll({
        ubigeoDepartmen,
        ubigeoDistrict: '00',
      }),
      headers: new HttpHeaders().set(environment.subscriptionApiHeader.key, environment.subscriptionApiHeader.value)
    })

    const raw: Ubigeo[] = await firstValueFrom(request$);
    return raw.filter((u: Ubigeo) => {
      return u.ubigeoProvince !== '00'
    });
  }

  public async getDistritos (ubigeoDepartmen: string, ubigeoProvince: string) {
    const request$ = this.http.get<Ubigeo[]>(environment.services.parametros.getEndpointUrl('ubigeo'), {
      params: new HttpParams().appendAll({
        ubigeoDepartmen,
        ubigeoProvince
      }),
      headers: new HttpHeaders().set(environment.subscriptionApiHeader.key, environment.subscriptionApiHeader.value)
    })

    const raw: Ubigeo[] = await firstValueFrom(request$);
    return raw.filter((u: Ubigeo) => {
      return u.ubigeoDistrict !== '00'
    });
  }

  public async populateCardNames () {

    const requestSubtype: ParametroDetalleXEstructura[] = await this.getParametrosDetailFromEstructura('TIPO_ASSI')
 
    const rawSubtypes: ParametroDetalle[] = []
    requestSubtype.forEach(subType => {
      subType.parametro_detalles.forEach(detalle => {
        rawSubtypes.push(detalle);
      })
    })

    const requestType: ParametroDetalleXEstructura[] = await this.getParametrosDetailFromEstructura('MARCA_ASSI')
    const rawTypes: ParametroDetalle[] = [];
    requestType.forEach(type => {
      type.parametro_detalles.forEach(detalle => {
        rawTypes.push(detalle);
      })
    })

    Object.values(groupBy(rawTypes, t => t.idParametro)).forEach(tuple => {
      const codigo = tuple.find(v => v.codigo === 'CODIGO')
      const nombre = tuple.find(v => v.codigo === 'NOMBRE')

      if (!codigo && !nombre) throw new Error('Malformed tuple')

      this._cardNames.type[(codigo as any).valor] = (nombre as any).valor
    })

    Object.keys(this._cardNames.type).forEach(key => {
      this._cardNames.typeFlipped[this._cardNames.type[key]] = key
    })

    Object.values(groupBy(rawSubtypes, t => t.idParametro)).forEach(tuple => {      
      const codigo = tuple.find(v => v.codigo === 'CODIGO')
      const nombre = tuple.find(v => v.codigo === 'NOMBRE')

      if (!codigo && !nombre) throw new Error('Malformed tuple')

      this._cardNames.subtype[(codigo as any).valor] = (nombre as any).valor
    })
    
    Object.keys(this._cardNames.subtype).forEach(key => {
      this._cardNames.subtypeFlipped[this._cardNames.subtype[key]] = key
    })

  }

  public async getCouponCodeForStore (storeName: string): Promise<string> { 
    const url = environment.services.parametros.getEndpointUrl('getCoupon').replace('{store_name}', storeName.toUpperCase())
    const request$ = this.http.put<CouponResponse>(url, {}, {
      headers: new HttpHeaders().set(environment.subscriptionApiHeader.key, environment.subscriptionApiHeader.value)
    })

    const raw: CouponResponse = await firstValueFrom(request$);
    return raw.coupon;

  }
  
  get config(): Record<string, any> { return this._config }
  get operators(): Record<string, string> { return this._operators }
  get configQradar(): Record<string, string> { return this._qradar }
  get parameterError(): Record<string, string> { return this._parameterError }
  get lineaParametro(): Record<string, string> { return this._lineaParametro }
  get emailAlternativo():Record<string, string> { return this._emailAlternativo }
  get campaignNumber(): Record<string, string> { return this._campaignNumber }
  get cardNames(): {
    type: Record<string, string>,
    typeFlipped: Record<string, string>,
    subtype: Record<string, string>,
    subtypeFlipped: Record<string, string>,
  } { return this._cardNames }

}
