import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import moment from 'moment';
import { InternalService } from 'src/app/common/InternalService';
import { environment } from 'src/environments/environment';
import { ParametrosService } from '../parametros/parametros.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class QradarService extends InternalService {

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient,
    private utils: UtilsService,
  ) {
    super(parametros, http, environment.services.pepper)
  }


  async addToRegister(endpointName: string, report: Record<string, any>) {
    try {

      if (environment.qradarIsActive && this.validateAttempts(endpointName) && this.validateTime(endpointName)) {
        let requestPayload = {
          body: await this.setPayload(report)
        } as any
 
        await this.doRequestToEndpoint('qradarRegistro', requestPayload);
        this.removeFromStorage(endpointName);
      }

    } catch (error) {
      throw new Error('Error executing Qradar API.');
    }
  }

  validateAttempts(endpointName: string) {
    let overcameAttempts = false;
    const name: string = endpointName + 'Itentos';
    let count = localStorage.getItem(name) ? parseInt(localStorage.getItem(name) as string) : null;
    if (typeof count === 'number') {
      if (count <= environment.intentos[name]) {
        localStorage.setItem(name, (count + 1).toString());
      } else {
        overcameAttempts = true;
      }
    } else {
      localStorage.setItem(name, '1');
      localStorage.setItem(endpointName + 'Time', moment().format('YYYY-MM-DD HH:mm:ss'));
    }
    return overcameAttempts;
  }

  validateTime(endpointName: string) {
    let isOneMinute = false;
    if (typeof localStorage.getItem(endpointName + 'Time') === 'string') {
      let fechaInicio = moment(localStorage.getItem(endpointName + 'Time') as string, 'YYYY-MM-DD HH:mm:ss');
      if (fechaInicio.isValid()) {
        let fechaFin = moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
        let tiempo = fechaFin.diff(fechaInicio, 'm');
        if (tiempo <= environment.tiempoPorIncidencias) {
          isOneMinute = true;
        } else {
          this.removeFromStorage(endpointName);
          localStorage.setItem(endpointName + 'Time', moment().format('YYYY-MM-DD HH:mm:ss'));
          localStorage.setItem(endpointName + 'Itentos', '1');
        }
      } else {
        localStorage.setItem(endpointName + 'Time', moment().format('YYYY-MM-DD HH:mm:ss'));
      }
    } else {
      localStorage.setItem(endpointName + 'Time', moment().format('YYYY-MM-DD HH:mm:ss'));
    }
    return isOneMinute;
  }

  removeFromStorage(endpointName: string) {
    localStorage.removeItem(endpointName + 'Itentos');
    localStorage.removeItem(endpointName + 'Time');
  }

  async setPayload(data: Record<string, any>) {
    const configQradar = this.parametros.configQradar;
    const parameterErrror = this.parametros.parameterError;
    let requestPayload = {
      fechaHora: moment().format('YYYY-MM-DD HH:mm:ss'),
      hostName: configQradar['hostname'],
      nombreFormato: configQradar['nombreFormato'],
      versionFormato: configQradar['versionFormato'],
      siglaArquitectura: configQradar['siglaArquitectura'],
      nombreAplicacion: configQradar['NombreAplicacion'],
      versionAplicacion: configQradar['versionAplicacion'],
      recurso: parameterErrror['Recurso'],
      severidad: parameterErrror['Severidad'],
      devTimeFormat: 'yyyy-MM-dd HH:mm:ss.S',
      devTime: moment().format('YYYY-MM-DD HH:mm:ss.S'),
      descripcion: 'Error del sistema',
      usrName: '',
      src: this.utils.ipLocal,
      dst: configQradar['dst'],
      identHostName: configQradar['identHostname'],
      ambiente: configQradar['ambiente'],
      parametros: data['parametros']
    } as any

    return requestPayload;
  }

}