import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NavigateService {

  constructor(
    private router: Router
  ) { }

  private routesData: Record<string, Record<string, string | boolean | number>> = {}
  private currentDir = '';

  public getCurrentRouteData() {
    if (!this.routesData[this.currentDir]) return {}

    return this.routesData[this.currentDir]
  }

  public to(path: string) {
    this.router.navigate([path], { skipLocationChange: true });
    this.currentDir = path.replace(/^\//, '')
  }

  public toServiceError(
    codigo_error: string,
    hasReport = true,
    title = 'Algo no va bien,',
    description = 'parece que tenemos un error en nuestro servicio.<br>Por favor, inténtalo más tarde.',
    reloadTextButton: string | boolean = 'Volver a intentar') {

    switch (codigo_error) {
      case 'ERROR_API_OFERTAS': {
        this.routesData['error/api-ofertas'] = {
          logo: '../../../assets/icon/error/ofertas-fallo.svg',
          contact: '+51 311 9000',
          description: 'En este momento no podemos mostrarte las tarjetas que tienes aprobadas, para más información comunícate con los siguientes canales de atención:',
          codigo_error
        }
        this.to('error/api-ofertas');
        break;
      }
      case 'ERROR_API_ALTA': {
        this.routesData['error/api-alta'] = {
          logo: '../../../assets/icon/error/error-alta.svg',
          contact: '+51 311 9086',
          description: 'En este momento no podemos dar de alta tu tarjeta por este medio, te invitamos a continuar el proceso en la bánca telefónica.',
          codigo_error
        }
        this.to('error/api-alta');
        break;
      }
      case 'ERROR_API_CALIFICACION': {
        this.routesData['error/api-calificacion'] = {
          logo: '../../../assets/icon/error/error-calificacion.svg',
          contact: '+51 311 9086',
          description: 'En este momento no podemos darte el resultado de tu calificación por este medio, te invitamos a continuar el proceso en la bánca telefónica.',
          codigo_error
        }
        this.to('error/api-calificacion');
        break;
      }
      case 'ERROR_API_GET': {
        this.routesData['error/api-get'] = {
          title,
          description,
          reloadTextButton,
          codigo_error
        }
        this.to('error/api-get');
        break;
      }
      case 'ERROR_API_AUTENTICACION': {
        this.routesData['error/api-autenticacion'] = {
          logo: '../../../assets/icon/error/error-identidad.svg',
          description: 'En este momento no podemos validar tu identidad por este medio, te invitamos a continuar el proceso en la bánca telefónica.',
          contact: '+51 311 9086',
          codigo_error
        }
        this.to('error/api-autenticacion');
        break;
      }
      case 'ERROR_API_OTP_AUTHENTICATION': {
        this.routesData['error/api-otp-authentication'] = {
          logo: '../../../assets/icon/error/error-identidad.svg',
          description: 'En este momento no podemos validar tu identidad por este medio, te invitamos a continuar el proceso en la bánca telefónica.',
          contact: '+51 311 9086',
          codigo_error
        }
        this.to('error/api-otp-authentication');
        break;
      }
      case 'ERROR_GENERANDO_LLAVES_BIOMETRIA': {
        this.routesData['error/llave-biometria'] = {
          logo: '../../../assets/icon/error/error-identidad.svg',
          description: 'En este momento no podemos validar tu identidad por este medio, te invitamos a continuar el proceso en la bánca telefónica.',
          contact: '+51 311 9086',
          codigo_error
        }
        this.to('error/llave-biometria');
        break;
      }
      case 'ERROR_DATOS_INCOMPLETOS': {
        this.routesData['error/datos-incompletos'] = {
          title,
          description,
          reloadTextButton,
          codigo_error
        }
        this.to('error/datos-incompletos');
        break;
      }
      case 'ERROR_API_MARCAR_INTENTO_OTP': {
        this.routesData['error/api-marcar-intento-otp'] = {
          logo: '../../../assets/icon/error/error-identidad.svg',
          description: 'En este momento no podemos validar tu identidad por este medio, te invitamos a continuar el proceso en la bánca telefónica.',
          contact: '+51 311 9086',
          codigo_error
        }
        this.to('error/api-marcar-intento-otp');
        break;
      }
      case 'ERROR_NO_CANAL': {
        this.routesData['error/no-canal'] = {
          title,
          description,
          reloadTextButton,
          codigo_error
        }
        this.to('error/no-canal');
        break;
      }
      default: {
        this.routesData['error/servicio'] = {
          title,
          hasReport,
          description,
          reloadTextButton,
          codigo_error
        }
        this.to('error/servicio');
        break;
      }
    }

  }
}
