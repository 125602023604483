import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import KeenSlider, { KeenSliderInstance } from "keen-slider";
import { PepperService } from 'src/app/services/pepper/pepper.service';
import { environment } from 'src/environments/environment';
import { ApiOfertasService } from 'src/app/services/api-ofertas/api-ofertas.service';
import { cellphoneValidator, DocumentTypes, documentValidator, emailValidator, lengthValidator } from 'src/utils/formControlValidators';
import { ApiPepperBackendService } from 'src/app/services/api-pepper-backend/api-pepper-backend.service';
import { ParametrosService } from 'src/app/services/parametros/parametros.service';
import { RejectedClientError } from 'src/app/errors/RejectedClientError';
import { BypassToCalificacionError } from 'src/app/errors/BypassToCalificacionError';
import { ApiCalificacionService } from 'src/app/services/api-calificacion/api-calificacion.service';
import { AntifraudeService } from 'src/app/services/antifraude/antifraude.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { OfertasRequest } from 'src/app/services/api-ofertas/requests/OfertasRequest';
import { QradarService } from 'src/app/services/qradar/qradar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {


  @ViewChild("sliderBeneficios") sliderBeneficios: ElementRef<HTMLElement> | undefined

  slider: KeenSliderInstance | null = null

  operadores = [
    { id: "C", name: 'Claro' },
    { id: "M", name: 'Movistar' },
    { id: "E", name: 'Entel' },
  ]

  slider_beneficios = []
  slider_paginas: Array<number> = []
  currentBeneficiosAbs = 0;
  legales = ''

  errorOfertas = false
  loadOfertas = false
  intentosOfertas = 0
  errorOfertasMessage = 'Hemos tenido un error al cargar la oferta que tenemos para ti, por favor inténtalo de nuevo.'

  clientForm: UntypedFormGroup | undefined

  clientInitialValue: any = {
    dni: '',
    email: '',
    operador: undefined,
    celular: '',
    autorizacion: false
  }

  loading: boolean;
  comesFromDebit: boolean;
  isRecaptchaValid: boolean;
  isRecaptchaTouched: boolean;
  personalDataModal: boolean;
  tycDataModal: boolean;

  bannerImgUrl: string;
  storeName: string;
  storeLogo: string;
  partnerBenefit: string;

  recaptcha = {
    enabled: false,
    key: ''
  }

  contentPoliticaDatoPersonal = ''
  contentTerminosyCondiciones = ''

  getContentHubData(fieldName: string, fieldKey: string, onErrorValue: string = '') {
    try {

      let chData: any = this.pepper.getContentData()

      if (!chData[fieldName]) return onErrorValue
      chData = chData[fieldName]

      if (!chData[fieldKey]) return onErrorValue

      return chData[fieldKey]
    } catch (e) {
      return onErrorValue;
    }
  }

  constructor(
    private pepper: PepperService,
    private parametros: ParametrosService,
    private ofertas: ApiOfertasService,
    private navigate: NavigateService,
    private pepperBackend: ApiPepperBackendService,
    private calificacion: ApiCalificacionService,
    private antifraude: AntifraudeService,
    private utils: UtilsService,
    private qradar: QradarService,
    private reportes: ReportesService
  ) {

    const benefits = this.getContentHubData('partner_benefits', 'values', '')
    const bannerImgUrl = this.getContentHubData('partner_principal_benefit_image', 'url', '')
    const logoImgUrl = this.getContentHubData('partner_logo_image', 'url', '')
    const storeName = this.getContentHubData('partner_name', 'value', '')
    const partnerBenefit = this.getContentHubData('partner_principal_benefit', 'value', '')

    const assetBaseUrl = environment.services.pepperBackend.getEndpointAttr('contentHub', 'assetsBaseUrl')

    if (benefits) {
      this.slider_beneficios = benefits.map((benefit: Record<string, any>) => ({
        icon: assetBaseUrl + '/' + benefit['benefit_image']['url'],
        title: benefit['benefit_title']['value'],
        description: benefit['benefit_description']['value'],
      }))
    }

    this.bannerImgUrl = !!bannerImgUrl ? assetBaseUrl + bannerImgUrl : 'http://placehold.jp/360x164.png'
    this.storeLogo = !!logoImgUrl ? assetBaseUrl + logoImgUrl : 'http://placehold.jp/205x46.png'
    this.storeName = storeName
    this.partnerBenefit = partnerBenefit

    this.legales = this.getContentHubData('partner_legal', 'value', '')

    this.loading = false

    if (parametros.config['Recaptcha'] === true) {
      this.isRecaptchaValid = (environment as any)?.overrides?.home?.isRecaptchaValid ? ((environment as any).overrides.home.isRecaptchaValid as boolean) : false;

      this.recaptcha.key = parametros.config['SiteKeyPepper']
      this.recaptcha.enabled = true

    } else {
      this.isRecaptchaValid = true
    }

    this.isRecaptchaTouched = false

    this.personalDataModal = false
    this.contentPoliticaDatoPersonal = parametros.config['PoliticaDatoPersonal']


    this.tycDataModal = false
    this.contentTerminosyCondiciones = parametros.config['TerminosyCondiciones']

    // Overrides
    if ((environment as any)?.overrides?.home?.clientInitialValue) {
      this.clientInitialValue = (environment as any).overrides.home.clientInitialValue
    }

    const dniPepperDebito = this.pepper.getDniPepperDebito();
    this.clientInitialValue['dni'] = dniPepperDebito ? dniPepperDebito : this.clientInitialValue['dni'];
    this.comesFromDebit = dniPepperDebito ? true : false;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.generateBulletsArray();
  }

  get operators() {
    return this.parametros.operators
  }

  async handleSubmit() {
    console.log("version pepper: 0.0.1")
    if (this.clientForm?.valid) {
      this.loading = true
      if (this.intentosOfertas > 0) this.loadOfertas = true

      const { dni, email, operador, celular, autorizacion } = this.clientForm?.getRawValue()

      const m = moment()

      let antifraudeFlag

      try {
        const ip = this.utils.ipLocal;

        this.pepper.setClientFormData({
          documentNumber: dni,
          email,
          operador,
          celular,
          documentType: 'DNI',
          autorizacion
        })

        if (this.parametros.config['FLAG_ANTIFRAUDE'] === true) {
          antifraudeFlag = false
        } else {
          if (this.pepper.getSelectedFlow() !== 'fisico') {
            antifraudeFlag = await this.antifraude.isSuspiciousRequest(ip, celular, email, dni)
          }
        }


        if (antifraudeFlag) {
          throw new Error('BLOQUEO_POR_ANTIFRAUDE')
        }

        const requestOfertas: OfertasRequest = {
          documentNumber: dni,
          email,
          documentType: '01',
          telephone: celular + ''
        }

        const campaign = this.parametros.campaignNumber;

        if (campaign['CampaignNumber']) requestOfertas.campaignNumber = campaign['CampaignNumber']

        await this.ofertas.fetchOfertas(requestOfertas)
        this.pepper.updateCurrentPerson()
        this.pepper.setSelectedCard(this.ofertas.offers[0])

        const currentPersona = this.pepper.currentPerson()
        if (currentPersona === undefined) throw new Error('No person data found')

        let fullname = []

        if (currentPersona.firstName) {
          fullname.push(currentPersona.firstName)
        }

        if (currentPersona.secondName) {
          fullname.push(currentPersona.secondName)
        }

        if (currentPersona.lastName) {
          fullname.push(currentPersona.lastName)
        }

        if (currentPersona.secondLastName) {
          fullname.push(currentPersona.secondLastName)
        }

        let emailForReport = email
        let phoneForReport = celular

        if (currentPersona.emailAddress && currentPersona.emailAddress.length > 0) {
          emailForReport = currentPersona.emailAddress[0].emailAddress;
        }

        currentPersona.phoneInformation = currentPersona.phoneInformation && currentPersona.phoneInformation.length > 0 ? currentPersona.phoneInformation.filter(el => el.useCode === "C") : [];

        if (currentPersona.phoneInformation && currentPersona.phoneInformation.length > 0) {
          phoneForReport = currentPersona.phoneInformation[0].phoneNumber;
        }

        const pepper_type = this.pepper.getSelectedFlow()
        await this.reportes.addToReport({
          message_id: this.parametros.getSessionId(),
          full_name: fullname.join(' ').toUpperCase(),
          document_type: "1",
          document_number: dni,
          req_type: pepper_type,
          store_name: pepper_type,
          code_seller: this.pepper.getSelectedStore(),
          record_file_evaluation: null,
          establishment: this.pepper.getSelectedStore(),
          cellphone: phoneForReport + '',
          cell_operator: operador,
          created_at: m.format('YYYY/MM/DD'),// DD/MM/YYYY HH:mm:ss
          updated_at: m.format('YYYY/MM/DD'),
          req_status: "Proceso",
          email: emailForReport,
          commerce_type: pepper_type?.toUpperCase()[0] === 'F' ? 'Físico' : 'Online',
          currency: "PEN",
          ammount_approved: 0,
          account_number: null,
          reason: "P",
          ip_address: this.utils.ipLocal,
          properties: {
            fecha: m.format('YYYY-MM-DD HH:mm:ss'), // Fecha: DDMMYYY, lee del sistema
            create: m.format('DD/MM/YYYY HH:mm:ss'),
            updated: m.format('DD/MM/YYYY HH:mm:ss'),
            dia: m.format('DD'), // Día: número del día, y lo lee del sistema
            mes: m.format('MMMM'), // Mes: número del mes, y lo lee del sistema
            semana: 'SEMANA ' + m.week(), // Semana: número de semana
            tipoFlujo: this.pepper.getSelectedFlow(), // Flujo: texto ""Venta""
            flagAntifraude: antifraudeFlag ? 'SI' : 'NO', // Flag Antifraude: Rpta SI o NO
            flagVenta: "NO",
            emailForm: email,
            autorizacionDatos: (autorizacion as boolean) ? 'Si' : 'No',
            cellphoneForm: celular + '',
            isCustomCampaign: this.ofertas.getIsCustomCampaign() ? 'SI' : 'NO',
            pagina: 'Login', // Pagina: rpta LogIn
            device: this.utils.detectBrowser()
          }
        })


        this.errorOfertas = false
        if (currentPersona.emailAddress.length == 0) {
          this.navigate.toServiceError('ERROR_DATOS_INCOMPLETOS')
          return;
        }
        if (currentPersona.phoneInformation.length == 0) {
          this.navigate.toServiceError('ERROR_DATOS_INCOMPLETOS')
          return;
        }
        if (this.ofertas.hasCampaign()) {
          this.navigate.to('usuario/identidad/datos-personales')
        } else {
          this.navigate.to('usuario/sin-campañas')
        }
      } catch (error) {

        if (error instanceof RejectedClientError) {

          this.pepper.updateCurrentPerson()

          const currentPersona = this.pepper.currentPerson()
          if (currentPersona === undefined) throw new Error('No person data found')


          let fullname = []

          if (currentPersona.firstName) {
            fullname.push(currentPersona.firstName)
          }

          if (currentPersona.secondName) {
            fullname.push(currentPersona.secondName)
          }

          if (currentPersona.lastName) {
            fullname.push(currentPersona.lastName)
          }

          if (currentPersona.secondLastName) {
            fullname.push(currentPersona.secondLastName)
          }



          const pepper_type = this.pepper.getSelectedFlow()

          await this.reportes.addToReport({
            message_id: this.parametros.getSessionId(),
            full_name: fullname.length > 0 ? fullname.join(' ').toUpperCase() : '-',
            document_type: "1",
            document_number: dni,
            req_type: pepper_type,
            store_name: pepper_type,
            code_seller: this.pepper.getSelectedStore(),
            record_file_evaluation: null,
            establishment: this.pepper.getSelectedStore(),
            cellphone: celular + '',
            cell_operator: operador,
            created_at: m.format('YYYY/MM/DD'),
            updated_at: m.format('YYYY/MM/DD'),
            req_status: "Proceso",
            email: email,
            commerce_type: pepper_type?.toUpperCase()[0] === 'F' ? 'Físico' : 'Online',
            currency: "PEN",
            ammount_approved: 0,
            account_number: null,
            reason: "P",
            ip_address: this.utils.ipLocal,
            properties: {
              fecha: m.format('YYYY-MM-DD HH:mm:ss'), // Fecha: DDMMYYY, lee del sistema
              create: m.format('DD/MM/YYYY HH:mm:ss'),
              updated: m.format('DD/MM/YYYY HH:mm:ss'),
              dia: m.format('DD'), // Día: número del día, y lo lee del sistema
              mes: m.format('MMMM'), // Mes: número del mes, y lo lee del sistema
              semana: 'SEMANA ' + m.week(), // Semana: número de semana
              tipoFlujo: this.pepper.getSelectedFlow(), // Flujo: texto ""Venta""
              flagAntifraude: antifraudeFlag ? 'SI' : 'NO', // Flag Antifraude: Rpta SI o NO
              tipoCliente: currentPersona.customerId ? 'SI' : 'NO',
              campana: 'No Campaña',
              codigoUnico: currentPersona.customerId ? currentPersona.customerId : '  ',
              emailForm: email,
              autorizacionDatos: (autorizacion as boolean) ? 'Si' : 'No',
              cellphoneForm: celular + '',
              flagCampania: 'NO',
              pagina: 'No Campaña',
              device: this.utils.detectBrowser()

            }
          })

          this.navigate.to('usuario/sin-campañas')
          return;
        } else if (error instanceof BypassToCalificacionError) {
          this.pepper.updateCurrentPerson()
          this.calificacion.setQualification(error.expedientNumber, error.qualifiedProposal)


          const currentPersona = this.pepper.currentPerson()
          if (currentPersona === undefined) throw new Error('No person data found')

          const emailAlter = this.ofertas.correoAlternativo
          this.pepper.setEmailAlternativo(emailAlter)
          this.pepper.setValidacionBiometria(this.ofertas.validarBiometria)

          let fullname = []

          if (currentPersona.firstName) {
            fullname.push(currentPersona.firstName)
          }

          if (currentPersona.secondName) {
            fullname.push(currentPersona.secondName)
          }

          if (currentPersona.lastName) {
            fullname.push(currentPersona.lastName)
          }

          if (currentPersona.secondLastName) {
            fullname.push(currentPersona.secondLastName)
          }

          let emailReingreso = email
          let cellphoneReingreso = celular

          if (currentPersona.emailAddress && currentPersona.emailAddress.length > 0) {
            emailReingreso = currentPersona.emailAddress[0].emailAddress;
          }

          if (currentPersona.phoneInformation && currentPersona.phoneInformation.length > 0) {
            cellphoneReingreso = currentPersona.phoneInformation[0].phoneNumber;
          }

          const pepper_type = this.pepper.getSelectedFlow()

          await this.reportes.addToReport({
            message_id: this.parametros.getSessionId(),
            full_name: fullname.join(' ').toUpperCase(),
            document_type: "1",
            document_number: dni,
            req_type: pepper_type,
            store_name: pepper_type,
            code_seller: this.pepper.getSelectedStore(),
            record_file_evaluation: null,
            establishment: this.pepper.getSelectedStore(),
            cellphone: cellphoneReingreso + '',
            cell_operator: operador,
            created_at: m.format('YYYY/MM/DD'),
            updated_at: m.format('YYYY/MM/DD'),
            req_status: "Proceso",
            email: emailReingreso,
            commerce_type: pepper_type?.toUpperCase()[0] === 'F' ? 'Físico' : 'Online',
            currency: "PEN",
            ammount_approved: 0,
            account_number: null,
            reason: "P",
            ip_address: this.utils.ipLocal,
            properties: {
              emailOtp: emailAlter,
              fecha: m.format('YYYY-MM-DD HH:mm:ss'), // Fecha: DDMMYYY, lee del sistema
              create: m.format('DD/MM/YYYY HH:mm:ss'),
              updated: m.format('DD/MM/YYYY HH:mm:ss'),
              dia: m.format('DD'), // Día: número del día, y lo lee del sistema
              mes: m.format('MMMM'), // Mes: número del mes, y lo lee del sistema
              semana: 'SEMANA ' + m.week(), // Semana: número de semana
              tipoFlujo: this.pepper.getSelectedFlow(), // Flujo: texto ""Venta""
              flagAntifraude: antifraudeFlag ? 'SI' : 'NO', // Flag Antifraude: Rpta SI o NO
              campana: error.selectedOffer.id,
              flagCalificacion: 'SI',
              tipoCliente: currentPersona.customerId ? 'SI' : 'NO',
              flagCampania: 'SI',
              flagR1: 'NO',
              tipoAutenticacion: 'SI',
              codigoUnico: currentPersona.customerId ? currentPersona.customerId : '  ',
              pagina: 'Calificacion',
              emailForm: email,
              autorizacionDatos: (autorizacion as boolean) ? 'Si' : 'No',
              cellphoneForm: celular + '',
              device: this.utils.detectBrowser()
            }
          })

          this.navigate.to('usuario/selecciona-tarjeta')
          return;

        } else if (error instanceof HttpErrorResponse && /BDFraude/.test(error.error?.errorDetail)) {
          const pepper_type = this.pepper.getSelectedFlow()

          await this.reportes.addToReport({
            message_id: this.parametros.getSessionId(),
            full_name: '-',
            document_type: "1",
            document_number: dni,
            req_type: pepper_type,
            store_name: pepper_type,
            code_seller: this.pepper.getSelectedStore(),
            record_file_evaluation: null,
            establishment: this.pepper.getSelectedStore(),
            cellphone: celular + '',
            cell_operator: operador,
            created_at: m.format('YYYY/MM/DD'),
            updated_at: m.format('YYYY/MM/DD'),
            req_status: "Proceso",
            email: email,
            commerce_type: pepper_type?.toUpperCase()[0] === 'F' ? 'Físico' : 'Online',
            currency: "PEN",
            ammount_approved: 0,
            account_number: null,
            reason: "P",
            ip_address: this.utils.ipLocal,
            properties: {
              fecha: m.format('YYYY-MM-DD HH:mm:ss'), // Fecha: DDMMYYY, lee del sistema
              create: m.format('DD/MM/YYYY HH:mm:ss'),
              updated: m.format('DD/MM/YYYY HH:mm:ss'),
              dia: m.format('DD'), // Día: número del día, y lo lee del sistema
              mes: m.format('MMMM'), // Mes: número del mes, y lo lee del sistema
              semana: 'SEMANA ' + m.week(), // Semana: número de semana
              tipoFlujo: this.pepper.getSelectedFlow(), // Flujo: texto ""Venta""
              flagAntifraude: antifraudeFlag ? 'SI' : 'NO', // Flag Antifraude: Rpta SI o NO
              emailForm: email,
              autorizacionDatos: (autorizacion as boolean) ? 'Si' : 'No',
              cellphoneForm: celular + '',
              pagina: 'Login', // Pagina: rpta LogIn
              flagR1: 'SI',
              device: this.utils.detectBrowser()
            }
          })
          this.navigate.toServiceError('BLACKLIST')

        } else {
          const pepper_type = this.pepper.getSelectedFlow()

          const req = {
            message_id: this.parametros.getSessionId(),
            full_name: '-',
            document_type: "1",
            document_number: dni,
            req_type: pepper_type,
            store_name: pepper_type,
            code_seller: this.pepper.getSelectedStore(),
            record_file_evaluation: null,
            establishment: this.pepper.getSelectedStore(),
            cellphone: celular + '',
            cell_operator: operador,
            created_at: m.format('YYYY/MM/DD'),
            updated_at: m.format('YYYY/MM/DD'),
            req_status: "Proceso",
            email: email,
            commerce_type: pepper_type?.toUpperCase()[0] === 'F' ? 'Físico' : 'Online',
            currency: "PEN",
            ammount_approved: 0,
            account_number: null,
            reason: "P",
            ip_address: this.utils.ipLocal,
            properties: {
              fecha: m.format('YYYY-MM-DD HH:mm:ss'), // Fecha: DDMMYYY, lee del sistema
              create: m.format('DD/MM/YYYY HH:mm:ss'),
              updated: m.format('DD/MM/YYYY HH:mm:ss'),
              dia: m.format('DD'), // Día: número del día, y lo lee del sistema
              mes: m.format('MMMM'), // Mes: número del mes, y lo lee del sistema
              semana: 'SEMANA ' + m.week(), // Semana: número de semana
              tipoFlujo: this.pepper.getSelectedFlow(), // Flujo: texto ""Venta""
              flagAntifraude: antifraudeFlag ? 'SI' : 'NO', // Flag Antifraude: Rpta SI o NO
              emailForm: email,
              autorizacionDatos: (autorizacion as boolean) ? 'Si' : 'No',
              cellphoneForm: celular + '',
              pagina: 'ERROR_API_OFERTAS', // Pagina: rpta LogIn
              device: this.utils.detectBrowser()
            }
          } as any

          if ((error as Error).message !== 'BLOQUEO_POR_ANTIFRAUDE') {
            req.properties.flagR1 = error instanceof Error && error.message === 'BLACKLIST' ? 'SI' : 'NO'
          }

          await this.reportes.addToReport(req)
          const err = error as HttpErrorResponse
          if (err.status && err.status !== 403) {
            const data = {
              parametros: [
                {
                  clave: 'DETALLEERROR',
                  valor: 'ERROR Offers - '.concat(err.status ? err.status.toString() : 'interno', ' - ', err.error ? err.error['errorDetail'] ? err.error['errorDetail'] : '' : '')
                }
              ]
            }
            await this.qradar.addToRegister('ofertasOffers', data);
          }

          if (err.status === 403 && err?.error?.providerCode === '0001') {
            this.navigate.toServiceError('DOCUMENTO_NO_EXISTE');
            return;
          }

          if (err.status === 500) {
            this.navigate.toServiceError('ERROR_INTERNO_API_OFERTAS');
            return;
          }

          this.errorOfertas = true

          if (error instanceof Error && error.message) {
            this.navigate.toServiceError((error instanceof Error && error.message) ? error.message : 'ERROR_API_OFERTAS');
            return;
          } else {
            if (this.intentosOfertas > 1) {
              this.navigate.toServiceError((error instanceof Error && error.message) ? error.message : 'ERROR_API_OFERTAS');
              return;
            }
            this.intentosOfertas++
          }
          this.reportes.restartReqId()
          this.loadOfertas = false
        }
      } finally {
        this.loading = false
      }

    }
  }

  async handleResolvedCaptcha(token: string | null) {
    if (token === null) {
      this.isRecaptchaValid = false;
      this.isRecaptchaTouched = false;
      return;
    }

    const response = await this.pepperBackend.validateRecaptcha(token)

    if (response.success) {
      this.isRecaptchaValid = true;
    }

    this.isRecaptchaTouched = true
  }

  openPersonalDataModal(e: Event) {
    e.preventDefault()
    this.personalDataModal = true
  }

  openTycDataModal(e: Event) {
    e.preventDefault()
    this.tycDataModal = true
  }

  handlePersonalDataCloseModal() {
    this.personalDataModal = false
  }

  handleTycDataCloseModal() {
    this.tycDataModal = false
  }

  initializeForm(): void {
    // Configuración del formulario
    this.clientForm = new UntypedFormGroup({
      dni: new UntypedFormControl({ value: this.clientInitialValue.dni, disabled: this.comesFromDebit }, [
        Validators.required,
        documentValidator(DocumentTypes.DNI)
      ]),

      email: new UntypedFormControl(this.clientInitialValue.email, [
        Validators.required,
        emailValidator()
      ]),

      operador: new UntypedFormControl(this.clientInitialValue.operador, [
        Validators.required
      ]),

      celular: new UntypedFormControl(this.clientInitialValue.celular, [
        Validators.required,
        lengthValidator(9),
        cellphoneValidator()
      ]),

      autorizacion: new UntypedFormControl(this.clientInitialValue.autorizacion, [Validators.requiredTrue])
    });
  }

  ngAfterViewInit(): void {
    if (typeof this.sliderBeneficios === 'undefined') {
      return;
    }

    this.slider = new KeenSlider(this.sliderBeneficios.nativeElement, {
      // Configuración del slider
      mode: "snap",
      slides: {
        perView: 1,
      },
      breakpoints: {
        '(min-width: 768px)': {
          slides: {
            perView: 2
          }
        },
        '(min-width: 1280px)': {
          slides: {
            perView: 1
          }
        },
      },
    });

    this.slider.on('optionsChanged', this.generateBulletsArray);
    this.slider.on('slideChanged', () => {
      if (this.slider?.track.details.abs !== this.currentBeneficiosAbs) {
        this.currentBeneficiosAbs = (this.slider?.track.details.abs as number);
      }
    });
  }

  generateBulletsArray(): void {
    const visibleSlides = (this.slider?.options.slides as any)['perView'];
    const totalSlides = this.slider_beneficios.length;
    const pages = Math.ceil(totalSlides / visibleSlides);
    this.slider_paginas = Array.from({ length: pages }).map((_, ix) => ix);
  }

  setBeneficiosSliderProgress(abs: number) {
    this.slider?.moveToIdx(abs, true)
  }

  setNextBeneficios(e: Event) {
    e.preventDefault();
    if (this.slider?.track.details.abs === undefined) return;
    const nextIndex = this.slider?.track.details.abs + 1;
    this.slider?.moveToIdx(nextIndex, true)
  }

  setPrevBeneficios(e: Event) {
    e.preventDefault();
    if (this.slider?.track.details.abs === undefined) return;
    const nextIndex = this.slider?.track.details.abs - 1;
    this.slider?.moveToIdx(nextIndex, true)
  }

  get canGoPrev() {
    if (!this.slider) return false;
    return (this.slider as KeenSliderInstance).track.details.abs > (this.slider as KeenSliderInstance).track.details.min;
  }

  get canGoNext() {
    if (!this.slider) return false;
    return (this.slider as KeenSliderInstance).track.details.abs < (this.slider as KeenSliderInstance).track.details.max;
  }

}
