import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biometria-retry',
  templateUrl: './biometria-retry.component.html',
  styleUrls: ['./biometria-retry.component.scss']
})
export class BiometriaRetryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
