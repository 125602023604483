<div class="tc-back" id="tcBack" [class.obfuscated]="obfuscated">
    <div class="magnetic"></div>
    <div class="wrapper">
        <div class="cvv">
            <div class="graybar"></div>
            <div class="data">
                <span class="label">CVV</span>
                <span class="value" id="cvv">{{ cvv }}</span>
            </div>
        </div>
        <p>El CSC y el CVV son tus claves para hacer compras online con tu tarjeta de crédito.</p>
    </div>
</div>