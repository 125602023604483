import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tc-back',
  templateUrl: './tc-back.component.html',
  styleUrls: ['./tc-back.component.scss']
})

export class TcBackComponent implements OnInit {
  @Input() obfuscated: boolean = false
  @Input() cvv: string = ''

  constructor() { }

  ngOnInit(): void {
  }

}
