import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './screens/home/home.component';
import { SeleccionTarjetaComponent } from './screens/seleccion-tarjeta/seleccion-tarjeta.component';
import { LoadingScreenComponent } from './screens/loading-screen/loading-screen.component';
import { ErrorHorarioComponent } from './screens/error-horario/error-horario.component';
import { ErrorServicioComponent } from './screens/error-servicio/error-servicio.component';
import { UsuarioSinCampanaComponent } from './screens/usuario-sin-campana/usuario-sin-campana.component';
import { ValidarIdentidadComponent } from './screens/validar-identidad/validar-identidad.component';
import { VerificacionFacialComponent } from './screens/verificacion-facial/verificacion-facial.component';
import { OtpSendComponent } from './screens/otp/otp-send.component';
import { OtpValidarComponent } from './screens/otp-validar/otp-validar.component';
import { DatosPersonalesComponent } from './screens/datos-personales/datos-personales.component';
import { ConfigurarTcComponent } from './screens/configurar-tc/configurar-tc.component';
import { BiometriaMainComponent } from './screens/biometria/biometria-main/biometria-main.component';
import { BiometriaSuccessComponent } from './screens/biometria/biometria-success/biometria-success.component';
import { BiometriaRetryComponent } from './screens/biometria/biometria-retry/biometria-retry.component';
import { BiometriaLoadingComponent } from './screens/biometria/biometria-loading/biometria-loading.component';
import { BiometriaErrorComponent } from './screens/biometria/biometria-error/biometria-error.component';
import { BiometriaTimeoutComponent } from './screens/biometria/biometria-timeout/biometria-timeout.component';
import { TcListaComponent } from './screens/tc-lista/tc-lista.component';
import { DatosTarjetaComponent } from './screens/datos-tarjeta/datos-tarjeta.component';
import { RecibeNuevoCodigoComponent } from './screens/recibe-nuevo-codigo/recibe-nuevo-codigo.component';
import { EnviadoNuevoCodigoComponent } from './screens/enviado-nuevo-codigo/enviado-nuevo-codigo.component';
import { ErrorDatosIncompletosComponent } from './screens/error-screens/datos-incompletos/datos-incompletos.component';
import { OfertasFalloDefinitivoComponent } from './screens/error-screens/error-ofertas/ofertas-fallo-definitivo/ofertas-fallo-definitivo.component';
import { ExpedienteOtroCanalComponent } from './screens/error-screens/expediente-otro-canal/expediente-otro-canal.component';
import { LoginComponent } from './screens/pepper-debito/login/login.component';
import { TipoTarjetaComponent } from './screens/pepper-debito/tipo-tarjeta/tipo-tarjeta.component';

const routes: Routes = [
  { path: 'loading', component: LoadingScreenComponent },
  { path: 'debito/login', component: LoginComponent },
  { path: 'debito/tipo-tarjeta', component: TipoTarjetaComponent },
  { path: 'home', component: HomeComponent },
  { path: 'error/horario', component: ErrorHorarioComponent },
  { path: 'error/servicio', component: ErrorServicioComponent },
  { path: 'error/api-ofertas', component: OfertasFalloDefinitivoComponent },
  { path: 'error/api-alta', component: OfertasFalloDefinitivoComponent },
  { path: 'error/api-calificacion', component: OfertasFalloDefinitivoComponent },
  { path: 'error/api-autenticacion', component: OfertasFalloDefinitivoComponent },
  { path: 'error/llave-biometria', component: OfertasFalloDefinitivoComponent },
  { path: 'error/api-otp-authentication', component: OfertasFalloDefinitivoComponent },
  { path: 'error/datos-incompletos', component: ErrorDatosIncompletosComponent },
  { path: 'error/api-marcar-intento-otp', component: OfertasFalloDefinitivoComponent },
  { path: 'error/api-get', component: ErrorServicioComponent },
  { path: 'error/no-canal', component: ExpedienteOtroCanalComponent },
  { path: 'usuario/sin-campañas', component: UsuarioSinCampanaComponent, data: {page_name: 'sin-campañas'} },
  { path: 'usuario/no-califica', component: UsuarioSinCampanaComponent, data: {page_name: 'no-califica'} },
  { path: 'usuario/selecciona-tarjeta', component: SeleccionTarjetaComponent },
  { path: 'usuario/identidad/necesita-validar', component: ValidarIdentidadComponent },
  {
    path: 'usuario/identidad/biometria',
    component: VerificacionFacialComponent,
    children: [
      {path: 'main', component: BiometriaMainComponent},
      {path: 'accepted', component: BiometriaSuccessComponent},
      {path: 'incomplete', component: BiometriaErrorComponent, data: {title: 'Algo salió mal', message: 'Por favor, inténtalo nuevamente en unos momentos.'}},
      {path: 'rejected', component: BiometriaErrorComponent, data: {title: 'No te hemos reconocido', message: 'Tu identidad no ha podido ser verificada. Por favor, reinténtalo más tarde.'}},
      {path: 'blocked', component: BiometriaErrorComponent, data: {title: 'No te hemos podido identificar', message: 'Por tu seguridad, podrás realizar más intentos de validación a partir de mañana.'}},
      {path: 'retry', component: BiometriaRetryComponent},
      {path: 'loading', component: BiometriaLoadingComponent},
      {path: 'timeout', component: BiometriaTimeoutComponent}
    ]
  },

  { path: 'usuario/identidad/enviar-otp', component: OtpSendComponent },
  { path: 'usuario/identidad/validar-otp', component: OtpValidarComponent },
  { path: 'usuario/identidad/datos-personales', component: DatosPersonalesComponent },
  { path: 'usuario/configurar-tc', component: ConfigurarTcComponent },
  { path: 'tarjeta/lista', component: TcListaComponent },
  { path: 'tarjeta/datos', component: DatosTarjetaComponent },
  { path: 'nuevo-codigo', component: RecibeNuevoCodigoComponent },
  { path: 'nuevo-codigo/enviado', component: EnviadoNuevoCodigoComponent },
  
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
