import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiAutenticacionService } from 'src/app/services/api-autenticacion/api-autenticacion.service';
import { ApiOfertasService } from 'src/app/services/api-ofertas/api-ofertas.service';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { PepperService } from 'src/app/services/pepper/pepper.service';
import { ApiCalificacionService } from 'src/app/services/api-calificacion/api-calificacion.service';
import { ParametrosService } from 'src/app/services/parametros/parametros.service';

@Component({
  selector: 'app-validar-otp',
  templateUrl: './otp-validar.component.html',
  styleUrls: ['./otp-validar.component.scss']
})
export class OtpValidarComponent implements OnInit {

  persona = {
    nombre: '',
  }
  counter = 0
  loading = false

  errorValidar = false
  loadValidar = false
  intentosValidar = 0
  errorValidarMessage = 'Hemos tenido un error al cargar el código de la clave dinámica, por favor inténtalo de nuevo.'

  private invalidToken = false
  mailMatch = true
  mailOfuscation = "email"
  extraStep = false


  get incorrectToken() {
    return this.invalidToken
  }


  otpValidationForm: FormGroup;

  constructor(
    private autenthicate: ApiAutenticacionService,
    private ofertas: ApiOfertasService,
    private nav: NavigateService,
    private pepper: PepperService,
    private calificacion: ApiCalificacionService,
    private reportes: ReportesService,
    private parametros: ParametrosService,
    private utils: UtilsService
  ) {
    this.extraStep = !!this.pepper.getNeedsExtraStep()
    this.otpValidationForm = new FormGroup({
      token: new FormControl('', [
        Validators.required,
        Validators.pattern(/[0-9]{8}/)
      ])
    })

    this.otpValidationForm.get('token')?.valueChanges.subscribe(() => {
      this.invalidToken = false
    })

    let personMatchMail = this.ofertas.getPerson();
    let emailforMatch = this.autenthicate.target.email;
    let formEmailforMatch = this.autenthicate.formEmail;

    if (personMatchMail && personMatchMail.customerId && emailforMatch && formEmailforMatch) {
      this.mailMatch = (emailforMatch.toLowerCase() === formEmailforMatch.toLowerCase());
      this.mailOfuscation = this.mailMatch ? "email" : "emailForMessage"
    }

  }

  async validateOtp() {
    const tokenControl = this.otpValidationForm.get('token')
    if (!tokenControl) return;

    const value = tokenControl.value
    this.loading = true

    if (this.intentosValidar > 0) this.loadValidar = true

    try {
      await this.autenthicate.marcarIntento(value)
      this.errorValidar = false
    } catch (error) {
      const err = error as HttpErrorResponse
      if (err.status === 403 && err?.error?.providerCode === 'BE001') this.nav.toServiceError('SUPERO_INTENTOS_OTP', true, 'Lo sentimos,', 'en este momento no podemos atenderte. Has superado el límite de intentos.<br><br>Podrás volver a intentarlo el siguiente mes.', false)
      this.errorValidar = true
      if (this.intentosValidar > 1) this.nav.toServiceError('ERROR_API_MARCAR_INTENTO_OTP')
      this.intentosValidar++
      this.loadValidar = false
      return;
    }

    try {
      const response: any = await this.autenthicate.verificarToken(value)
      await this.reportes.addToReport({
        req_status: 'Proceso',
        properties: {
          tipoAutenticacion: response?.validated === "true" ? 'OTP-SI' : 'OTP-NO',//aqui va OTP-si o OTP-NO
          pagina: 'Validar OTP',
          device: this.utils.detectBrowser()
        }
      })

      if (response?.validated !== "true") {
        const control = this.otpValidationForm.get('token')
        if (control) {
          control.setValue('')
          control.markAsPristine()
        }
        this.invalidToken = true
      } else {
        if (this.parametros.config['FlagBiometria'] === true) {
          this.calificacion.registrarExpedienteRedis(true)
          this.nav.to('usuario/configurar-tc')
        } else {
          this.nav.to('usuario/identidad/necesita-validar')
        }
      }
    } catch (e) {
      this.nav.toServiceError('ERROR_API_OTP_AUTHENTICATION')
    }

    this.loading = false
  }

  get targetEmail() {
    return this.autenthicate.target.email
  }

  get matchEmailMessage() {
    return "El correo donde se la ha enviado el código no es el que usted ha ingresado en el formulario"
  }

  get nombrePersona() {
    const persona = this.ofertas.getPerson()
    return persona.firstName
  }

  get showAlertUnmatchEmail() {
    return !this.mailMatch;
  }

  get emailOfuscation() {
    return this.mailOfuscation;
  }


  resending = false

  async resendToken() {
    this.resending = true
    try {
      await this.autenthicate.regenerateToken()
      this.restartTimer()
    } catch (error) {
      this.nav.toServiceError('ERROR_API_AUTENTICACION')
    } finally {
      this.resending = false
    }
  }

  restartTimer() {
    this.counter = 60
    const handleCount = () => {
      if (this.counter === 1) {
        clearInterval(interval)
      }
      this.counter--

    }

    const interval = setInterval(handleCount, 1000)
  }

  parseTime(seconds: number) {
    let _seconds;

    if (seconds > 9) {
      _seconds = seconds + ''
    } else {
      _seconds = '0' + seconds
    }

    return '00:' + _seconds
  }



  async ngOnInit(): Promise<void> {
  }

}
