<step-indicator [current]="extraStep ? 6 : 5" title="Configura tu tarjeta"></step-indicator>
<sticky-notice-bar *ngIf="!errorAlta"></sticky-notice-bar>
<section *ngIf="!errorAlta" class="page-configurar-tc">
    <form *ngIf="configuracionForm" class="container form" [formGroup]="configuracionForm" (ngSubmit)="handleSubmit()" id="configurarTcForm">
        <h3 class="heading-3">Tu nueva tarjeta <strong id="title">{{ card.info.nombreTipo | titlecase }}</strong> ha sido aprobada</h3>
    
        <img class="card" [src]="chAssetUrl + card.meta.onboarding_image.url" [alt]="card.info.nombreTipo | titlecase">
    
        <p class="text-1">Ahora, escoge tu línea de crédito:</p>
    
        <div class="linea-amount__wrapper" [class.error]="linea_credito.error">
            <div class="linea-amount">
                <input type="text" mask="separator.0" name="linea" thousandSeparator="," [readonly]="executingAlta" formControlName="linea" id="inputLinea" (blur)="roundLineaCredito()">
            </div>
            <p class="error-message">Ingresa un monto válido.</p>
    
            <input-range [step]="100" class="linea-credito-range" [min]="linea_credito.min" [max]="linea_credito.max" [value]="linea_credito.current" (changes)="handleLineaCreditoSliderChange($event)"></input-range>
        </div>
    

        <div class="notice error" [hidden]="linea_credito.error === false">{{ linea_credito.error }}</div>
    
        <div class="info-block_wrapper">
            <div class="info-block">Seguro de desgravamen: Se calcula multiplicando el saldo promedio diario por 0.39% Máx. S/20 o US$5.</div>
        </div>

        
        <section class="datos-pago">
            <hr class="separator">
            <h4 class="heading-4">Datos de pago y estado de cuenta</h4>
            <div class="wrapper">
                <label>
                    <span>Elige el día de pago que prefieras</span>
                    <ng-select  class="input" [readonly]="executingAlta" formControlName="diaPago" id="inputDiaPago" placeholder="Selecciona ..." [clearable]="false">
                        <ng-option *ngFor="let diaPago of diasPago" [value]="diaPago.id">{{diaPago.moment}}</ng-option>
                    </ng-select>
                </label>
            </div>
        </section>
        
        <section class="sobre-tienda" *ngIf="isFisico">
            <hr class="separator">
            <h4 class="heading-4">Sobre la tienda</h4>
            <p class="text-3 text-center">El vendedor de la tienda en donde te encuentras, te brindará su código personal para que puedas registrarlo.</p>
            <div class="wrapper">
                <label>
                    <span>Ingresa el código del vendedor</span>
                    <input class="input" type="text" maxlength="15" [readonly]="executingAlta" formControlName="codigoVendedor" id="inputCodigoVendedor">
                </label>
            </div>
        </section>

        <section class="datos-envio">
            <hr class="separator">
            <h4 class="heading-4">Datos para el envío de tu tarjeta física</h4>
            <p class="text-1">Elige la dirección para la entrega:</p>

            <div *ngFor="let direccion of direcciones">
                <label class="address" [ngClass]="{active: configuracionForm.controls['address'].value?.id === direccion.id, unreachable: unreacheableAddress}">
                    <input type="radio" [readonly]="executingAlta" formControlName="address" id="inputAddress" [value]="direccion">
                    <p>{{ direccion.address | titlecase }}</p>
                </label>
                <section class="unreachable-notice notice info mb-100">
                    Tu dirección no se encuentra en nuestro rango de cobertura para la entrega. No te preocupes, nos comunicaremos contigo para coordinar la entrega.
                </section>
            </div>


            <button type="button" class="add-address" (click)="toggleAddAddressSubform()" [ngClass]="{active: addressFormViewPortHeight > 0}">
                Agregar otra dirección
            </button>
            
            <div class="screen-datos-personales" [ngStyle]="{height: addressFormViewPortHeight + 'px', overflow: addressFormViewPortHeight > 0 ? 'visible' : 'hidden'}">

                <form *ngIf="addressForm" [formGroup]="addressForm" class="form" #addAddressSubform (ngSubmit)="handleAddAddress()">
                    <div class="datos-domicilio-form">
                        
                        <label>
                            <span>Departamento</span>
                            <ng-select appendTo="#configurarTcForm" [clearable]="false" [loading]="departamentosLoading" (change)="handleChangeDepartamento()" class="input" [readonly]="executingAlta" formControlName="departamento" id="inputDepartamento" >
                                <ng-option *ngFor="let departamento of departamentos" [value]="departamento">{{departamento.ubigeoName}}</ng-option>
                            </ng-select>
                        </label>
                        
                        <label>
                            <span>Provincia</span>
                            <ng-select appendTo="#configurarTcForm" [clearable]="false" [loading]="provinciasLoading" [disabled]="provinciasLoading" (change)="handleChangeProvincia()" class="input" [readonly]="executingAlta" formControlName="provincia" id="inputProvincia" >
                                <ng-option *ngFor="let provincia of provincias" [value]="provincia">{{provincia.ubigeoName}}</ng-option>
                            </ng-select>
                        </label>
                        
                        <label>
                            <span>Distrito</span>
                            <ng-select appendTo="#configurarTcForm" [clearable]="false" [loading]="distritosLoading" [disabled]="distritosLoading"  class="input" [readonly]="executingAlta" formControlName="distrito" id="inputDistrito" >
                                <ng-option *ngFor="let distrito of distritos" [value]="distrito">{{distrito.ubigeoName}}</ng-option>
                            </ng-select>
                        </label>
                        
                        <label>
                            <span>Tipo de vía</span>
                            <ng-select appendTo="#configurarTcForm" [clearable]="false"  class="input" [readonly]="executingAlta" formControlName="tipoDeVia" id="inputTipoDeVia" >
                                <ng-option *ngFor="let via of vias" [value]="via.id">{{via.name}}</ng-option>
                            </ng-select>
                        </label>
                        
                        <label class="nombre-via">
                            <span>Nombre de via</span>
                            <input class="input" type="text" [readonly]="executingAlta" maxlength="100" formControlName="nombreDeVia" id="inputNombreDeVia" >
                            <span class="error-msj">{{addressForm.controls['nombreDeVia'].errors | formControlErrorMessage:'nombre De Via'}}</span>
                        </label>
            
                        <div class="group-detalle">
                            <label>
                                <span>Número</span>
                                <input class="input" type="text" maxlength="5" [readonly]="executingAlta" formControlName="numero" id="inputNumero" >
                                <span class="error-msj">{{addressForm.controls['numero'].errors | formControlErrorMessage:'número'}}</span>
                            </label>
                            
                            <label>
                                <span>Manzana</span>
                                <input class="input" type="text" maxlength="10" [readonly]="executingAlta" formControlName="manzana" id="inputManzana" >
                                <span class="error-msj">{{addressForm.controls['manzana'].errors | formControlErrorMessage:'manzana'}}</span>
                            </label>
                            
                            <label>
                                <span>Lote</span>
                                <input class="input" type="text" [readonly]="executingAlta" maxlength="10" formControlName="lote" id="inputLote" >
                                <span class="error-msj">{{addressForm.controls['lote'].errors | formControlErrorMessage:'lote'}}</span>
                            </label>
                            
                            <label>
                                <span>Interior</span>
                                <input class="input" type="text" maxlength="10" [readonly]="executingAlta" formControlName="interior" id="inputInterior" >
                            </label>
                            
                        </div>
                    </div>

                    <div class="text-center">
                        <button class="btn btn-outline" [disabled]="addressForm.invalid">
                            Guardar dirección
                        </button>
                    </div>
                </form>
            </div>
            
        </section>

        <section *ngIf="loadingCoordinacionEntrega" class="loader full-width">
            <img src="../../../../assets/preloader.svg" class="center-block">
        </section>

        

         <section class="fecha-entrega" [ngClass]="{megahidden: !configuracionForm.controls['address'].value || loadingCoordinacionEntrega || unreacheableAddress}">
            <p class="text-1">Elige la fecha y horario de entrega:</p>
            <h5 *ngIf="hasDateAndTime" class="heading-5 text-center"><strong>Mes: {{ month | titlecase }}</strong></h5>
            
            <ul class="fechas">
                <li *ngFor="let fecha of fechas">
                    <label>
                        <input type="radio" class="megahidden" [readonly]="executingAlta" formControlName="fechaSeleccionada" id="inputFechaSeleccionada" [value]="fecha.id">
                        <span>
                            <span class="dia">{{ fecha.dia }}</span>
                            <span class="numero">{{ fecha.numero }}</span>
                        </span>
                    </label>
                </li>
            </ul>
            
            <ul class="rango-horario">
                <li *ngFor="let rango of rango_horas">
                    <label>
                        <input type="radio" class="megahidden" [readonly]="executingAlta" formControlName="rangoHorarioSeleccionado" id="inputRangoHorarioSeleccionado" [value]="rango">
                        <span>
                            {{ rango.name }}
                        </span>
                    </label>
                </li>
            </ul>
        </section>

        <section *ngIf="!hasDateAndTime && !loadingCoordinacionEntrega" class="reload full-width">
            <img (click)="reloadDates()" src="../../../../assets/icon/reload.svg" class="center-block">
            <h5 (click)="reloadDates()" class="heading-5 text-center color-green weight-medium2 mt-050">Volver a cargar fecha y horario</h5>
        </section>

        <section class="configuraciones">
            <hr class="separator">
            <h4 class="heading-3 mt-075">Configura tus operaciones</h4>
            <p class="heading-5 text-center mb-100 mt-075">Considera que puedes activar y/o desactivar los siguientes servicios para tu nueva tarjeta de crédito.</p>

            <div class="bs_wrapper">
                <label class="big-switch">
                    <!-- <input type="checkbox" name="config_id[]" [value]="config.id"> -->
                    <input type="checkbox" [readonly]="executingAlta" formControlName="comprasIntExt" id="switchComprasIntExt">
                    <span class="switch"></span>
                    <div class="mt-050">
                        <h5 class="heading-5 weight-medium2">Compras por internet y uso en el exterior</h5>
                        <p class="text-3">Realiza compras y consumos en comercios web tales como: Falabella.com, Netlfix, Rappi, LATAM, Uber, Cineplanet, Mercado Libre, PlazaVea,com, entre otros. Además, podrás usarla al exterior del Perú.</p>
                        <div *ngIf="!configuracionForm.controls['comprasIntExt'].value && (isCardless || isOnline)" class="notice warning">Al desactivar esta opción no podrás realizar tu compra por internet con tu tarjeta digital.</div>
                    </div>
                </label>
            </div>
            <div class="bs_wrapper">
                <label class="big-switch">
                    <!-- <input type="checkbox" name="config_id[]" [value]="config.id"> -->
                    <input type="checkbox" [readonly]="executingAlta" formControlName="dispEfectivo" id="switchDispEfectivo">
                    <span class="switch"></span>
                    <div>
                        <h5 class="heading-5 weight-medium2">Disposición de efectivo</h5>
                        <p class="text-3">Retira efectivo de tu tarjeta de crédito en cajeros o por transferencia. Consulta tu Estado de Cuenta para verificar tu tasa de interés.</p>
                    </div>
                </label>
            </div>
            <div class="bs_wrapper">
                <label class="big-switch">
                    <!-- <input type="checkbox" name="config_id[]" [value]="config.id"> -->
                    <input type="checkbox" [readonly]="executingAlta" formControlName="sobregiro" id="switchSobregiro">
                    <span class="switch"></span>
                    <div>
                        <h5 class="heading-5 weight-medium2">Sobregiro</h5>
                        <p class="text-3">Podrás usar un 10% adicional de la línea de crédito de tu tarjeta. Consulta tu Estado de Cuenta para verificar tu tasa de interés.</p>
                    </div>
                </label>
            </div>
        </section>
        
        <label class="checkbox legal_1">
            <input type="checkbox" [readonly]="executingAlta" formControlName="checkboxCondicionesMembresia" id="inputCheckboxCondicionesMembresia">
            <span class="checkbox"></span>
            <p>He leído y acepto las <a class="no-text-decoration color-green weight-medium2" (click)="openMembresiaModal($event)" href="#">Condiciones de Cobro por Membresía y derecho de Endoso de Seguro de Vida.</a></p>
        </label>

        <label class="checkbox legal_1">
            <input type="checkbox" [readonly]="executingAlta" formControlName="checkboxTerminos" id="inputCheckboxTerminos">
            <span class="checkbox"></span>
            <p>He leído y acepto los <a class="no-text-decoration color-green weight-medium2" (click)="openTycModal($event)" href="#">Términos y Condiciones.</a></p>
        </label>

        <label class="checkbox legal_1">
            <input type="checkbox" [readonly]="executingAlta" formControlName="checkboxPolitica" id="inputCheckboxPolitica">
            <span class="checkbox"></span>
            <p>He leído y acepto las <a class="no-text-decoration color-green weight-medium2" (click)="openPoliticaModal($event)" href="#">Política de Privacidad.</a></p>
        </label>

        
        <label class="checkbox legal_2">
            <input type="checkbox" [readonly]="executingAlta" formControlName="checkboxAutorizacion" id="inputCheckboxAutorizacion">
            <span class="checkbox"></span>
            <p>Autorizo el uso de mis datos personales para las finalidades opcionales previstas en la Política de Privacidad.</p>
        </label>

        <button id="btnsumbit" type="submit" [disabled]="configuracionForm.invalid || executingAlta || loadingCoordinacionEntrega || !hasDateAndTime" class="btn fw--mobile center-block">{{executingAlta ? 'Cargando ...' : 'Solicitar tarjeta'}}</button>
    </form>

</section>
<section *ngIf="!errorAlta" class="text-legales">
    <div class="container">
        <small>{{ legales }}</small>
    </div>
</section>
<section *ngIf="errorAlta">
    <app-error-ofertas [message]="errorAltaMessage" [load]="loadAlta" (retry)="handleSubmit()"></app-error-ofertas>
</section>

<pepper-modal class="modal-type" [active]="membresiaModal" (close)="handleMembresiaCloseModal()" (overlay)="handleMembresiaCloseModal()" [actionButton]="true" actionButtonLabel="Entiendo" (actionButton)="handleMembresiaCloseModal()">
    <div class="container page-modal" [innerHTML]="contentMembresiaModal | safe:'html'"></div>
</pepper-modal>

<pepper-modal class="modal-type" [active]="tycModal" (close)="handleTycCloseModal()" (overlay)="handleTycCloseModal()" [actionButton]="true" actionButtonLabel="Entiendo" (actionButton)="handleTycCloseModal()">
    <div class="container page-modal" [innerHTML]="contentTycModal | safe:'html'"></div>
</pepper-modal>

<pepper-modal class="modal-type" [active]="politicaModal" (close)="handlePoliticaCloseModal()" (overlay)="handlePoliticaCloseModal()" [actionButton]="true" actionButtonLabel="Entiendo" (actionButton)="handlePoliticaCloseModal()">
    <div class="container page-modal" [innerHTML]="contentPoliticaModal | safe:'html'"></div>
</pepper-modal>