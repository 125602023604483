import { Component } from "@angular/core";

@Component({
    selector: 'app-politica-privacidad',
    templateUrl: './politica-privacidad.component.html',
    styleUrls: ['./politica-privacidad.component.scss']
})
export class PoliticaPrivacidadComponent {

    constructor() {}
    
}