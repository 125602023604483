import { Component, OnInit } from "@angular/core";
import { NavigateService } from "src/app/services/navigate/navigate.service";
import { ReportesService } from "src/app/services/reportes/reportes.service";
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-error-datos-incompletos',
  templateUrl: './datos-incompletos.component.html',
  styleUrls: ['./datos-incompletos.component.scss']
})
export class ErrorDatosIncompletosComponent implements OnInit {

  private data;

  constructor(
    private navigate: NavigateService,
    private reporte: ReportesService,
    private utils: UtilsService
  ) {
    this.data = this.navigate.getCurrentRouteData()
  }

  async ngOnInit(): Promise<void> {
    if (!this.data['codigo_error']) return;

    await this.reporte.addToReport({
      properties: {
        pagina: this.data['codigo_error'],
        device: this.utils.detectBrowser()
      }
    })
  }

  reloadApp(): void {
    window.location.reload()
  }

}