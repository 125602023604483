import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IpInfoService } from './ip-info.service';
import { IpInfoResponse } from './response/IpInfoResponse';


@Injectable({
  providedIn: 'root',
})
export class FingerprintService {

  constructor(
    private _httpClient: HttpClient,
    private _ipInfoService: IpInfoService
  ) { }

  async getGeolocation(): Promise<IpInfoResponse> {
    const response$ = this._ipInfoService.getIpInfo();
    const response = lastValueFrom(response$);
    return response;
  }

  async sendFingerprint(fingerprint: any) {
    try {
      const geoIp = await this.getGeolocation();
      fingerprint.Geoip = geoIp;
      return fingerprint;
    } catch (error) {
      return fingerprint;
    }
  }
}