<div class="input-range">
    <div class="current_wraper">
        <span #currentIndicator class="current">S/ {{val | mask: 'separator.2'}}</span>
    </div>
    <input
        id="range_selector_linea"
        name="range_selector_linea"
        [step]="step"
        #ir
        type="range"
        [min]="min"
        [max]="max"
        [ngModel]="val + ''"
        (ngModelChange)="onChange($event)">
    <div class="limits">
        <p>S/ {{min | mask: 'separator.2'}}</p>
        <p>S/ {{max | mask: 'separator.2'}}</p>
    </div>
</div>
<!-- <input type="range" [name]="name" min="50" max="500" [ngModel]="val" (ngModelChange)="onChange($event)"> -->