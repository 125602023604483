import { Component, OnInit, Input } from '@angular/core';
import { ApiOfertasService } from 'src/app/services/api-ofertas/api-ofertas.service';
import { PepperService } from 'src/app/services/pepper/pepper.service';
// import { Router, Event, NavigationEnd} from '@angular/router';

@Component({
  selector: 'step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss'],
  inputs: [
    'current'
  ]
})
export class StepIndicatorComponent implements OnInit {
  
  @Input() current: number = 1;
  @Input() title: string = '';

  // current: number = 2;
  extraStep = false

  constructor(
    private pepper: PepperService
  ) {
    // this.router.events.subscribe((event: Event) => {
    //   // if (event instanceof NavigationStart) {
    //   //     // Show progress spinner or progress bar
    //   //     console.log('Route change detected');
    //   // }

    //   if (event instanceof NavigationEnd) {
    //       // Hide progress spinner or progress bar
    //       // this.currentRoute = event.url;          
    //       console.log(event);
    //   }

    //   // if (event instanceof NavigationError) {
    //   //      // Hide progress spinner or progress bar

    //   //     // Present error to user
    //   //     console.log(event.error);
    //   // }
    // });
  }

 

  ngOnInit(): void {
    const person = this.pepper.currentPerson()
    if (!person || person.needsExtraStep) {
      this.extraStep = true
    }
  }

}
