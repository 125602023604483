<div class="page-error-datos">
    <div class="container">
        <img src="../../../assets/icon/error/datos-incompletos.svg" alt="Error">
        <p class="text-1">
            <span class="title">Lo sentimos,</span>
        </p>
        <p class="text-1">
            Al parecer tus datos están incompletos o desactualizados, <strong> por tu seguridad </strong>es necesario que te acerques al banco para actualizarlos.
        </p>
        <p class="text-3">
            <span class="schedule">Lunes a viernes de 9am a 6pm</span>
            <span class="schedule">Sábados de 9am a 1pm</span>
        </p>

        <button (click)="reloadApp()" class="btn center-block fw--mobile">Volver al inicio</button>
    </div>
</div>