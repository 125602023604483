<step-indicator [current]="extraStep ? 4 : 3" title="Identifícate"></step-indicator>
<sticky-notice-bar *ngIf="!errorValidar"></sticky-notice-bar>
<section *ngIf="!errorValidar" class="screen-otp">
    <div class="container">
        <h3 class="heading-3 text-left text-center--mobile"><strong id="title">Hola {{ nombrePersona | ucwords }},</strong></h3>
        <p class="text-1 text-left text-center--mobile" *ngIf="targetEmail && emailOfuscation">Te hemos enviado un código de verificación
            <span class="break--tablet break--desktop"></span> 
            a tu correo {{ targetEmail | lowercase | obfuscate:emailOfuscation  }}. Recuerda
            <span class="break--tablet break--desktop"></span> 
            revisar la bandeja de spam.
        </p>

        <div class="notice warning alert" role="alert">
            En caso no recibieras el código de validación al correo registrado,
            <span class="break--tablet break--desktop"></span> 
            por favor, acércate a tu agencia Interbank más cercana para
            <span class="break--tablet break--desktop"></span>
            actualizar tus datos
         </div>
        
        <form (ngSubmit)="validateOtp()" class="form" [formGroup]="otpValidationForm">
            <label [ngClass]="{'mb-225': incorrectToken}">
                
                <span>Ingresa el código</span>
                <input class="input" type="password" id="input_token" formControlName="token" mask="00000000">
                <span class="error-msj l-fixed" *ngIf="incorrectToken">El código es incorrecto. Intentalo nuevamente</span>
            </label>
    
            <p class="retry-otp" id="msj_resend_timer" *ngIf="counter > 0">Solicita uno nuevo en {{ parseTime(counter) }}</p>
            <p class="retry-otp"  id="msj_resend_link" *ngIf="counter === 0 && !resending"><a href="#" (click)="$event.preventDefault(); resendToken();" class="color-green no-text-decoration text-3 weight-medium2">Aún no recibes el código <img style="height: 0.8rem; width: auto; vertical-align: middle;" src="../../../assets/icon/chevron-left-green.svg" alt="Reenviar código"></a></p>
            <p class="retry-otp" id="msj_resending" *ngIf="counter === 0 && resending">Reenviando código ...</p>
    
            <button type="submit" id="submit_btn" class="btn" [disabled]="otpValidationForm.invalid || loading">Listo</button>
        </form>
    </div>
</section>
<section *ngIf="errorValidar">
    <app-error-ofertas [message]="errorValidarMessage" [load]="loadValidar" imagen="../../../assets/icon/error/error-validacion.svg" boton="Intentar nuevamente" (retry)="validateOtp()"></app-error-ofertas>
</section>