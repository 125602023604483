import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InternalService } from 'src/app/common/InternalService';
import { environment } from 'src/environments/environment';
import { ApiCalificacionService } from '../api-calificacion/api-calificacion.service';
import { ApiOfertasService } from '../api-ofertas/api-ofertas.service';
import { ParametrosService } from '../parametros/parametros.service';
import { PepperService } from '../pepper/pepper.service';
import { GetResponse } from './responses/GetResponse';

@Injectable({
  providedIn: 'root'
})
export class ApiGetService extends InternalService {

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient,
    private calificacion: ApiCalificacionService,
    private pepper: PepperService
  ) {
    super(parametros, http, environment.services.pepper);
  }

  public async getDeliveryDates (ubigeo: number) {
    try {
      const oferta = this.pepper.getSelectedCard()
      const proposal = this.calificacion.proposal

      const response = await this.doRequestToEndpoint<GetResponse>('get', {
        params: {
          issuanceType: '01',
          channelCode: '99',
          saleType: 'T',
          currencyId: '604',
          isDigitalSale: 'true',
          cardBrand: oferta.marcaTarjeta,
          cardType: oferta.tipoTarjeta,
          creditLine: parseInt(proposal.maxLine),
          ubigeoCode: ubigeo,
        }
      })

      return response
    } catch (error) {
      throw error
    }
  } 
}
