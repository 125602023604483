import { Component, OnInit } from '@angular/core';
import { OtpService } from 'src/app/services/otp/otp.service';

@Component({
  selector: 'app-enviado-nuevo-codigo',
  templateUrl: './enviado-nuevo-codigo.component.html',
  styleUrls: ['./enviado-nuevo-codigo.component.scss']
})
export class EnviadoNuevoCodigoComponent implements OnInit {

  constructor(
    private otp: OtpService
  ) {
    if (otp.user === null) {
      throw new Error()
    }
  }

  get user () {
    return this.otp.user
  }

  ngOnInit(): void {
  }

}
