<div class="page-usuario-sin-campana">
    <div class="container">
        <p class="text-1 about-that">Evaluamos tu información, y por ahora <strong>no tenemos una tarjeta de crédito disponible</strong> para ti.</p>
        <hr class="separator">
        <h2>
            <small>Aún no te vayas,</small>
            ¡Tenemos algo para ti!
        </h2>
    
        <img class="center-block" src="../../../assets/icon/abrecuenta.svg" alt="Abre tu cuenta">
    
        <p class="text-1 offer">Abre tu nueva Cuenta Simple 100% digital, y <strong>{{title}}.</strong></p>
    
        <a [href]="url" class="btn fw--mobile" target="_blank">Abrir Cuenta Simple</a>
    </div>
</div>
<section class="text-legales">
    <div class="container">
        <small>{{legal}}</small>
    </div>
</section>