import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiCalificacionService } from 'src/app/services/api-calificacion/api-calificacion.service';
import { ApiGetService } from 'src/app/services/api-get/api-get.service';
import { PepperService } from 'src/app/services/pepper/pepper.service';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import moment from 'moment'
import 'moment/locale/es'
import { alphanumeric, alphanumericExtended, alphanumericExtended1, numeric } from 'src/utils/formControlValidators';
import { GetResponse } from 'src/app/services/api-get/responses/GetResponse';
import { PostalAddress } from 'src/app/services/api-ofertas/responses/OfertasResponse';
import { Ubigeo } from 'src/app/services/parametros/responses/Ubigeo';
import padStart from 'lodash-es/padStart';
import { ParametrosService } from 'src/app/services/parametros/parametros.service';
import { ApiAltaService } from 'src/app/services/api-alta/api-alta.service';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import { VIAS } from 'src/app/common/constants/vias.constant';
import { QradarService } from 'src/app/services/qradar/qradar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
moment.locale('es')

@Component({
  selector: 'app-configurar-tc',
  templateUrl: './configurar-tc.component.html',
  styleUrls: ['./configurar-tc.component.scss']
})
export class ConfigurarTcComponent implements OnInit {

  constructor(
    private pepper: PepperService,
    private apiGet: ApiGetService,
    private calificacion: ApiCalificacionService,
    private parametros: ParametrosService,
    private alta: ApiAltaService,
    private qradar: QradarService,
    private nav: NavigateService,
    private utils: UtilsService,
    private reporte: ReportesService
  ) {
    this.legales = this.pepper.getContentData()?.partner_legal['value']
  }

  @ViewChild('addAddressSubform') addAddressSubform: any;

  configuracionForm: FormGroup | undefined
  addressForm: FormGroup | undefined

  loadingCoordinacionEntrega = false;
  coordinacionEntrega: GetResponse | undefined

  private subscriptions: Subscription[] = [];

  
  departamentos: Ubigeo[] = []
  departamentosLoading: boolean = false

  provincias: Ubigeo[] = []
  provinciasLoading: boolean = false

  distritos: Ubigeo[] = []
  distritosLoading: boolean = false

  extraStep = false
  intentos = 0
  valorGeografico = 0
  hasDateAndTime = true

  errorAlta = false
  loadAlta = false
  intentosAlta = 0
  errorAltaMessage = 'Hemos tenido un error al cargar tu solicitud, por favor inténtalo de nuevo.'

  legales !: string

  roundLineaCredito () {
    const value = Math.round(this.linea_credito.current / 100) * 100

    const lineaControl = this.configuracionForm?.get('linea')

    lineaControl?.setValue(value)
  }

  vias = VIAS;

  get chAssetUrl () {
    return environment.consts?.chAssetsUrl
  }

  get isFisico () {
    return this.pepper.getSelectedFlow() === 'fisico'
  }

  get isCardless () {
    return this.pepper.getSelectedFlow() === 'cardless'
  }

  get isOnline () {
    return this.pepper.getSelectedFlow() === 'online'
  }

  linea_credito: {min: number, max: number, current: number, error: string | boolean} = {
    min: 0,
    max: 0,
    current: 0,
    error: false
  }

  public direcciones: Array<{id: PostalAddress, address: string}> = []
  private populateDirecciones (): void {
    const user = this.pepper.currentPerson()
    if (!user) return;
    this.direcciones = user.postalAddress.map((address: any) => {
      const d = address.standardizedPostalAddress
      const response = {
        id: address,
        address: `${d.streetType} ${d.streetName} ${d.streetNumber}`
      }

      if (d.blockIdentification)
        response.address += ` Mz ${d.blockIdentification}`

      if (d.lotNumber)
        response.address += ` Lt ${d.lotNumber}`

      if (d.interiorNumber)
        response.address += ` Int ${d.interiorNumber}`
        
      response.address += ` - ${address.provinceCode}`
      response.address += ` - ${address.districtCode}`

      return response
      
    })
  }

  unreacheableAddress = false

  public fechas: Array<{id: string, dia: string, numero: string}> = []
  private populateFechas (): void {
    if (!this.coordinacionEntrega) return;
    if (!this.coordinacionEntrega.dates || this.coordinacionEntrega.dates.length === 0) return;
    this.fechas = this.coordinacionEntrega.dates.map(d => {
      const m = moment(d)
      return {
        id: d,
        dia: m.format('ddd'),
        numero: m.format('DD')
      }
    })
  }

  public rango_horas: Array<{id: number, hour: string, name: string}> = []
  private populateRangoHoras (): void {
    if (!this.coordinacionEntrega) return;
    if (!this.coordinacionEntrega.dates || this.coordinacionEntrega.dates.length === 0) return;
    this.rango_horas = this.coordinacionEntrega.shifts.map(shift => {
      return {
        id: shift.id,
        hour: shift.hour,
        name: shift.description
      }
    })
  }

  get month (): string {
    if (!this.coordinacionEntrega) return ''
    if (!this.coordinacionEntrega.dates || this.coordinacionEntrega.dates.length === 0) return '';
    const m = moment(this.coordinacionEntrega.dates[0])
    return m.format('MMMM')
  }

  handleMontoInputChange (evt: any) {
    this.linea_credito.current = evt.target.value
  }

  get card () {
    return {
      info: this.pepper.getSelectedCard(),
      meta: this.pepper.getSelectedCardData()
    }
  }

  public diasPago: Array<{id: number, moment: any}> = []
  private populateDiasPago (): void {
    this.diasPago = this.card.info.diasPago.map((day: string) => {
      return {
        id: day,
        moment: `Los ${padStart(day, 2, '0')} de cada mes`
      }
    })
  }

  handleLineaCreditoSliderChange (val: number) {
    this.linea_credito.error = false
    this.configuracionForm?.get('linea')?.setValue(val)
    this.linea_credito.current = val
  }

  async ngOnInit(): Promise<void> {
    this.linea_credito.min = parseInt(this.calificacion.proposal.minLine)
    this.linea_credito.max = parseInt(this.calificacion.proposal.maxLine)
    this.linea_credito.current = parseInt(this.calificacion.proposal.maxLine)

    const person = this.pepper.currentPerson()
    this.extraStep = !!person?.needsExtraStep

    const controls: any = {
      linea: new FormControl(this.linea_credito.current, [
        Validators.required,
        Validators.min(this.linea_credito.min),
        Validators.max(this.linea_credito.max),
      ]),
      diaPago: new FormControl(undefined, [
        Validators.required
      ]),
      address: new FormControl(undefined, [
        Validators.required
      ]),
      checkboxCondicionesMembresia: new FormControl('', [
        Validators.requiredTrue
      ]),
      checkboxTerminos: new FormControl('', [
        Validators.requiredTrue
      ]),
      checkboxPolitica: new FormControl('', [
        Validators.requiredTrue
      ]),
      checkboxAutorizacion: new FormControl('', [
        Validators.requiredTrue
      ]),
      fechaSeleccionada: new FormControl(undefined),
      rangoHorarioSeleccionado: new FormControl(undefined),
      comprasIntExt: new FormControl(this.isCardless ? true : false),
      dispEfectivo: new FormControl(false),
      sobregiro: new FormControl(false),
    }

    this.addressForm = new FormGroup({
      departamento: new FormControl(undefined, [
        Validators.required
      ]),
      provincia: new FormControl(undefined, [
          Validators.required
      ]),
      distrito: new FormControl(undefined, [
          Validators.required
      ]),
      tipoDeVia: new FormControl(undefined, [
          Validators.required
      ]),
      nombreDeVia: new FormControl(undefined, [
          Validators.required,
          alphanumericExtended1(),
          Validators.maxLength(100)
      ]),
      numero: new FormControl(undefined, [
          Validators.required,
          numeric(),
          Validators.maxLength(6)
      ]),
      manzana: new FormControl(undefined, [
          Validators.required,
          alphanumericExtended(),
          Validators.maxLength(10)
      ]),
      lote: new FormControl(undefined, [
          Validators.required,
          numeric(),
          Validators.maxLength(10)
      ]),
      interior: new FormControl(undefined, [
          alphanumericExtended(),
          Validators.maxLength(10)
      ]),
    })

    const numeroControl = this.addressForm.get('numero') as FormControl;
    const manzanaControl = this.addressForm.get('manzana');
    const loteControl = this.addressForm.get('lote');

    numeroControl?.valueChanges.subscribe(value => {
      if (value) {
        manzanaControl?.clearValidators();
        manzanaControl?.setValidators([alphanumericExtended(), Validators.maxLength(10)]);
        loteControl?.clearValidators();
        loteControl?.setValidators([alphanumericExtended(), Validators.maxLength(10)]);
      } else {
        manzanaControl?.addValidators([Validators.required]);
        loteControl?.addValidators([Validators.required]);
      }
      manzanaControl?.updateValueAndValidity({ emitEvent: false });
      loteControl?.updateValueAndValidity({ emitEvent: false });
    });

    manzanaControl?.valueChanges.subscribe(value => {
      this.updateValidation(numeroControl, value);
    });

    loteControl?.valueChanges.subscribe(value => {
      this.updateValidation(numeroControl, value);
    });

    if (this.isFisico) {
      controls.codigoVendedor = new FormControl('', [
        Validators.required,
        alphanumeric(),
        Validators.maxLength(15)
      ])
    }

    this.configuracionForm = new FormGroup(controls)

    const lineaControl = this.configuracionForm?.get('linea')
    lineaControl
    ?.valueChanges
    .subscribe(value => {
      if (lineaControl.valid) {
        this.linea_credito.error = false
        this.linea_credito.current = value
      } else {
        const errors = lineaControl.errors
        if (errors === null) return;
        let error = '';
        if (errors['min']) {
          error = `a partir de S/${this.linea_credito.min}`;
        } else if (errors['max']) {
          error = `hasta S/${this.linea_credito.max}`;
        } else {
          error = `valido`;
        }

        this.linea_credito.error = `Debes ingresar un monto ${error} para continuar.`
      }
    })


    const addressControl = this.configuracionForm?.get('address')
    addressControl
    ?.valueChanges
    .subscribe(async value => {
      this.unreacheableAddress = false
      this.closeAddAddressSubform()
      this.loadingCoordinacionEntrega = true
      this.hasDateAndTime = true
      
      this.configuracionForm?.get('fechaSeleccionada')?.clearValidators()
      this.configuracionForm?.get('fechaSeleccionada')?.setValue(undefined)
      this.configuracionForm?.get('fechaSeleccionada')?.updateValueAndValidity()

      this.configuracionForm?.get('rangoHorarioSeleccionado')?.clearValidators()
      this.configuracionForm?.get('rangoHorarioSeleccionado')?.setValue(undefined)
      this.configuracionForm?.get('rangoHorarioSeleccionado')?.updateValueAndValidity()
      try {
        this.valorGeografico = value.id.geographicalLocation;
        this.coordinacionEntrega = await this.apiGet.getDeliveryDates(value.id.geographicalLocation)
        if (this.coordinacionEntrega.dates) {
          this.populateFechas()
          this.populateRangoHoras()
          
          this.configuracionForm?.get('fechaSeleccionada')?.addValidators(Validators.required)
          this.configuracionForm?.get('rangoHorarioSeleccionado')?.addValidators(Validators.required)
          
        } else {
          this.unreacheableAddress = true
        }
        this.loadingCoordinacionEntrega = false
      } catch (error) {
        this.hasDateAndTime = false
        if (this.intentos > 1) this.nav.toServiceError('ERROR_API_GET')
        this.intentos ++;
        this.loadingCoordinacionEntrega = false
      }
      
    })

    this.populateDiasPago()
    
    this.populateDirecciones()
    this.departamentos = await this.parametros.getDepartamentos()
  }

  updateValidation(control: FormControl, relatedValue: any) {
    if (relatedValue && this.addressForm?.get('manzana')?.value) {
      control.clearValidators();
      control.setValidators([numeric(), Validators.maxLength(6)]);
    } else {
      control.addValidators([Validators.required]);
    }
    control.updateValueAndValidity({ emitEvent: false });
  }

  async reloadDates() {
    try {
      this.unreacheableAddress = false
      this.loadingCoordinacionEntrega = true
      this.hasDateAndTime = true

      this.coordinacionEntrega = await this.apiGet.getDeliveryDates(this.valorGeografico)
      if (this.coordinacionEntrega.dates) {
        this.populateFechas()
        this.populateRangoHoras()
        
        this.configuracionForm?.get('fechaSeleccionada')?.addValidators(Validators.required)
        this.configuracionForm?.get('rangoHorarioSeleccionado')?.addValidators(Validators.required)

      } else {
        this.unreacheableAddress = true   
      }

      this.loadingCoordinacionEntrega = false

    } catch (error) {
      this.hasDateAndTime = false
      if (this.intentos > 1) this.nav.toServiceError('ERROR_API_GET')
      this.intentos ++;
      this.loadingCoordinacionEntrega = false
    }
    
  }
  
  private getAddressForm() {
    const form = this.addressForm
    if(!form) throw new Error('The form is not fialized')
    return form
  }

  private getAddressControl(controlName: string) {
    const form = this.getAddressForm()

    const control = form.get(controlName)
    if(!control) throw new Error('Control ' + controlName + ' not found')
    
    return control
  }

  private parseUbigeoId (id: string | number) {
    return padStart(id + '', 2, '0')
  }

  private resetControl(controlName: string) {
    const control = this.getAddressControl(controlName)
    control.setValue('')
    control.markAsPristine()
  }

  async handleChangeDepartamento() {
    const currentControl = this.getAddressControl('departamento')
    this.resetControl('provincia')
    this.resetControl('distrito')

    this.provinciasLoading = true
    this.provincias = await this.parametros.getProvincias(this.parseUbigeoId(currentControl.value.ubigeoDepartmend))
    this.provinciasLoading = false
  }
  async handleChangeProvincia() {
    
    const prevControl = this.getAddressControl('departamento')
    const currentControl = this.getAddressControl('provincia')
    this.resetControl('distrito')


    this.distritosLoading = true
    this.distritos = await this.parametros.getDistritos(this.parseUbigeoId(prevControl.value.ubigeoDepartmend), this.parseUbigeoId(currentControl.value.ubigeoProvince))
    this.distritosLoading = false
  }

  addressFormViewPortHeight = 0
  toggleAddAddressSubform () {
    if (this.addressFormViewPortHeight > 0) {
      this.closeAddAddressSubform()
      return;
    }
    this.addressFormViewPortHeight = this.addAddressSubform.nativeElement.clientHeight
  }

  private closeAddAddressSubform () {
    this.addressFormViewPortHeight = 0;
    this.addAddressSubform.nativeElement.reset()
    this.provincias = []
    this.distritos = []
  }

  
  handleAddAddress () {
    const form = this.getAddressForm()
    const values = form.value

    this.pepper.addAddress({
      department: values['departamento']['ubigeoName'].toUpperCase(),
      province: values['provincia']['ubigeoName'].toUpperCase(),
      district: values['distrito']['ubigeoName'].toUpperCase(),
      via: this.utils.removeSpecialCharacters(values['tipoDeVia'].toUpperCase()),
      address: this.utils.removeSpecialCharacters(values['nombreDeVia'].toUpperCase()),
      number: this.utils.parseNumberVia(values['numero']).toString(),
      ubigeo: values['distrito']['ubigeoCode'],
      mz: values['manzana'],
      lt: values['lote'],
      int: values['interior']
    })

    this.toggleAddAddressSubform()
    this.populateDirecciones()
  }
  
  executingAlta = false
  async handleSubmit () {
    if (!this.configuracionForm) throw new Error('Form not initialized')
    try {
      this.executingAlta = true

      if ( this.intentosAlta > 0 ) this.loadAlta = true

      const {
        comprasIntExt,
        dispEfectivo,
        sobregiro,
        checkboxTerminos,
        checkboxAutorizacion,
        address,
        fechaSeleccionada,
        rangoHorarioSeleccionado,
        linea,
        diaPago
      } = this.configuracionForm.value
      
      let codigoVendedor = ''
      if (this.isFisico) {
        const control = this.configuracionForm.get('codigoVendedor')
        if (!control) throw new Error('No codigoVendedor control found')

        codigoVendedor = control.value
      } else if (!this.isCardless) {
        codigoVendedor = 'VENTAONLINE'
      }

      let direccion = address.address

      const person = this.pepper.currentPerson()
      const aprovedOffer = this.pepper.getSelectedCard();
      const aprovedProposal = this.calificacion.proposal
      if (!person || !this.coordinacionEntrega) throw new Error('')
      const ubigeo = address.id.geographicalLocation
      const departmentCode = ubigeo.substring(0, 2)
      const provinceCode = ubigeo.substring(2, 4)
      const districtCode = ubigeo.substring(4, 6)
      
      let fechaNacimiento = ''

      const fnParts = person.birthdate.split('-')
      fechaNacimiento = `${fnParts[2]}/${fnParts[1]}/${fnParts[0]}`


      const ip = this.utils.ipLocal;

      const idExpediente = this.calificacion.expedienteId

      if (idExpediente === undefined) throw new Error('Expediente ID not found')

      let indicadorDevolucionCashback = '0'
      if (/^01(16|21|22)$/.test(aprovedOffer.cardCode)) {
        indicadorDevolucionCashback = '3'
      }

      let codigoConvenio = '14'
      if (this.isOnline || this.isFisico) {
        codigoConvenio = '20'
      }

      let _coordinacionEntrega = {
        horaFin: null as any,
        fechaEntrega: null as any,
        horaInicio: null as any,
      }

      if ( !this.unreacheableAddress ) {
        _coordinacionEntrega.horaFin = this.coordinacionEntrega.endTime.substring(0, 5),
        _coordinacionEntrega.fechaEntrega = fechaSeleccionada,
        _coordinacionEntrega.horaInicio = rangoHorarioSeleccionado.hour
        this.pepper.setIsOutOfZone(false)
      } else {
        this.pepper.setIsOutOfZone(true)
      }

      const payload: any = {
        comprasIntExt: (comprasIntExt as boolean),
        dispEfectivo: (dispEfectivo as boolean),
        sobregiro: (sobregiro as boolean),
        grabarLDPD: person.ldpd,

        codigoDepartamento: departmentCode,
        codigoDistrito: districtCode,
        direccion: direccion,
        codigoProvincia: provinceCode,
        email: this.pepper.obtenerCorreoPersonal(),
        numeroTelefono: person.phoneInformation[0].phoneNumber,

        ..._coordinacionEntrega,

        apellidoPaterno: person.lastName,
        segundoNombre: person.secondName ? person.secondName : '',
        nombreVia: address.id.standardizedPostalAddress.streetName,
        fechaNacimiento,
        nombreUrbanizacion: address.id.standardizedPostalAddress.urbanizationNumber,
        idManzana: address.id.standardizedPostalAddress.blockIdentification ? address.id.standardizedPostalAddress.blockIdentification : '',
        apellidoMaterno: person.secondLastName ? person.secondLastName : '',
        numeroCalle: address.id.standardizedPostalAddress.streetNumber,
        numeroDocumento: person.documentNumber,
        idInterior: address.id.standardizedPostalAddress.interiorNumber ? address.id.standardizedPostalAddress.interiorNumber : '',
        codigoUbigeo: address.id.geographicalLocation,
        primerNombre: person.firstName,
        tipoVia: this.getStreetName(address.id.standardizedPostalAddress.streetType.toUpperCase())+'|'+address.id.standardizedPostalAddress.streetType.toUpperCase(),
        departamento: address.id.deparmentCode,
        provincia: address.id.provinceCode,
        distrito: address.id.districtCode,
        sexo: person.genderType === 'M' ? 'MASCULINO' : 'FEMENINO',
        idLote: address.id.standardizedPostalAddress.lotNumber ? address.id.standardizedPostalAddress.lotNumber : '',

        idCampania: aprovedOffer.campaignId,

        importeLinea: linea,
        importeLineaPepper: linea,
        cardBrand: aprovedProposal.productType,
        cardType: aprovedProposal.subProduct,
        diaPago: diaPago,
        codigoVendedor,
        codigoMarca: aprovedOffer.marcaTarjeta,
        indicadorTipo: aprovedOffer.tipoTarjeta,
        indicadorTipoCliente: aprovedOffer.tipoCliente,
        ip,
        idExpediente,
        revolvingRateOffered: aprovedProposal.revolvingRateOffered,
        indicadorDevolucionCashback,
        codigoConvenio
      }
      
      if (person.customerId)
        payload.codigoUnico = person.customerId
      
      await this.alta.registerAlta(payload)
      
      const reportePayload: any = {
        req_status: 'Proceso',
        properties: {
          descripcionTarjeta: (this.card.info.nombreMarca + ' ' +  this.card.info.nombreTipo).toUpperCase(),
          pagina: 'Configuracion',
          comprasIntExt: (comprasIntExt as boolean) ? 'On' : 'Off',
          dispEfectivo: (dispEfectivo as boolean) ? 'On' : 'Off',
          sobregiro: (sobregiro as boolean) ? 'On' : 'Off',
          terminosCondiciones: (checkboxTerminos as boolean) ? 'Si' : 'No',
          // autorizacionDatos:  (checkboxAutorizacion as boolean) ? 'Si' : 'No',
          flagVenta: "SI",
          device: this.utils.detectBrowser()
        },
        flag_membership: 'SI',
        flag_accepttc: 'SI',
        ammount_approved: linea
      }

      if (this.isFisico) {
        reportePayload.properties.code_seller = codigoVendedor
      }
      
      await this.reporte.addToReport(reportePayload)
      this.errorAlta = false
      if (this.isFisico) {
        this.nav.to('tarjeta/lista')
      } else {
        this.nav.to('tarjeta/datos')
      }
    } catch (error) {
      this.executingAlta = false
      const err = error as HttpErrorResponse
      if ( err.status && err.status !== 403){
        const data = {
          parametros: [
            {
              clave: 'DETALLEERROR',
              valor: 'ERROR AltaTC - '.concat(err.status ? err.status.toString() : 'interno' ,' - ',err.error ? err.error['errorDetail'] ? err.error['errorDetail']: '' : '')
            }
          ]
        }
        await this.qradar.addToRegister('altaTc', data);
      }
      if ( err.status === 403 && err?.error?.errorMessage === 'E3005') this.nav.toServiceError('ERROR_TARJETA_DUPLICADA')
      this.errorAlta = true

      if(this.intentosAlta > 1 ) this.nav.toServiceError('ERROR_API_ALTA')
      this.intentosAlta ++
      
      this.loadAlta = false
    }
  }

  
  membresiaModal = false
  openMembresiaModal (e: Event) {
    e.preventDefault()
    this.membresiaModal = true
  }
  handleMembresiaCloseModal () {
    this.membresiaModal = false
  }
  get contentMembresiaModal () {
    return this.parametros.config['AceptarMembresia']
  }

  tycModal = false
  openTycModal (e: Event) {
    e.preventDefault()
    this.tycModal = true
  }
  handleTycCloseModal () {
    this.tycModal = false
  }
  get contentTycModal () {
    return this.parametros.config['TerminosyCondiciones']
  }
  
  politicaModal = false
  openPoliticaModal (e: Event) {
    e.preventDefault()
    this.politicaModal = true
  }
  handlePoliticaCloseModal () {
    this.politicaModal = false
  }
  get contentPoliticaModal () {
    return this.parametros.config['PoliticaPrivacidad']
  }

  getStreetName(abbreviation: string) {
    let via = VIAS.find( element => element.id === abbreviation);
    return via?.name.toUpperCase();
  }

}
