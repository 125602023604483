import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'tc-timer',
  templateUrl: './tc-timer.component.html',
  styleUrls: ['./tc-timer.component.scss'],
  inputs: [
    'seconds'
  ]
})
export class TcTimerComponent implements OnInit {
  @Input('seconds') seconds: number = 0;
  @Input() autostart: boolean = true;
  @Input() startObservable: Observable<void> | undefined

  @Output('end') endEvent = new EventEmitter<void>();


  private eventsSubscription?: Subscription;


  public currentSeconds: number = 0;
  public initialized: boolean = false
  private interval: any = null;

  constructor() { }

  ngOnInit ():void {
    this.currentSeconds = this.seconds * 1000;

    if (typeof this.startObservable !== 'undefined') {
      this.eventsSubscription = this.startObservable.subscribe(() => this.startCountdown());
    }

    if (this.autostart) {
      this.startCountdown()
    }
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
  }
  
  private startCountdown () {
    this.currentSeconds = this.seconds * 1000;
    this.interval = setInterval(() => { this.handleInterval() }, 1000)
  }

  private handleInterval () {
    this.currentSeconds -= 1000;
    if (this.currentSeconds === 0) {
      this.stopTimer()
    }
  }

  private stopTimer () {
    clearInterval(this.interval)
    this.endEvent.emit()
  }

}
