import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IpInfoResponse } from './response/IpInfoResponse';


@Injectable({
  providedIn: 'root'
})
export class IpInfoService {

  private url = `https://pro.ip-api.com/json/?fields=status,message,continent,continentCode,country,countryCode,countryCode3,
region,regionName,city,district,zip,lat,lon,timezone,offset,currentTime,currency,callingCode,isp,org,as,asname,reverse,mobile,
proxy,hosting,query&key=BwfSJxuANSbGW0B`;

  constructor(private _httpClient: HttpClient) { }


  getIpInfo(): Observable<IpInfoResponse> {
    return this._httpClient.get<IpInfoResponse>(this.url);
  }

}