import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-error-servicio',
  templateUrl: './error-servicio.component.html',
  styleUrls: ['./error-servicio.component.scss']
})
export class ErrorServicioComponent implements OnInit {

  private data;

  get title (): string {
    if(typeof this.data['title'] !== 'string') return ''
    return this.data['title']
  }

  get description (): string {
    if(typeof this.data['description'] !== 'string') return ''
    return this.data['description']
  }

  get reloadTextButton () {
    return this.data['reloadTextButton']
  }

  constructor(
    private navigate: NavigateService,
    private reporte: ReportesService,
    private utils: UtilsService
  ) {
    this.data = this.navigate.getCurrentRouteData()
  }

  async ngOnInit(): Promise<void> {
    if (!this.data['codigo_error']) return;

    if(this.data['hasReport']) {
      await this.reporte.addToReport({
        properties: {
          pagina: this.data['codigo_error'],
          device: this.utils.detectBrowser()
        }
      })
    }
  }

  reloadApp(): void {
    window.location.reload()
  }

}
