import { Component, ViewChild, Renderer2, AfterViewInit, Input  } from '@angular/core';
import { PepperService } from 'src/app/services/pepper/pepper.service';

@Component({
  selector: 'sticky-notice-bar',
  templateUrl: './sticky-notice-bar.component.html',
  styleUrls: ['./sticky-notice-bar.component.scss']
})
export class StickyNoticeBarComponent implements AfterViewInit {

  @ViewChild('bar') stickyBar: any;
  @ViewChild('hook') hook: any;

  @Input() message: string = '';

  constructor(
    private renderer: Renderer2,
    private pepper: PepperService
  ) { }

  get txtBenefit () {
    return this.pepper.getContentData()?.partner_principal_benefit['value'];
  }

  ngAfterViewInit() {

    var intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) {
        this.renderer.setStyle(this.stickyBar.nativeElement, 'height', this.stickyBar.nativeElement.offsetHeight + 'px');
        this.renderer.addClass(this.stickyBar.nativeElement, 'sticky')
        return;
      }
      
      this.renderer.removeStyle(this.stickyBar.nativeElement, 'height');
      this.renderer.removeClass(this.stickyBar.nativeElement, 'sticky')
    });

    intersectionObserver.observe(this.hook.nativeElement);

  }
 
  ngOnDestroy() {
  }

}
