import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InternalService } from 'src/app/common/InternalService';
import { environment } from 'src/environments/environment';
import { ParametrosService } from '../parametros/parametros.service';
import { ValidateCaptchaResponse } from './responses/ValidateCaptchaResponse';
import { PepperService } from '../pepper/pepper.service';
import { BiometriaOauthTokenResponse } from './responses/BiometriaOauthTokenResponse';
import { Customer } from '../biometria/responses/Customer';
import { TokenValidacionResponse } from './responses/TokenValidacionResponse';

@Injectable({
  providedIn: 'root'
})
export class ApiPepperBackendService extends InternalService {

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient,
    private pepper: PepperService
  ) {
    super(parametros, http, environment.services.pepperBackend);
  }

  public async getCardsInfo(cardCodes: Array<string>) {
    try {
      const extraHeaders = {} as Record<string, string>

      extraHeaders[environment.subscriptionApiHeader.key] = environment.subscriptionApiHeader.value
      const response = this.doRequestToEndpoint<Record<string, any>>('chCards',  {
        params: {
          cardCodes
        },
        extraHeaders
      })
      return response
    } catch (error) {
      throw new Error('Error calling CH Cards Info');
    }
  }

  public async validateRecaptcha(token: string): Promise<Record<'success', boolean>> {
    try {
      const extraHeaders = {} as Record<string, string>

      extraHeaders[environment.subscriptionApiHeader.key] = environment.subscriptionApiHeader.value

      const response = await this.doRequestToEndpoint<ValidateCaptchaResponse>('validateRecaptcha', {
        params: {
          captcha: token
        },
        extraHeaders
      })
      
      if (response.Validacion === 'exitosa') {
        return {
          success: true
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      return {
        success: false
      }
    }
  }

  public async generateBiometriaOauthTokens(): Promise<BiometriaOauthTokenResponse | null> {
    const clientFormData = this.pepper.getClientFormData()
    if (!clientFormData) return null;

    const person = this.pepper.currentPerson()
    if(person === null) return null;

    const response = await this.doRequestToEndpoint<BiometriaOauthTokenResponse>('biometriaOauth', {
      params: {
        documentType: clientFormData['documentType'],
        documentNumber: clientFormData['documentNumber'],
        identityFirstName: person.firstName,
        identityLastName: person.lastName,
      }
    })
    
    return response
  }

  public async verifyBiometriaTransactionId(transactionId: string): Promise<any | null> {
    const response = await this.doRequestToEndpoint<any>('biometriaVerify', {
      params: {
        transactionId: transactionId
      }
    })
    
    return response
  }

  public async biometriaIntentosFallidos () : Promise<Customer | null> {
    const clientFormData = this.pepper.getClientFormData()
    if (!clientFormData) return null;
    const response = await this.doRequestToEndpoint<Customer>('biometriaIntentosFallidos', {
      params: {
        identityDocumentType: "DNI",
        identityDocumentNumber: clientFormData['documentNumber'],
        identityCompany: "INTERBANK",
        identityChannel: "PEP"
      }
    })
    
    return response
  }

  public async getTokenValidacion () : Promise<TokenValidacionResponse> {
    try {
      const response = await this.doRequestToEndpoint<any>('tokenValidacion');

      return response;
    } catch (error) {
      throw new Error('Error calling session token Info');
    }
    
  }

  public async getTokenCuentaSimple () : Promise<TokenValidacionResponse> {
    try {
      const response = await this.doRequestToEndpoint<any>('tokenCuentaSimple');

      return response;
    } catch (error) {
      throw new Error('Error calling session token Info');
    }
    
  }
}
