import { Component, HostListener, OnInit } from "@angular/core";
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiOfertasService } from "src/app/services/api-ofertas/api-ofertas.service";
import { OfertasRequest } from "src/app/services/api-ofertas/requests/OfertasRequest";
import { NavigateService } from "src/app/services/navigate/navigate.service";
import { ParametrosService } from "src/app/services/parametros/parametros.service";
import { PepperService } from "src/app/services/pepper/pepper.service";
import { UtilsService } from "src/app/services/utils/utils.service";
import { DocumentTypes, documentValidator } from "src/utils/formControlValidators";
import moment from 'moment';
import { ReportesService } from "src/app/services/reportes/reportes.service";

@Component({
  selector: 'app-login-debito',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  estiloVarita: any = {
    top: '0px',
    right: '0px'
  }

  loading: boolean = false;
  isMemoria = false;
  politicaDataModal: boolean = false;
  clientForm: FormGroup | undefined

  clientInitialValue: any = {
    dni: '',
    politicaPrivacidad: false
  }

  constructor(
    private multiOfertas: ApiOfertasService,
    private pepperService: PepperService,
    private parametros: ParametrosService,
    private utils: UtilsService,
    private reportes: ReportesService,
    private navigate: NavigateService) { }

  ngOnInit(): void {
    this.validarCodigoEnMemoria();
    this.inicializarFormulario();
    this.calcularEstiloVarita();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.calcularEstiloVarita();
  }

  calcularEstiloVarita() {
    const ancho = window.innerWidth;
    const largo = window.innerHeight;

    const baseWidth = (ancho < 768) ? 390 : (ancho >= 768 && ancho < 1280) ? 768 : 1280;
    const baseRight = (ancho < 768) ? 230 : (ancho >= 768 && ancho < 1280) ? -25 : -20;
    const baseTop = (ancho < 768) ? 131 : (ancho >= 768 && ancho < 1280) ? 85 : 40;

    let ajusteRight = baseRight;
    if (ancho > baseWidth) ajusteRight += (ancho - baseWidth) / 2

    this.estiloVarita = { top: baseTop + 'px', right: ajusteRight + 'px' }
  }



  inicializarFormulario() {
    this.clientForm = new UntypedFormGroup({
      dni: new UntypedFormControl(this.clientInitialValue.dni, [
        Validators.required,
        documentValidator(DocumentTypes.DNI)
      ]),
      codigoValidacion: new UntypedFormControl(this.clientInitialValue.dni, 
        (this.pepperService.validateCampaign() && !this.isMemoria) ? [ Validators.required ]: []),
      politicaPrivacidad: new UntypedFormControl(this.clientInitialValue.politicaPrivacidad, [
        Validators.requiredTrue
      ])
    });
  }

  async handleSubmit() {
    this.loading = true;
    const { dni, codigoValidacion, politicaPrivacidad } = this.clientForm?.value;
    try {
      this.pepperService.setDniPepperDebito(dni);
      const requestOfertas: OfertasRequest = {
        documentNumber: dni,
        documentType: '01'
      }
      
      if (this.isToValidateClient && !this.isMemoria) { this.validarCodigo(codigoValidacion); } 

      if ( this.clientForm?.valid) {
        await this.multiOfertas.multiOfertas(requestOfertas);
        await this.generarReporte(dni);
        this.navigate.to('debito/tipo-tarjeta')
      }
      
    } catch (error) {
      await this.generarReporte(dni);
      this.navigate.toServiceError('ERROR_API_MULTI_OFERTAS');
    } finally {
      this.loading = false
    }
  }

  validarCodigo(codigoValidacion: string) {
    if (this.parametros.config['CODIGO_VALIDACION'] !== codigoValidacion){
      this.clientForm?.get('codigoValidacion')?.setErrors({codigoInvalido: false})
    } else {
      sessionStorage.setItem('codigoValidacion', codigoValidacion);
    }
  }

  openPoliticaDataModal(e: Event) {
    e.preventDefault()
    this.politicaDataModal = true
  }

  handlePoliticaDataCloseModal() {
    this.politicaDataModal = false
  }

   async generarReporte(dni: string) {

    const pepper_type = this.pepperService.getSelectedFlow()
    const m = moment()
    const req = {
      message_id: this.parametros.getSessionId(),
      full_name: '-',
      document_type: "1",
      document_number: dni,
      req_type: pepper_type,
      store_name: pepper_type,
      code_seller: this.pepperService.getSelectedStore(),
      record_file_evaluation: null,
      establishment: this.pepperService.getSelectedStore(),
      cellphone: '-',
      cell_operator: '-',
      created_at: m.format('YYYY/MM/DD'),
      updated_at: m.format('YYYY/MM/DD'),
      req_status: "Proceso",
      email: '-',
      commerce_type: pepper_type?.toUpperCase()[0] === 'F' ? 'Físico' : 'Online',
      currency: "PEN",
      ammount_approved: 0,
      account_number: null,
      reason: "P",
      ip_address: this.utils.ipLocal,
      properties: {
        fecha: m.format('YYYY-MM-DD HH:mm:ss'), // Fecha: DDMMYYY, lee del sistema
        create: m.format('DD/MM/YYYY HH:mm:ss'),
        updated: m.format('DD/MM/YYYY HH:mm:ss'),
        dia: m.format('DD'), // Día: número del día, y lo lee del sistema
        mes: m.format('MMMM'), // Mes: número del mes, y lo lee del sistema
        semana: 'SEMANA ' + m.week(), // Semana: número de semana
        tipoFlujo: this.pepperService.getSelectedFlow(), // Flujo: texto ""Venta""
        pagina: 'Login-debito', // Pagina: rpta LogIn
        device: this.utils.detectBrowser()
      }
    } as any

    await this.reportes.addToReport(req)
  }

  get contentPoliticaModal () {
    return this.parametros.config['PoliticaPrivacidad']
  }

  get isToValidateClient() {
    return this.pepperService.validateCampaign()
  }

  validarCodigoEnMemoria(){
    const codigoMemoria = sessionStorage.getItem('codigoValidacion');
    if (codigoMemoria && codigoMemoria === this.parametros.config['CODIGO_VALIDACION'] ){
      this.isMemoria = true
    }else {
      this.isMemoria = false
    }
  }

}