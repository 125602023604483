<section *ngIf="loading" class="loader full-width">
    <img src="../../../../assets/preloader.svg" class="center-block">
</section>

<div class="tc-lista-screen" *ngIf="!showCard && !loading">
    <div class="container">
        <img src="../../../assets/icon/tc-exito.svg" alt="Éxito" class="center-block">
        <h3 class="heading-3 color-blue weight-medium">¡Tu tarjeta está lista!</h3>
        <p class="text-1">Disfruta de <strong> tu cupón de descuento </strong> en tu primera compra con tu nueva tarjeta Interbank en <strong>{{ partnerName }}.</strong></p>
        <p class="text-1">Para realizar tu compra, comienza por generar los datos de tu nueva tarjeta.</p>
        <button (click)="showModal()" class="btn fw--mobile center-block mt-100 mb-125">Genera tu tarjeta digital</button>
        <div class="promotion-ticket-fancy center-block" *ngIf="coupon">
            <p class="title text-2">Usa tu cupón de descuento:</p>
            <p class="coupon text-1 weight-medium2">{{coupon}}</p>
        </div>
        <p class="text-1"></p>
        <hr class="separator">
        <p class="text-2 text-center" *ngIf="emailAlternativo">Además, hemos enviado a todos tus correos registrados la constancia de tu solicitud.<br>En los próximos dias te contactaremos para la entrega de tu tarjeta física.</p>
        <p class="text-2 text-center" *ngIf="!emailAlternativo">Además, te hemos enviado un correo con la constancia de tu solicitud.<br>En los próximos dias te contactaremos para la entrega de tu tarjeta física.</p>
        <div class="notice info mt-125" *ngIf="outOfZone">Tu dirección no se encuentra en nuestro rango de cobertura para la entrega de tu tarjeta de crédito. No te preocupes, nosotros nos comunicaremos contigo para coordinar la entrega.</div>
    </div>
</div>
<!-- VISUALIZACION DE TARJETA ANTIGUO  -->
<div class="datos-tarjeta-screen" *ngIf="showCard && !loading && !hasVisualizacion">
    <div class="container">
        <h4 class="title weight-medium text-center">Esta es tu tarjeta digital</h4>
        <p *ngIf="isOnline" class="description text-center">Recuerda que solo es válida para <span class="weight-medium">un único uso</span> y tiene una <span class="weight-medium">línea de crédito de S/ {{line | mask: 'separator.2'}}.</span></p>
        <p *ngIf="isCardless" class="description text-center">Recuerda que puedes hacer un uso máximo de <span class="weight-medium">S/ {{line | mask: 'separator.2'}} de línea de crédito.</span></p>
        <p class="expiry-label text-center">Los datos de tu tarjeta expiran en:</p>
        <div class="timer"> 
            <div *ngIf="!timeout; else timeoutBlock">
                <tc-timer [seconds]="countdownSeconds" [autostart]="true" [startObservable]="startTimerSubject" (end)="handleTimeout()"></tc-timer>
            </div>
            <ng-template #timeoutBlock>
                <div class="timeout-indicator">
                    <img src="../../../assets/icon/timer-done.svg">
                    <span>El tiempo ha terminado</span>
                </div>
            </ng-template>
        </div>
        <div class="virtualcard">
            <tc-front [type]="type" [name]="name" [card]="card" [month]="month" [year]="year" [csc]="csc" [obfuscated]="timeout"></tc-front>
            <tc-back [cvv]="cvv" [obfuscated]="timeout"></tc-back>
        </div>
        <div *ngIf="coupon" class="promotion-ticket center-block mt-100">
            <p class="title text-3">Recuerda usar tu cupón de descuento:</p>
            <p class="coupon text-2 weight-medium2">{{ coupon }}</p>
        </div>
    </div>
</div>

<!-- MODAL DE VISUALIZACION DE DATOS DE TARJETA -->
<div *ngIf="hasVisualizacion" #viewPepper ></div>

<pepper-modal class="modal-type" [active]="type_modal_active" (close)="handleTypeOnboardingCloseModal()" (overlay)="handleTypeOnboardingCloseModal()">
    <div class="container">
        <h4 class="heading-4 weight-medium">Ahora, te mostraremos un poco más sobre tu nueva tarjeta</h4>
        <div class="card-representation">
            <img class="card-face" src="../../../assets/icon/virtualcard/tarjeta-frente.svg">
            <img class="card-face" src="../../../assets/icon/virtualcard/tarjeta-reverso.svg">
            <img class="card-complete center-block" src="../../../assets/icon/virtualcard/tarjeta-completa.svg">
        </div>
        <button (click)="handleTypeOnboardingCloseModal()" class="btn fw--mobile center-block visualizar">Ver datos de mi tarjeta</button>
        <h5 class="heading-4 weight-medium">No olvides que..</h5>
        <ul class="list">
            <li><strong>Deberás copiar todos los datos de tu tarjeta</strong>. Los necesitarás para realizar tu compra.</li>
            <li>Tu tarjeta digital solo tendrá habilitada <strong>una parte de tu línea de crédito.</strong></li>
            <li>La información y detalle de la categoría de tu tarjeta la <strong>puedes encontrar en tu correo.</strong></li>
        </ul>
    </div>
</pepper-modal>

<pepper-modal class="modal-card" [active]="card_modal_active" (close)="handleCardOnboardingCloseModal()" (overlay)="handleCardOnboardingCloseModal()">
    <div class="container">
        <img class="center-block" src="../../../assets/icon/advisegreen.svg">
        <h4 class="heading-4 weight-medium color-blue">Antes de generar tu tarjeta digital, considera que:</h4>
        <ul class="list" *ngIf="currentFlow === 'online'">
            <li>Tu tarjeta digital no reemplazará a tu tarjeta física y solo será de <strong>un único uso.</strong></li>
            <li>Por tu seguridad los datos de esta tarjeta solo estarán <strong>visibles por 10 minutos.</strong></li>
            <li><strong>No compartas esta información</strong> con otras personas y evita tomar captura de tu pantalla.</li>
            <li>Para tarjetas American Express (Amex) se generarán <strong>2 códigos de seguridad (CVV y CSC).</strong></li>
        </ul>
        <ul class="list" *ngIf="currentFlow === 'cardless'">
            <li>Tu tarjeta digital y tu tarjeta física compartirán los mismos datos.</li>
            <li>Por tu seguridad los datos de esta tarjeta solo estarán <strong>visibles por 3 minutos.</strong></li>
            <li><strong>No compartas esta información</strong> con otras personas y evita tomar captura de tu pantalla.</li>
            <li>Para tarjetas American Express (Amex) se generarán <strong>2 códigos de seguridad (CVV y CSC).</strong></li>
            <li>Recuerda que <strong>si desactivaste tus compras en internet y uso en el exterior</strong> no podras realizar compras con tu tarjeta digital.</li>
        </ul>
        <button (click)="handleCardOnboardingCloseModal()" class="btn fw--mobile center-block">Continuar</button>
    </div>
</pepper-modal>