import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import { ParametrosService } from './services/parametros/parametros.service';
import { PepperService } from './services/pepper/pepper.service';
import { environment } from 'src/environments/environment';
import { UtilsService } from './services/utils/utils.service';

declare let fbq: Function;

interface PageData {
  event: string;
  page: {
    name: string;
    section: string;
  };
  site: {
    name: string;
    environment: string;
  };
  user?: {
    ID: string;
    IDType: string;
  };
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pepper-frontend';

  constructor(
    private navigate: NavigateService,
    private pepper: PepperService,
    private parametros: ParametrosService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private util: UtilsService
  ) { }

  async ngOnInit() {
    this.googleAnalytics();
    this.adobeLaunch();
    this.navigate.to('/loading')

    try {
      await Promise.all([
        this.pepper.init(),
        this.parametros.init()
      ])

      if (this.pepper.getSelectedExperience()) {
        this.navigate.to('debito/login')
        return;
      }

      if (this.pepper.getSelectedFlow() === 'recompra') {
        this.navigate.to('/nuevo-codigo')
      } else {
        this.navigate.to('/home')
      }

    } catch (e) {
      this.navigate.toServiceError('ERROR_CARGA_INICIAL', false)
    }

  }

  googleAnalytics() {
    var digitalData = window.digitalData || [];
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        if (item.url !== '/loading') {
          const cfData = this.pepper.getClientFormData();
          const pageData: PageData = {
            "event": "page data",
            "page": {
              "name": 'pepper:' + item.url.slice(1),
              "section": "pepper:ventatc",
            },
            "site": {
              "name": "pepper",
              "environment": environment.mode,
            }
          };

          switch (item.url) {
            case '/debito/login':
              digitalData.push(pageData);
              break;
            case '/debito/tipo-tarjeta':
              pageData.user = {
                "ID": this.util.encryptDocument(this.pepper.getDniPepperDebito()!),
                "IDType": "01"
              };
              digitalData.push(pageData);
              break;

            case '/home':
              digitalData.push(pageData);
              break;

            case '/usuario/identidad/datos-personales':
              if (!this.pepper.currentPerson().customerId) {
                pageData.user = {
                  "ID": this.util.encryptDocument(cfData!['documentNumber']),
                  "IDType": "01"
                };
                digitalData.push(pageData);
              }
              break;

            default:
              pageData.user = {
                "ID": this.util.encryptDocument(cfData!['documentNumber']),
                "IDType": "01"
              };
              digitalData.push(pageData);
              break;
          }
        }


        const gtmTag = {
          event: 'VirtualPageView',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
        fbq('track', 'PageView');
        if (item.url === '/tarjeta/datos') {
          fbq('track', 'Contact');
          fbq('track', 'Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
        }
      }
    });
  }

  adobeLaunch() {
    var digitalData = window.digitalData || [];
    digitalData.push({
      "event": "page init"
    });
  }
}
