<div class="step-indicator">
    <p class="title">{{ title }}</p>
    <ul id="stepper">
        <li [class.current]="current === 1" [class.done]="current > 1">1</li>
        <li [class.current]="current === 2" [class.done]="current > 2">2</li>
        <li [class.current]="current === 3" [class.done]="current > 3">3</li>
        <li [class.current]="current === 4" [class.done]="current > 4">4</li>
        <li [class.current]="current === 5" [class.done]="current > 5">5</li>
        <li *ngIf="extraStep" [class.current]="current === 6" [class.done]="current > 6">6</li>
    </ul>
</div>