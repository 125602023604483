import { Component, OnInit } from '@angular/core';
import { NavigateService } from 'src/app/services/navigate/navigate.service';

@Component({
  selector: 'app-biometria-success',
  templateUrl: './biometria-success.component.html',
  styleUrls: ['./biometria-success.component.scss']
})
export class BiometriaSuccessComponent implements OnInit {

  constructor(
    private nav: NavigateService
  ) { }

  ngOnInit(): void {
  }

  continue () {
    this.nav.to('usuario/configurar-tc')
  }

}
