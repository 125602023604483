<section *ngIf="tarjetaDebitoActiva || tarjetaCreditoActiva" class="page-show-card">
  <div class="container">
    <h3 class="heading-3 title" [innerHTML]="titulo | safe:'html'"></h3>
    <p *ngIf="aprobados" class="text-2 sub-title text-center--mobile text-center--tablet text-center--desktop">Elige la
      mejor para ti</p>
    <div *ngIf="aprobados" class="botones">
      <button type="button" (click)="btnCredito()" class="btn btn-opcion fw--mobile"
        [ngClass]="{'btn-activo': tarjetaCreditoActiva}"> Tarjeta de
        Crédito</button>
      <button type="button" (click)="btnDebito()" class="btn btn-opcion fw--mobile"
        [ngClass]="{'btn-activo': tarjetaDebitoActiva}"> Tarjeta de
        Débito</button>
    </div>
    <section class="show-card">
      <div [hidden]="!tarjetaCreditoActiva" class="logo logo-credito"></div>
      <div [hidden]="!tarjetaDebitoActiva" class="logo logo-debito"></div>
      <div class="descripcion">
        <h2 class="heading-3 text-left--tablet text-left--desktop">
          <strong>{{ tarjetaCreditoActiva ? 'Tarjeta de Crédito' : 'Tarjeta de Débito Digital' }}</strong>
        </h2>
        <p class="text-1 text-left--tablet text-left--desktop">Obtenla y disfruta de</p>
        <div class="descuento">
          <p class="text-2">{{ contentDescuento }}*</p>
        </div>
        <p class="text-3 text-left--tablet text-left--desktop">* {{ contentRequerimiento }}</p>
        <p class="text-3 terminos text-left--tablet text-left--desktop">
          <a class="color-green no-text-decoration" (click)="openTycDataModal($event)">Términos y Condiciones</a>
        </p>
        <button *ngIf="tarjetaCreditoActiva" type="button" class="btn fw--mobile" (click)="obtenerTarjetaCredito()">¡La quiero!</button>
        <ibk-account *ngIf="tarjetaDebitoActiva && !loader" 
          [jwt]="jwt" 
          [credential]="credential"
          [commerce-id]="commerceId" 
          [businessName]="businessName"
          [subscriptionKey]="subscriptionKey"  id="account-wc" custom-btn>
          <button type="button" class="btn btn-debito fw--mobile" >¡La quiero!</button>
        </ibk-account>
        
      </div>

    </section>
    <section class="show-beneficios">
      <div class="opciones">
        <h3 class="heading-3"><strong>Lo que debes saber</strong></h3>
        <div class="beneficios-option">
          <button type="button" (click)="setActiveTab('caracteristicas')" class="btn btn-beneficio"
            [ngClass]="{'btn-beneficio-activo': activeTab === 'caracteristicas'}">Características</button>
          <button type="button" (click)="setActiveTab('tarifas')" class="btn btn-beneficio"
            [ngClass]="{'btn-beneficio-activo': activeTab === 'tarifas'}">Tasas y tarifas</button>
          <button type="button" (click)="setActiveTab('preguntas')" class="btn btn-beneficio"
            [ngClass]="{'btn-beneficio-activo': activeTab === 'preguntas'}">Preguntas</button>
        </div>
        <div [hidden]="activeTab !== 'caracteristicas'" class="characteristics">
          <div *ngIf="tarjetaCreditoActiva" class="linea">
            <div class="linea-card">
              <div class="linea-text">
                <p class="text-center--tablet text-center--desktop"><strong>Hasta S/ {{ amout | mask: 'separator' }}&nbsp;</strong></p>
                <p class="text-center--tablet text-center--desktop">de línea de crédito preaprobada</p>
              </div>
            </div>
          </div>
          <div class="characteristics-card__wrapper">
            <div *ngFor="let caracteristica of tarjetaCreditoActiva ? caracteristicasCredito : caracteristicasDebito" class="characteristics-slider">
              <div class="keen-slider__slide">
                <div class="icon">
                  <img [src]="caracteristica.icono">
                </div>
                <div class="text">
                  <strong class="text-left--mobile text-left--tablet text-left--desktop">{{ caracteristica.titulo }}</strong>
                  <p class="text-left--mobile text-left--tablet text-left--desktop" [innerHTML]="caracteristica.descripcion | safe:'html'"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div [hidden]="activeTab !== 'tarifas'" class="rates-fees">
          <div class="characteristics-card__wrapper">
            <div *ngFor="let tarifa of tarjetaCreditoActiva ? tarifasCredito : tarifasDebito" class="rates-fees-card">
              <div class="card-body">
                <div class="title-card">
                  <img class="bullet" src="../../../../assets/icon/elipse.svg" alt="elipse">
                  <p class="text-2"><strong>{{ tarifa.titulo}}</strong></p>
                </div>
                <div class="description-card">
                  <p class="text-2" [innerHTML]="tarifa.descripcion | safe:'html'"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div [hidden]="activeTab !== 'preguntas'" class="question">
          <div class="characteristics-card__wrapper">
            <div *ngFor="let pregunta of preguntasCredito; let i = index" class="question-card">
              <div class="card-body-question">
                <div class="body-question">
                  <div class="title-question">
                    <p class="text-2"><strong>{{ tarjetaCreditoActiva ? pregunta.titulo : pregunta.tituloDebito ?  pregunta.tituloDebito : pregunta.titulo}}</strong></p>
                  </div>
                  <div class="answer-question" *ngIf="pregunta.visible">
                    <p class="text-2">{{ tarjetaCreditoActiva ? pregunta.respuesta : pregunta.respuestaDebito ? pregunta.respuestaDebito : pregunta.respuesta}}</p>
                  </div>
                </div>
                <div class="boton-img" (click)="toggleVisibility(i)">
                  <img [ngClass]="{'close-btn': !pregunta.visible}" src="../../../../assets/icon/chevron-down-green.svg" alt="desplegar">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  </div>
</section>

<section *ngIf="tarjetaDebitoActiva || tarjetaCreditoActiva" class="text-legales">
  <small>{{ contentLegales }}</small>
</section>

<section *ngIf="!tarjetaDebitoActiva && !tarjetaCreditoActiva" class="page-error">
  <div class="calificacion">
    <img class="logo-error" src="../../../../assets/icon/debito/disculpas.svg" alt="Error">
    <h1 class="heading-4">
      <strong>Lo sentimos</strong>
    </h1>
    <p class="text-1 descripcion-error">
      Evaluamos tu información y por ahora no tenemos un producto disponible para ti.
    </p>
    <button class="btn center-block fw--mobile" (click)="reloadApp()">Entendido</button>
  </div>
</section>

<pepper-modal class="modal-type" [active]="tycDataModal" (close)="handleTycDataCloseModal()" (overlay)="handleTycDataCloseModal()" [actionButton]="true" actionButtonLabel="Entiendo" (actionButton)="handleTycDataCloseModal()">
  <div class="container page-modal" [innerHTML]="contentTerminosyCondiciones | safe:'html'"></div>
</pepper-modal>