<section class="screen-login-debito">
  <div class="container">
    <div class="contenedor-blanco">
      <div class="formulario">
        <form *ngIf="clientForm !== undefined" class="form" [formGroup]="clientForm" (ngSubmit)="handleSubmit()">
          <h1 class="heading-2 text-center--mobile"><strong>Tenemos el producto ideal para ti</strong></h1>
          <p class="text-2 text-center--mobile mt-100 ocultar">Descubre si tienes una tarjeta de crédito o de Débito Digital
            aprobada.</p>
          <label class="identidad">
            <span>Ingresa tu DNI</span>
            <input class="input" type="text" mask="00000000" formControlName="dni" id="input_dni" [readOnly]="loading">
          </label>
          <label *ngIf="isToValidateClient && !isMemoria" class="identidad">
            <span>Ingresa código de validación</span>
            <input class="input" type="text" formControlName="codigoValidacion" id="input_codigo_validacion" [readOnly]="loading">
            <span class="error-msj">{{clientForm.controls['codigoValidacion'].errors | formControlErrorMessage:'codigo'}}</span>
          </label>
          <button id="submit_btn" type="submit" class="btn fw--mobile" [disabled]="loading || clientForm.invalid">{{ loading ? 'Cargando ...' : 'Vamos' }}</button>
          <div class="row">
            <label class="checkbox checkbox-label">
              <input type="checkbox" formControlName="politicaPrivacidad" id="input_autorizacion">
              <span class="checkbox"></span>
              <p class="text-3"><strong>He leído y acepto la <a (click)="openPoliticaDataModal($event)"
                    class="color-green weight-medium2" href="#">Política de privacidad</a></strong></p>
            </label>
          </div>
        </form>
      </div>
      <div class="imagen">
        <img src="../../../../assets/icon/debito/celular.svg" alt="Celular_logo">
      </div>
      <div class="varita" [ngStyle]="estiloVarita">
        <img src="../../../../assets/icon/debito/varita.svg" alt="varita_logo">
      </div>
    </div>
  </div>
</section>

<pepper-modal class="modal-type" [active]="politicaDataModal" (close)="handlePoliticaDataCloseModal()" (overlay)="handlePoliticaDataCloseModal()">
  <app-politica-privacidad></app-politica-privacidad>
</pepper-modal>