import { Component, OnInit } from '@angular/core';
import { PepperService } from 'src/app/services/pepper/pepper.service';

@Component({
  selector: 'app-verificacion-facial',
  templateUrl: './verificacion-facial.component.html',
  styleUrls: ['./verificacion-facial.component.scss']
})

export class VerificacionFacialComponent implements OnInit {

  extraStep = false

  constructor(private pepper: PepperService) {
    this.extraStep = !!this.pepper.getNeedsExtraStep()
  }

  async ngOnInit(): Promise<void> { }

}
