import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obfuscate'
})
export class ObfuscatePipe implements PipeTransform {

  private obfuscateCellphone (raw: string): string {
    return '***' + raw.slice(-3)
  }

  private obfuscateEmail (raw: string): string {
    const emailParts = raw.split('@')

    return emailParts[0].substring(0, 3) + '***@' + emailParts[1];
  }

  private obfuscateEmailForMessage (raw: string): string {
    const emailParts = raw.split('@')
    const firstArrayEmail = emailParts[0];
    const lengthFirstPiece = firstArrayEmail.length;
    return firstArrayEmail.substring(0, 3) + '***' + firstArrayEmail.substring(lengthFirstPiece-1, lengthFirstPiece) + '@' + emailParts[1];
  }

  
  
  transform(value: string, type: string): string {
    switch (type) {
      case 'cellphone': return this.obfuscateCellphone(value)
      case 'email': return this.obfuscateEmail(value)
      case 'emailForMessage': return this.obfuscateEmailForMessage(value)
      default: return '***'
    }
  }
}
