import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'tc-front',
  templateUrl: './tc-front.component.html',
  styleUrls: ['./tc-front.component.scss']
})
export class TcFrontComponent implements OnInit {
  @ViewChild('cardnumber') cardNumberNode: any;
  @ViewChild('tooltip') tooltip: any;

  @Input() obfuscated: boolean = false
  @Input() type: string = ''
  @Input() name: string = ''
  @Input() card: string = ''
  @Input() month: string = ''
  @Input() year: string = ''
  @Input() csc: string = ''


  constructor(private renderer: Renderer2) { }

  copyCardNumber() {
    if (typeof window.getSelection === 'undefined') return;
    var range = document.createRange();
    range.selectNode(this.cardNumberNode.nativeElement);

    const selection = window.getSelection()

    if (selection === null) return;

    selection.addRange(range);

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copy email command was ' + msg);
      this.showTooltip()
      const timeOutId = setTimeout(() => {
        this.hideTooltip();
      }, 8000);
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    selection.removeAllRanges();
  }

  ngOnInit(): void {
  }

  showTooltip() {
    this.renderer.setStyle(this.tooltip.nativeElement, 'opacity', '1');
    this.renderer.setStyle(this.tooltip.nativeElement, 'transform', 'translateY(31px)');
  }

  hideTooltip() {
    this.renderer.setStyle(this.tooltip.nativeElement, 'opacity', '0');
    this.renderer.setStyle(this.tooltip.nativeElement, 'transform', 'translateY(0px)');
  }

}
