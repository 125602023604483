import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import { PepperService } from 'src/app/services/pepper/pepper.service';
import { ApiOfertasService } from 'src/app/services/api-ofertas/api-ofertas.service';
import { ApiPepperBackendService } from 'src/app/services/api-pepper-backend/api-pepper-backend.service';
import { environment } from 'src/environments/environment';
import { ParametrosService } from 'src/app/services/parametros/parametros.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ApiCalificacionService } from 'src/app/services/api-calificacion/api-calificacion.service';


@Component({
  selector: 'app-seleccion-tarjeta',
  templateUrl: './seleccion-tarjeta.component.html',
  styleUrls: ['./seleccion-tarjeta.component.scss']
})
export class SeleccionTarjetaComponent implements AfterViewInit {

  @ViewChild("cardsWrapper") cardsWrapper: ElementRef<HTMLElement> | undefined

  slider: KeenSliderInstance | null = null
  legales = ''
  extraStep = false
  loading: boolean
  cardData: Record<string, any>

  constructor(
    private pepper: PepperService,
    private ofertas: ApiOfertasService,
    private calificacion: ApiCalificacionService,
    private navigate: NavigateService,
    private backend: ApiPepperBackendService,
    private parametros: ParametrosService,
    private reporte: ReportesService,
    private utils: UtilsService
  ) {
    this.legales = this.pepper.getContentData()?.partner_legal['value']
    this.extraStep = !!this.pepper.getNeedsExtraStep()
    this.loading = true
    this.cardData = {}
  }

  get personName() {
    const persona = this.ofertas.getPerson()
    return persona.firstName
  }

  get offers() {
    return this.ofertas.offersCalificacion
  }

  get chAssetUrl() {
    return environment.consts?.chAssetsUrl
  }

  public async seleccionarTarjeta(card: any, data: any) {
    this.pepper.setSelectedCard(card, data)
    this.calificacion.searchProposal(card)

    if (this.parametros.config['FLAG_OTP'] === true || this.pepper.getEmailAlternativo()) {
      if (this.parametros.config['FlagBiometria'] === true || this.pepper.getValidacionBiometria() === true) {
        this.navigate.to('usuario/configurar-tc');
        return;
      }
      this.navigate.to('usuario/identidad/necesita-validar')
    } else {
      this.navigate.to('usuario/identidad/enviar-otp')
    }
  }

  async ngAfterViewInit(): Promise<void> {
    if (typeof this.cardsWrapper === 'undefined') {
      return;
    }

    this.slider = new KeenSlider(this.cardsWrapper.nativeElement, {
      mode: "snap",
      slides: {
        origin: "center",
        perView: 1.5,
        spacing: 15,
      },
      breakpoints: {
        '(min-width: 768px)': {
          slides: {
            origin: "center",
            perView: 2.2,
          }
        },
        '(min-width: 1280px)': {
          slides: {
            perView: 3,
          }
        },
      },
    })

    const cardCodes = this.offers.map(offer => offer.cardCode)

    const response = await this.backend.getCardsInfo(cardCodes)

    this.cardData = response

    const currentPersona = this.pepper.currentPerson()
    if (currentPersona === undefined) throw new Error('No person data found')

    this.reporte.addToReport({
      full_name: `${currentPersona.firstName} ${currentPersona.secondName} ${currentPersona.lastName} ${currentPersona.secondLastName}`.replace(/\s+/g, ' ').toUpperCase(),
      document_number: currentPersona.documentNumber,
      reason: "P",
      properties: {
        campana: this.showcampana(this.offers[0].incomeFlow),
        flagCampania: "SI",
        tipoCliente: currentPersona.customerId ? 'SI' : 'NO',
        flagR1: 'NO',
        codigoUnico: currentPersona.customerId,
        pagina: 'Campaña',
        device: this.utils.detectBrowser()
      },
    })

    this.loading = false

  }

  showcampana(code: string | undefined) {
    let flowName;
    switch (code) {
      case '05': flowName = "100% Aprobado"; break;
      case '03': flowName = "Regular"; break;
      case '01': flowName = "Fast Track"; break;
      default: flowName = "No Campaña"; break;
    }
    return flowName;
  }


  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }

}
