<div class="page-error-expediente">
    <div class="container">
        <img src="../../../assets/icon/error/tiempo-espera.svg" alt="Error">
        <p class="text-1">
            <span class="title">Lo sentimos,</span>
        </p>
        <p class="text-1">
            Al parecer has iniciado una solicitud de tarjeta a través de otro canal, para realizarla por este medio por favor inténtalo en 24 horas.
        </p>     
        <button (click)="reloadApp()" class="btn center-block fw--mobile">Volver al inicio</button>
    </div>
</div>