import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { InternalService } from 'src/app/common/InternalService';
import { environment } from 'src/environments/environment';
import { ParametrosService } from '../parametros/parametros.service';
import { PepperService } from '../pepper/pepper.service';
import { ApiOfertasService, Oferta } from '../api-ofertas/api-ofertas.service';
import { UnqualifiedProposalError } from 'src/app/errors/UnqualifiedProposalError';
import { QradarService } from '../qradar/qradar.service';
import { EmailAddress, ProposalOferta } from '../api-ofertas/responses/OfertasResponse';

@Injectable({
  providedIn: 'root'
})
export class ApiCalificacionService extends InternalService {

  private constants: Record<string, string | number | boolean> = {}

  private selectedProposal: any;
  private listProposal: ProposalOferta[] = [];
  private expedientNumber!: string

  private selectedUbigeo: string | undefined;

  get proposal() {
    return this.selectedProposal
  }

  setProposal(proposal: ProposalOferta) {
    this.selectedProposal = proposal;
  }

  get listaProposals() {
    return this.listProposal.length > 0 ? this.listProposal : this.oferta.proposals
  }

  get expedienteId() {
    return this.expedientNumber
  }

  get ubigeo() {
    return this.selectedUbigeo
  }

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient,
    private oferta: ApiOfertasService,
    private qradar: QradarService,
    private pepper: PepperService,
  ) {
    super(parametros, http, environment.services.pepper);
  }

  get user() {
    return this.oferta.getPerson()
  }

  get cardNames() {
    return this.parametros.cardNames
  }

  get cf() {
    return this.pepper.getClientFormData() ?? {}
  }


  private async getConstants() {
    const constants = {} as Record<string, string | number>

    const response = await this.parametros.getParametrosDetailFromEstructura('DUMMY_CALIFICACION')
    response[0].parametro_detalles.forEach(detalle => {
      let valor: any = detalle.valor
      switch (valor) {
        case 'true': valor = true; break;
        case 'false': valor = false; break;
        case 'null': valor = null; break;
        default: break;
      }
      constants[detalle.codigo] = valor
    })

    this.constants = constants
  }

  public async qualify(ubigeo: string, department: string, province: string, district: string,): Promise<void> {
    this.selectedUbigeo = ubigeo
    await this.getConstants()
    const reentryLogic = this.oferta.reentryLogic
    try {
      const selectedOffer = this.pepper.getSelectedCard()
      const user = this.pepper.currentPerson()

      if (user === null) throw new Error('Empty user')

      const requestPayload: any = {
        body: {
          "persons": [
            {
              "managementType": "SIN_FIRMA",
              "procedureType": "AFILIACION",
              "identityDocument": {
                "type": "DNI",
                "number": user.documentNumber
              },
              "birthDate": user.birthdate,
              "firstName": user.firstName,
              "middleName": user.secondName ? user.secondName : '',
              "lastName": user.lastName,
              "fullName": `${user.lastName} ${user.secondLastName}, ${user.firstName} ${user.secondName}`.replace(/\s+/g, ' '),
              "gender": user.genderType === 'F' ? "FEMENINO" : "MASCULINO",
              "maritalStatus": this.constants['person-maritalStatus'],
              "personType": "CLIENTE",
              "type": "TITULAR",
              "company": {
                "name": this.constants['company-name'],
                "occupation": this.constants['company-occupation'],
                "ruc": this.constants['company-ruc'],
                "admissionDate": this.constants['company-admissionDate'],
                "employmentSituation": this.constants['company-employmentSituation'],
                "address": {
                  "department": this.constants['company-department'],
                  "province": this.constants['company-province'],
                  "district": this.constants['company-district'],
                }
              },
              "address": {
                department,
                province,
                district
              },
              "hasExtracashCampaign": this.constants['hasExtracashCampaign'],
              "extracashDisbursementDate": this.constants['extracashDisbursementDate'],
              "secondLastName": user.secondLastName,
            }
          ],
          "products": [
            {
              "group": "CREDIT_CARD",
              "type": this.cardNames.type[selectedOffer['marcaTarjeta']],
              "subType": this.cardNames.subtype[selectedOffer['tipoTarjeta']],
              "evaluateGuarantee": false,
              "evaluateMemberGuarantee": false,
              "evaluateDebtPurchase": false,
              "isPrincipal": this.constants['product-isPrincipal'],
              "requestId": this.constants['product-requestId'],
              "requestType": "AFILIACION",
              "evaluateConjugalIncome": false
            }
          ],
          "productApplications": [
            {
              "leadId": selectedOffer.id,
              "product": this.cardNames.type[selectedOffer['marcaTarjeta']],
              "productApplication": this.constants['productApplication'],
              "productApplicationType": this.constants['productApplicationType'],
              "productClassification": this.constants['productClassification'],
              "subProduct": this.cardNames.subtype[selectedOffer['tipoTarjeta']]
            }
          ],
          "reentryLogic": reentryLogic,
          "channelSale": this.constants['channelSale'],
          "salePoint": this.constants['salePoint'],
          "storeManagement": this.constants['storeManagement'],
          "managementType": this.constants['managementType'],
          "procedureType": this.constants['procedureType'],
        }
      }
      requestPayload.body.persons[0].phone = user.phoneInformation.map((item: any) => {
        return {
          "type": "CELULAR", "number": item.phoneNumber, "id": item.secondaryId, "carrier": this.parametros.operators[this.cf['operador']].toUpperCase()
        }
      })

      requestPayload.body.persons[0].email = this.agregarCorreo(user.emailAddress)

      if (user.customerId) {
        requestPayload.body.persons[0].id = user.customerId
        requestPayload.body.persons[0].customerId = user.customerId
      }

      requestPayload.body["realizarCalificacionProducto"] = true

      const response = await this.doRequestToEndpoint<any>('calificacionCalificar', requestPayload)
      this.listProposal = response.requestedProduct.creditCard.proposals
      this.visualizarTarjetas();

      if (this.oferta.offersCalificacion && this.oferta.offersCalificacion.length <= 0) {
        throw new UnqualifiedProposalError(selectedOffer)
      }
      this.setQualification(response.expedientNumber, this.listProposal[0])
      await this.registrarExpedienteRedis(false)
    } catch (error) {
      const err = error as HttpErrorResponse
      if (err.status && err.status !== 403) {
        const data = {
          parametros: [
            {
              clave: 'DETALLEERROR',
              valor: 'ERROR EvaluarCalificacion - '.concat(err.status ? err.status.toString() : 'interno', ' - ', err.error ? err.error['errorDetail'] ? err.error['errorDetail'] : '' : '')
            }
          ]
        }
        await this.qradar.addToRegister('calificacionCalificar', data);
      }
      throw error
    }

  }

  async registrarExpedienteRedis(validacionBiometria: boolean) {
    const requestPayload: any = {
      body: {
        expedientNumber: this.expedientNumber,
        emailOTP: this.pepper.getEmailAlternativo(),
        validacionBiometria: validacionBiometria
      }
    }
    await this.doRequestToEndpoint<any>('registrarAutenticacion', requestPayload)
  }

  public agregarCorreo(emailAddress: Array<EmailAddress>) {
    const emailAlternativo = this.pepper.getEmailAlternativo();
    let listaDeCorreos = emailAddress.map((item: any) => {
      let typeAssi = ''
      switch (item.useCode) {
        case 'EMAPER':
          typeAssi = "PERSONAL";
          break;
        default:
          typeAssi = item.useCode;
          break;
      }
      return {
        type: typeAssi,
        value: item.emailAddress,
        id: item.extensionValue
      }
    })

    if (!emailAlternativo) return listaDeCorreos;

    const correoExistente = listaDeCorreos.find((item) => item.value === emailAlternativo);
    if (correoExistente) {
      listaDeCorreos = [correoExistente, ...listaDeCorreos.filter((item) => item.value !== emailAlternativo)]
    } else {
      listaDeCorreos.unshift({
        type: 'TRABAJO',
        value: emailAlternativo!,
        id: '001EMALT',
      });
    }

    return listaDeCorreos;
  }

  public setQualification(expedientNumber: string, qualifiedProposal: any) {
    this.selectedProposal = qualifiedProposal
    this.expedientNumber = expedientNumber
  }

  // Función para obtener los códigos de las propuestas
  obtenerCodigosDeProposals(): { productTypeCode: string, subProductCode: string, maxLine: string | number }[] {
    return this.listProposal.map(proposal => ({
      productTypeCode: this.cardNames.typeFlipped[proposal.productType],
      subProductCode: this.cardNames.subtypeFlipped[proposal.subProduct],
      maxLine: parseInt(proposal.maxLine + '')
    }));
  }

  //filtrar ofertas basándose en códigos
  filtrarOfertasPorCodigos(codigos: { productTypeCode: string, subProductCode: string, maxLine: string | number }[]): Oferta[] {
    const filtro = this.oferta.offers.filter(oferta =>
      codigos.some(codigo =>
        oferta.marcaTarjeta === codigo.productTypeCode && oferta.tipoTarjeta === codigo.subProductCode
      )
    );

    filtro.forEach(offer => {
      const codigoCorrespondiente = codigos.find(cod =>
        offer.marcaTarjeta === cod.productTypeCode && offer.tipoTarjeta === cod.subProductCode
      );
      if (codigoCorrespondiente) offer.monto = codigoCorrespondiente.maxLine
    })
    return filtro;
  }

  // Muestra las tarjetas a las que califica 
  visualizarTarjetas() {
    const codigos = this.obtenerCodigosDeProposals();
    const ofertasFiltradas = this.filtrarOfertasPorCodigos(codigos);
    this.oferta.setOfertasCalificacion(ofertasFiltradas);
  }

  // Busca el proposal de la tarjeta selecionada
  searchProposal(card: any) {
    const productTypeName = this.cardNames.type[card.marcaTarjeta];
    const subProductTypeName = this.cardNames.subtype[card.tipoTarjeta];

    this.selectedProposal = this.listaProposals.find(proposal =>
      proposal.productType === productTypeName && proposal.subProduct === subProductTypeName
    );
  }
}
