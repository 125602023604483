import { Component, Input, AfterViewInit, Renderer2, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'input-range',
  templateUrl: './input-range.component.html',
  styleUrls: ['./input-range.component.scss']
})
export class InputRangeComponent implements AfterViewInit {

  @ViewChild('ir') input: any;
  @ViewChild('currentIndicator') currentIndicator: any;

  @Input('value') val: number = 0;
  @Input() min: number = 0;
  @Input() max: number = 0;
  @Input() step: number = 1;

  @Output('changes') changeEvent = new EventEmitter<number>();

  constructor(
    private renderer: Renderer2
  ) { }

  updateProgressUI (currentValue: number) {
    const percentage = (currentValue - this.min) * 100 / (this.max - this.min)
    
    this.renderer.setStyle(this.currentIndicator.nativeElement, 'left', `${percentage}%`);
    this.renderer.setStyle(this.input.nativeElement, 'background-size', percentage + '% 100%');
  } 

  onChange (currentValue: number) {
    this.changeEvent.emit(currentValue);
    this.updateProgressUI(currentValue)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['val'] && !changes['val'].firstChange) {
      this.updateProgressUI(changes['val'].currentValue)
    }
  }

  ngAfterViewInit(): void {
    this.updateProgressUI(this.val)
  }

}
