<section *ngIf="!errorOfertas" class="homepage-main">
    <aside>
        <div class="referrer-brand-banner">
            <picture>
                <img [src]="bannerImgUrl" [alt]="storeName">
            </picture>
        </div>
        <div class="wrapper">
            <div class="referrer-brand-logo">
                <picture>
                    <img [src]="storeLogo"  [alt]="storeName">
                </picture>
            </div>
            <h2 class="heading-2 text-center--mobile text-center--tablet text-left--desktop">Obtén <strong class="color-green" [innerText]="partnerBenefit"></strong> con tu nueva tarjeta de crédito Interbank</h2>

            <div class="beneficios">
                <p class="beneficios-title text-center--mobile text-center--tablet text-left--desktop">Otros beneficios de tu tarjeta</p>
                <div class="beneficios-slider__wrapper">
                    <div class="beneficios-slider keen-slider" #sliderBeneficios>
                        <div class="keen-slider__slide" *ngFor="let slide of slider_beneficios">
                            <div class="icon">
                                <img [src]="slide['icon']">
                            </div>
                            <div class="text">
                                <strong class="text-center--mobile text-center--tablet text-left--desktop">{{ slide['title'] }}</strong>
                                <p class="text-center--mobile text-center--tablet text-left--desktop">{{ slide['description'] }}</p>
                            </div>
                        </div>
                    </div>
                    <button (click)="setPrevBeneficios($event)" [disabled]="!canGoPrev" class="beneficios--prev"></button>
                    <button (click)="setNextBeneficios($event)" [disabled]="!canGoNext" class="beneficios--next"></button>
                    <div class="beneficios-slider--pagination">
                        <button *ngFor="let progress of slider_paginas; let ix = index" (click)="setBeneficiosSliderProgress(ix)" [ngClass]="{'active': progress === currentBeneficiosAbs}"></button>
                    </div>

                </div>
            </div>
            
        </div>
    </aside>
    <section>
        <form *ngIf="clientForm !== undefined" [formGroup]="clientForm" #formDir="ngForm" (ngSubmit)="handleSubmit()" class="form">
            <h3 class="heading-3">Ingresa tus datos y solicita tu tarjeta al instante</h3>
            <label class="identidad">
                <span class="tooltip">Para una correcta evaluación<br>ingresa tus datos y no de<br>terceros.</span> 
                <span>Número de DNI</span>
                <input class="input" type="text" mask="00000000" formControlName="dni" id="input_dni" [readOnly]="loading">
                <span class="error-msj">{{clientForm.controls['dni'].errors | formControlErrorMessage:'número de documento'}}</span>
            </label>
            <label>
                <span>E-mail</span>
                <input class="input" type="email" formControlName="email" id="input_email" [readOnly]="loading">
                <span class="error-msj">{{clientForm.controls['email'].errors | formControlErrorMessage:'email'}}</span>
            </label>
            <div class="i-group">
                <label>
                    <span>Operador</span>
                    <ng-select  class="input" formControlName="operador" id="input_operador" placeholder="Selecciona ..." [clearable]="false">
                        <ng-option *ngFor="let operador of operators | keyvalue" [value]="operador.key">{{operador.value}}</ng-option>
                    </ng-select>
                    <span class="error-msj">{{ clientForm.controls['operador'].errors | formControlErrorMessage:'':{'required': 'Selecciona tu operador telefónico'} }}</span>
                </label>
                <label>
                    <span>Número de celular</span>
                    <input class="input" type="tel" mask="000000000" [readOnly]="loading" formControlName="celular" id="input_celular">
                    <span class="error-msj">{{clientForm.controls['celular'].errors | formControlErrorMessage:'número de celular':{'notLength': 'El celular debe contar con 9 caracteres'} }}</span>
                </label>
            </div>
            <div class="row">
                <label class="checkbox mb-075 checkbox-label">
                    <input type="checkbox" formControlName="autorizacion" id="input_autorizacion">
                    <span class="checkbox"></span>
                    <p class="weight-medium2">He leído y acepto la <a  (click)="openPersonalDataModal($event)" class="no-text-decoration color-green weight-medium2" href="#">Política de Privacidad</a>.</p>
                </label>
            </div>
            
            <div class="row mb-125" *ngIf="recaptcha.enabled">
                <re-captcha [siteKey]="recaptcha.key" id="triggerResolvedCaptchaModal" (resolved)="handleResolvedCaptcha($event)"></re-captcha>
                <p class="text-3 mt-050">Este sitio esta protegido por reCAPTCHA. Aplican la <a class="color-green no-text-decoration" href="https://policies.google.com/privacy?hl=es" rel="nofollow" target="_blank">Política de Privacidad</a> de Google y los <a class="color-green no-text-decoration" href="#" id="triggercDataModalModal" (click)="openTycDataModal($event)">Términos del Servicio.</a></p>
                <div class="notice error mt-050" *ngIf="isRecaptchaTouched && !isRecaptchaValid">No se validó el recaptcha con éxito.</div>
            </div>

            <button id="submit_btn" type="submit" class="btn fw--mobile" [disabled]="loading || (!clientForm.valid || !isRecaptchaValid)">{{ loading ? 'Cargando ...' : 'Comencemos' }}</button>
        </form>
    </section>
</section>
<section *ngIf="!errorOfertas" class="text-legales">
    <div class="container">
        <small>{{legales}}</small>
    </div>
</section>
<section *ngIf="errorOfertas">
    <app-error-ofertas [message]="errorOfertasMessage" [load]="loadOfertas" (retry)="handleSubmit()"></app-error-ofertas>
</section>


<pepper-modal class="modal-type" [active]="tycDataModal" (close)="handleTycDataCloseModal()" (overlay)="handleTycDataCloseModal()" [actionButton]="true" actionButtonLabel="Entiendo" (actionButton)="handleTycDataCloseModal()">
    <div class="container page-modal" [innerHTML]="contentTerminosyCondiciones | safe:'html'"></div>
</pepper-modal>

<pepper-modal class="modal-type" [active]="personalDataModal" (close)="handlePersonalDataCloseModal()" (overlay)="handlePersonalDataCloseModal()">
    <app-politica-privacidad></app-politica-privacidad>
</pepper-modal>