import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { PepperService } from 'src/app/services/pepper/pepper.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-usuario-sin-campana',
  templateUrl: './usuario-sin-campana.component.html',
  styleUrls: ['./usuario-sin-campana.component.scss']
})
export class UsuarioSinCampanaComponent implements OnInit {

  title: string;
  legal: string;
  url: string;

  constructor(
    private pepper: PepperService,
    private reporte: ReportesService,
    private activatedRoute: ActivatedRoute,
    private utils: UtilsService
  ) {
    
    this.title = this.pepper.getContentData()?.partner_alternative_benefit['value'];
    this.legal = this.pepper.getContentData()?.partner_alternative_legal['value'];
    this.url = this.pepper.getContentData()?.partner_alternative_url['value'];

  }

  async ngOnInit(): Promise<void> {

    const pageData = await lastValueFrom(this.activatedRoute.data);

    await this.reporte.addToReport({
      req_status: 'Proceso',
      properties: {
        flagCalificacion: 'NO',
        pagina: pageData['page_name'],
        device: this.utils.detectBrowser()
      }
    })
  }

}
