<step-indicator [current]="extraStep ? 5 : 4" title="Valida tu identidad"></step-indicator>
<div class="overlay" [ngClass]="{'active': opened}"></div>

<section *ngIf="checkingBlock" class="loader full-width">
    <img src="../../../../assets/preloader.svg" class="center-block">
</section> 

<section class="screen-validar-identidad" [ngClass]="{megahidden: checkingBlock}">
    <h3 class="heading-3"><strong>Por tu seguridad necesitamos validar tu identidad</strong></h3>
    <div class="container">
        <img class="center-block" src="../../../assets/icon/takepicture.svg" alt="Tómate un selfie">
        <h4>Tómate una selfie</h4>
        <ul class="list">
            <li>Recuerda que en el selfie <strong>solo puedes aparecer tú.</strong></li>
            <li>Retira cualquier <strong>accesorio de tu rostro, evita moverte y procura que la foto tenga buena calidad</strong> para poder procesarla.</li>
            <li>Considera que <strong>la foto se tomará automáticamente.</strong></li>
        </ul>
    
        <div class="notice info">Abriremos una ventana adicional para realizar la validación. Recuerda que esta vista quedará bloqueada hasta terminar el proceso de validación.</div>
    
        <button class="btn fw--mobile center-block" (click)="openBiometriaPopup()" [disabled]="!ready || opened" id="open_popup_btn">
            {{ btnTxt }}
        </button>
    </div>
</section>