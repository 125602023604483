import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiAutenticacionService } from 'src/app/services/api-autenticacion/api-autenticacion.service';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import { ParametrosService } from 'src/app/services/parametros/parametros.service';
import { PepperService } from 'src/app/services/pepper/pepper.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { emailValidator } from 'src/utils/formControlValidators';

@Component({
  selector: 'app-otp-send',
  templateUrl: './otp-send.component.html',
  styleUrls: ['./otp-send.component.scss']
})
export class OtpSendComponent {

  persona = {
    nombre: '',
    correos: [] as Array<Record<string, string>>,
    telefonos: [] as Array<Record<string, string>>
  }
  
  otpForm: UntypedFormGroup
  addEmail: boolean = false
  loading = false
  extraStep = false

  constructor(
    private autenticacion: ApiAutenticacionService,
    private navigate: NavigateService,
    private pepper: PepperService,
    private reportes: ReportesService,
    private utils: UtilsService,
    private parametro: ParametrosService
  ) {
    this.extraStep = !!this.pepper.getNeedsExtraStep()
    const persona = this.pepper.currentPerson()
    if (persona === null) throw new Error('Empty person')
    this.persona.nombre = persona.firstName

    this.persona.correos = persona.emailAddress.map(email => {
      return {
        'id': email.emailAddress,
        'value': email.emailAddress
      }
    })

    this.persona.telefonos = persona.phoneInformation.map(phone => {
      return {
        'id': phone.phoneNumber,
        'value': phone.phoneNumber
      }
    })

    this.otpForm = new UntypedFormGroup({
      contact_otp: new UntypedFormControl('', [
        Validators.required
      ]),

      email: new UntypedFormControl('')
    });

    const cfData = this.pepper.getClientFormData()
    if (cfData === undefined) {
      return;
    }

    switch (this.persona.correos.length) {
      case 0: throw new Error('No email');
      // case 1: this.generateToken(this.persona.correos[0]['value']); break;
      default: /* Show screen */ break;
    }

  }

  async ngOnInit(): Promise<void> {
    await this.reportes.addToReport({
      req_status: 'Proceso',
      properties: {
        pagina: 'OTP',
        device: this.utils.detectBrowser()
      }
    })
  }

  async generateToken (email: string) {
    try {
      await this.autenticacion.generateToken(email)
      this.navigate.to('usuario/identidad/validar-otp')
    } catch (e) {
      this.navigate.toServiceError('ERROR_API_AUTENTICACION')
    }
  }

  ingresarCorreo() {
    this.otpForm.get('contact_otp')?.clearValidators();
    this.otpForm.get('contact_otp')?.updateValueAndValidity();
    this.otpForm.get('email')?.addValidators([Validators.required, emailValidator()]);
    this.otpForm.get('email')?.updateValueAndValidity();
    this.addEmail = true
  }

  async handleSubmit () {
    const input = this.addEmail ? this.otpForm.get('email') : this.otpForm.get('contact_otp')
    if (!input) throw new Error('Ocurrio un error obteniendo la información del formulario')
    
    if (this.otpForm.valid) {
      this.loading = true
      try {
        const email = this.addEmail ? input.value : input.value.value
        this.pepper.setEmailAlternativo(email)
        await this.reportes.addToReport({
          properties: {
            emailOtp: email
          }
        })
        await this.generateToken(email)
      } catch (e) {
        this.navigate.toServiceError('ERROR_API_AUTENTICACION')
      } finally {
        this.loading = false
      }
    }
  }

  showOptionEmailAlternativo() {
    return this.parametro.emailAlternativo['emailAlternativo'] && this.pepper.currentPerson().customerId;
  }

}
