// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from "src/app/common/interfaces/IEnvironment";
import {
  HttpMethods,
  Service
} from "src/app/common/Service";

const environment: IEnvironment = {
  production: false,

  mode: 'uat',

  consts: {
    chAssetsUrl: 'https://content-us-1.content-cms.com'
  },

  recaptcha: {
    siteKey: '', // Obtained from parametros service
  },

  tiempoPorIncidencias: 1,
  qradarIsActive: true,

  intentos: {
    ofertasOffersiItentos: 1,
    authenticationAuthIntentos: 1,
    authenticationValidateIntentos: 1,
    calificacionCalificarIntentos: 1,
    altaTcIntentos: 1,
  },

  biometriaIframeUrl: 'https://identity.acceptance.interbank.pe/#/auth?transaction=',

  subscriptionApiHeader: {
    key: 'Pep-ApimInt-Subscription-Key',
    value: 'b2fa5d88cd0940daa95f46c081114eec'
  },

  subscriptionKeyWebComponent: {
    key: 'Ocp-Apim-Subscription-Key',
    value: 'a1ca49de70964e9ba7e417d8ea7f7375'
  },

  subscriptionKeyWebComponents: {
    cuentaSimple: {
      key: 'subscription-key',
      value: 'e547435595fd4ac3a4291aa456e3d43d',
      commerceId: 'RzZYuFa7Fd8yb4Ch',
      credential: 'UnpaWXVGYTdGZDh5YjRDaDo5M3FYclZyYUczaHV5TnZa'
    }
  },

  services: {
    pepperBackend: new Service('pepperBackend', '/api/pepper/backend', [{
      name: 'contentHub',
      endpoint: '/content-hub/associate',
      method: HttpMethods.GET,
      attrs: {
        defaultAssociateName: 'shopstar',
        assetsBaseUrl: 'https://content-us-1.content-cms.com'
      }
    }, {
      name: 'chCards',
      endpoint: '/content-hub/cards',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }

    }, {
      name: 'validateRecaptcha',
      endpoint: '/validate-recaptcha',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }

    }, {
      name: 'biometriaOauth',
      endpoint: '/biometria/oauth',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }

    }, {
      name: 'biometriaVerify',
      endpoint: '/biometria/verify',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }

    }, {
      name: 'biometriaIntentosFallidos',
      endpoint: '/biometria-intentos-fallidos',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }
    }, {
      name: 'tokenValidacion',
      endpoint: '/vdt/oauth',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'API_VDT'
      }
    }, {
      name: 'tokenCuentaSimple',
      endpoint: '/cuentasimple/oauth',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'API_CUENTASIMPLE_OAUTH'
      }
    }]),
    parametros: new Service('parametros', '/api/pepper/gestionparametro', [{
      name: 'estructura',
      endpoint: '/estructura-redis',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }
    },
    {
      name: 'estructuraDetalle',
      endpoint: '/estructura-detalle',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }
    }, {
      name: 'parametros',
      endpoint: '/parametros',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }
    },
    {
      name: 'parametroDetalle',
      endpoint: '/parametros-detalle',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }
    }, {
      name: 'ubigeo',
      endpoint: '/ubigeos',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }
    }, {
      name: 'parametroDetallePorEstructura',
      endpoint: '/parameter/estructura',
      method: HttpMethods.GET,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }
    }, {
      name: 'getCoupon',
      endpoint: '/commerce/{store_name}/coupon',
      method: HttpMethods.PUT,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }
    }, {
      name: 'createReporte',
      endpoint: '/requests',
      method: HttpMethods.POST,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }
    }, {
      name: 'updateReporte',
      endpoint: '/requests/{req_id}',
      method: HttpMethods.POST,
      attrs: {
        parameterHeadersName: 'PEPPER_GENERAL'
      }
    }
    ]),
    pepper: new Service('pepper', '/api/pepper', [
      {
        name: 'ofertasOffers',
        endpoint: '/ofertas/offers',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_OFERTAS'
        }
      },
      {
        name: 'multiOfertas',
        endpoint: '/ofertas/multioffers',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_MULTI_OFERTAS'
        }
      },
      {
        name: 'authenticationAuth',
        endpoint: '/authentication/auth',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_AUTHENTICATION'
        }
      },
      {
        name: 'authenticationValidate',
        endpoint: '/authentication/valid',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_AUTHENTICATION'
        }
      },
      {
        name: 'intentosOTP',
        endpoint: '/intentos/validarotp/{document}/{otp}',
        method: HttpMethods.PUT,
        attrs: {
          parameterHeadersName: 'API_AUTHENTICATION'
        }
      },
      {
        name: 'calificacionCalificar',
        endpoint: '/calificacion/calificar',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_CALIFICACION'
        }
      },
      {
        name: 'calificacionConsultaexpediente',
        endpoint: '/calificacion/consultaexpediente',
        method: HttpMethods.GET
      },
      {
        name: 'registrarAutenticacion',
        endpoint: '/calificacion/registrarfactorautenticacion',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_GRABAR_FACTORAUTH'
        }
      },
      {
        name: 'coordinacionentregaFechayhora',
        endpoint: '/coordinacionentrega/fechayhora',
        method: HttpMethods.GET
      },
      {
        name: 'otpHostGeneration',
        endpoint: '/otpventa/generateOTP',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_RECOMPRA'
        }
      },
      {
        name: 'get',
        endpoint: '/coordinacionentrega/fechayhora',
        method: HttpMethods.GET,
        attrs: {
          parameterHeadersName: 'API_GET'
        }
      },
      {
        name: 'alta_online',
        endpoint: '/altatc/pepperonline',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_ALTATC'
        }
      },
      {
        name: 'alta_cardless',
        endpoint: '/altatc/peppercardless',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_ALTATC'
        }
      },
      {
        name: 'alta_classic',
        endpoint: '/altatc/pepperClassic',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_ALTATC'
        }
      },
      {
        name: 'expediente',
        endpoint: '/calificacion/consultaexpediente',
        method: HttpMethods.GET,
        attrs: {
          parameterHeadersName: 'API_CONSULTA_CALIFICACION'
        }
      },
      {
        name: 'qradarRegistro',
        endpoint: '/qradar/registroqradar',
        method: HttpMethods.POST,
        attrs: {
          parameterHeadersName: 'API_QRADAR'
        }
      },

    ]),
    card: new Service('card', '/api/card/v1', [
      {
        name: 'sensitiveInfo',
        endpoint: '/sensitive-info',
        method: HttpMethods.POST
      },
      {
        name: 'decryptUtil',
        endpoint: '/decrypt-util',
        method: HttpMethods.POST
      }
    ]),
    antifraud: new Service('antifraud', '/api/antifraud/v2', [
      {
        name: 'risk',
        endpoint: '/risk/{documentNumber}',
        method: HttpMethods.PUT,
        attrs: {
          parameterHeadersName: 'API_ANTIFRAUDE_HEADERS'
        }
      }
    ]),
    mock: new Service('mock', 'https://run.mocky.io/v3', [
      {
        name: 'mock500',
        endpoint: '/cd8f2464-5f79-47ff-8387-31e1e44d439f',
        method: HttpMethods.POST,
      },
      {
        name: 'ofertasOffers',
        endpoint: '/7fd5398e-5818-4f53-8a39-bb3e7ab08e99',
        method: HttpMethods.POST,
      },
      {
        name: 'mock404',
        endpoint: '/7b4d3651-53c1-421e-acdb-0df223274c46',
        method: HttpMethods.POST,
      },
      {
        name: 'calificarExito',
        endpoint: '/455aea44-6079-4bc8-aca1-35be07c7910c',
        method: HttpMethods.POST,
      }
    ])
  },
};


export { environment };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
