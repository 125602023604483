import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ucwords'
})
export class UcwordsPipe implements PipeTransform {

  transform(value: string): string {
    return value
      .toLowerCase()
      .replace(/(^[a-záéíóúñüö]|\s[a-záéíóúñüö])/, function (str: string) {
          return str.toUpperCase()
        })
  }

}
