import { Component, OnInit } from '@angular/core';
import { BiometriaReachedIntentsLimitError } from 'src/app/errors/BiometriaReachedIntentsLimitError';
import { ApiPepperBackendService } from 'src/app/services/api-pepper-backend/api-pepper-backend.service';
import { BiometriaService } from 'src/app/services/biometria/biometria.service';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import { popupCenter } from 'src/utils/popup';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ApiCalificacionService } from 'src/app/services/api-calificacion/api-calificacion.service';
import { PepperService } from 'src/app/services/pepper/pepper.service';

@Component({
  selector: 'app-validar-identidad',
  templateUrl: './validar-identidad.component.html',
  styleUrls: ['./validar-identidad.component.scss']
})
export class ValidarIdentidadComponent implements OnInit {

  ready = false
  opened = false
  url: string = ''
  btnTxt: string = 'Cargando ...'
  checkingBlock: boolean = true
  extraStep = false

  constructor(
    private biometria: BiometriaService,
    private nav: NavigateService,
    private pepper: PepperService,
    private backend: ApiPepperBackendService,
    private calificacion: ApiCalificacionService,
    private reportes: ReportesService,
    private utils: UtilsService
  ) { 
    this.extraStep = !!this.pepper.getNeedsExtraStep()
  }


  async eventHandler (data: any) {
    
    if (
      data.title === 'TRANSACTION_FINISHED' &&
      typeof data.transactionId === 'string' &&
      data.transactionId.length > 0
    ) {
      this.btnTxt = 'Procesando respuesta ...'
      const response: any = await this.backend.verifyBiometriaTransactionId(data.transactionId)

      switch (response.status) {
        case 'ACCEPTED': {
          
          await this.reportes.addToReport({
            req_status: 'Proceso',
            properties: {
              tipoAutenticacion: 'BIOMETRIA-SI',//BIOMETRIA -SI
              pagina: 'Biometrias',
              device: this.utils.detectBrowser()
            }
          })
          await this.calificacion.registrarExpedienteRedis(true);
          this.nav.to('usuario/identidad/biometria/accepted'); break;
        }
        case 'INCOMPLETE':{
          
          await this.reportes.addToReport({
            req_status: 'Proceso',
            properties: {
              tipoAutenticacion: 'BIOMETRIA-INCOMPLETO',//biometria-incompleto
              pagina: 'Biometrias',
              device: this.utils.detectBrowser()
            }
          })

          this.nav.to('usuario/identidad/biometria/incomplete'); break;
        }
        case 'REJECTED':{
          
          await this.reportes.addToReport({
            req_status: 'Proceso',
            properties: {
              tipoAutenticacion: 'BIOMETRIA-NO',//biometria - No
              pagina: 'Biometrias',
              device: this.utils.detectBrowser()
            }
          })

          this.nav.to('usuario/identidad/biometria/rejected'); break;
        }
        default: break;
      }
    }
  }

  async ngOnInit(): Promise<void> {
    try {
      await this.biometria.connect(this.eventHandler.bind(this))
      this.url = this.biometria.getBiometriaFrontendURL()
      this.ready = true
      this.btnTxt = 'Continuar'
      this.checkingBlock = false
    } catch (error) {
      if (error instanceof BiometriaReachedIntentsLimitError) {
        this.nav.to('usuario/identidad/biometria/blocked');
        return;
      }
      this.nav.toServiceError('ERROR_GENERANDO_LLAVES_BIOMETRIA')
    }
  }

  openBiometriaPopup () {
    popupCenter(this.url, 'BiometriaPopup', 400, 650)
    this.opened = true
    // this.btnTxt = 'Popup de biometria abierto, esperando respuesta ...'
  }

}
