<div class="tc-front" [class.obfuscated]="obfuscated">
    <p class="card-name text-2 weight-medium2">
        <span class="logo" [class]="type"></span>
        <span id="cardName">{{name | slice:0:22 | titlecase}}</span>
    </p>

    <div class="card-number">
        <input type="text" readonly #cardnumber [value]="card">
        <button class="copy" (click)="copyCardNumber()">
            <span class="copy-name">copiar</span>
            <span #tooltip class="tooltip">Has copiado el número de<br>tu tarjeta.</span> 
        </button>
    </div>

    <div class="details">
        <div><span class="label">Vence</span><span class="value" id="cardExpiration">{{month}}/{{year}}</span></div>
        <div class="bordered" *ngIf="csc"><span class="label">CSC</span><span class="value">{{csc}}</span></div>
    </div>
</div>