import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export enum DocumentTypes {
    DNI
}

export function alpha() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const hasInvalidCharacters = /[^a-zA-ZñÑáéíóúÁÉÍÓÚöÖüÜ]/.test(control.value)
        return hasInvalidCharacters ? {isAlpha: false} : null;
    }
}

export function numeric() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const hasInvalidCharacters = /[^0-9]/.test(control.value)
        return hasInvalidCharacters ? {isNumeric: false} : null;
    }
}

export function alphanumeric() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const hasInvalidCharacters = /[^a-zA-ZñÑáéíóúÁÉÍÓÚöÖüÜ0-9]/.test(control.value)
        return hasInvalidCharacters ? {isAlphanumeric: false} : null;
    }
}

export function customValidator() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        // Comprueba si el valor comienza con un número seguido de letras
        const isValid = /^[0-9]+[a-zA-Z]*$/.test(value);
        return isValid ? null : { invalidFormat: true }; // Válido
    };
}

export function alphanumericExtended() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const hasInvalidCharacters = /[^a-zA-ZñÑáéíóúÁÉÍÓÚöÖüÜ0-9\s]/.test(control.value)
        return hasInvalidCharacters ? {isAlphanumericExtended: false} : null;
    }
}

export function alphanumericExtended1() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const hasInvalidCharacters = /[^a-zA-ZñÑáéíóúÁÉÍÓÚöÖüÜ0-9,'\s]/.test(control.value)
        return hasInvalidCharacters ? {isAlphanumericExtended: false} : null;
    }
}

export function alphanumericExtended2() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const hasInvalidCharacters = /[^a-zA-ZñÑáéíóúÁÉÍÓÚöÖüÜ0-9,'\s]/.test(control.value)
        return hasInvalidCharacters ? {isAlphanumericExtended: false} : null;
    }
}

export function cellphoneValidator() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isValid = /^9[0-9]{8}$/.test(control.value)
        return !isValid ? {isPhone: false} : null;
    }
}

export function documentValidator(documentType: DocumentTypes) : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let isValid;

        switch (documentType) {
            case DocumentTypes.DNI: isValid = /^[0-9]{8}$/.test(control.value); break;
            default: isValid = false; break;
        }

        return !isValid ? {isDocument: false} : null;
    }
}

export function emailValidator() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let isValid = /^(.)+@[a-zA-Z0-9\.]+\.[a-zA-Z0-9\.]+$/.test(control.value)

        if (isValid) {
            isValid = !/\s/.test(control.value)
        }
        
        return !isValid ? {isEmail: false} : null;
    }
}

export function lengthValidator(requiredLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value + ''

        return value.length === requiredLength ? null : {notLength: requiredLength}
    }
}

export function validBirthdate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const valid = /^[0-3][0-9][0-1][0-9](19|20|21|22|23)[0-9][0-9]$/.test(control.value)

        return valid ? null : {isBirthday: false}
    }
}

export function requiredIf(condition: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!condition) return null
        const invalid = control.value === null || control.value === undefined || control.value === ''
        return invalid ? { hasValue: false } : null
    }
}