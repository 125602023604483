import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InternalService } from 'src/app/common/InternalService';
import { environment } from 'src/environments/environment';
import { ParametrosService } from '../parametros/parametros.service';
import padStart from 'lodash-es/padStart'

@Injectable({
  providedIn: 'root'
})
export class OtpService extends InternalService {

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient
  ) {
    super(parametros, http, environment.services.pepper);
  }

  private userData: {
    phone: string
  } | null = null

  get user() {
    return this.userData;
  }

  public async generateOTP(rawClientId: string, phoneNumber: string, phoneOperator: string, email: string) {

    let clientId: number | string = parseInt(rawClientId)
    clientId = padStart(clientId + '', 10, '0')
    const response = await this.doRequestToEndpoint('otpHostGeneration', {
      body: {
        generation: {
          clientId
        },
        notification: {
          "method": "PAYMENT",
          phoneNumber,
          phoneOperator,
          email
        }
      }
    })

    this.userData = {
      phone: phoneNumber
    }

    return response
  }
}
