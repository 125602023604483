import { Component, OnInit } from '@angular/core';
import { PepperService } from 'src/app/services/pepper/pepper.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-tc-lista',
  templateUrl: './tc-lista.component.html',
  styleUrls: ['./tc-lista.component.scss']
})
export class TcListaComponent implements OnInit {

  partnerBenefit: string
  partnerName: string
  celular: string
  email: string
  outOfZone: boolean
  public getUrl: string
  constructor(
    private pepper: PepperService,
    private reportes: ReportesService,
    private utils: UtilsService
  ) {
    const partnerBenefit = this.pepper.getContentData()?.partner_principal_benefit['value'];
    const partnerName = this.pepper.getContentData()?.partner_name['value'];
    const cfData = this.pepper.getClientFormData()
    const getUrl = sessionStorage.getItem("htienda") ? "/?hsede=recompra&htienda=" + sessionStorage.getItem("htienda") : '';
    if (cfData === undefined) throw new Error('No form data')

    const user = pepper.currentPerson()

    this.outOfZone = !!pepper.isOutOfZone()

    this.partnerBenefit = partnerBenefit
    this.partnerName = partnerName
    this.email = this.pepper.getEmailAlternativo()!;
    this.celular = user.phoneInformation[0].phoneNumber
    this.getUrl = getUrl;

  }

  async ngOnInit(): Promise<void> {

    const reportePayload = {
      req_status: 'Finalizado',
      properties: {
          flagVenta: "SI",
          pagina: 'Resumen',
          visualizacionTc: 'NO',
          device: this.utils.detectBrowser()
      }
    }

    await this.reportes.addToReport(reportePayload)
  }

}
