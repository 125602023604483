import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContentHubResponse } from './responses/ContentHubResponse';
import { ContentHubBadRequestError } from '../../errors/ContentHubBadRequestError';
import { ContentHubAssociateDataNotFoundError } from '../../errors/ContentHubAssociateDataNotFoundError';
import { ContentHubConnectionError } from '../../errors/ContentHubConnectionError';
import { InvalidFlowNameError } from '../../errors/InvalidFlowNameError';
import { ApiOfertasService } from '../api-ofertas/api-ofertas.service';
import { EmailAddress, PhoneInformation, PostalAddress } from '../api-ofertas/responses/OfertasResponse';
import { CurrentPersona } from './responses/CurrentPersona';
import { UtilsService } from '../utils/utils.service';
import { firstValueFrom, Observable, catchError, throwError } from 'rxjs';
import { ParametrosService } from '../parametros/parametros.service';

@Injectable({
  providedIn: 'root'
})
export class PepperService {

  private initializing = true
  private contentData: ContentHubResponse | undefined;
  private selectedFlow: string | undefined;
  private selectedStore: string | undefined;
  private selectedExp: string | undefined;
  private selectedCard: any;
  private selectedCardData: any;
  private clientFormData: Record<string, string> | undefined;
  private _personalData: {
    birthday: string,
    genre: string
  } | undefined;
  private extraAddresses: PostalAddress[] = []
  private _currentPerson: CurrentPersona | undefined;
  private _isOutOfZone: boolean | undefined;
  private emailAlternativo: string | undefined = '';
  private validacionBiometria: boolean = false;
  private dniPepperDebito: string | undefined;

  constructor(
    private http: HttpClient,
    private offers: ApiOfertasService,
    private parametro: ParametrosService,
    private util: UtilsService
  ) {}

  currentPerson () {
    if (!this._currentPerson) throw new Error('')
    return this._currentPerson;
  }

  public getNeedsExtraStep () {
    if (!this._currentPerson) throw new Error('')
    return this._currentPerson.needsExtraStep;
  }

  isOutOfZone () {
    return this._isOutOfZone
  }

  setIsOutOfZone (status: boolean) {
    this._isOutOfZone = status
  }

  public updateCurrentPerson (): void {
    let response: CurrentPersona | null = null
    const persona = this.offers.person
    
    if (this.clientFormData === undefined) {
      return;
    }
    
    response = {
      postalAddress: [] as PostalAddress[],
      emailAddress: [] as EmailAddress[],
      phoneInformation: [] as PhoneInformation[],
      personalData: null,
      ...persona ?? {
        lastName: '',
        firstName: '',
        birthdate: '',
        genderType: '',
        maritalStatus: '',
        customerFlag: '',
        phoneInformation: [],
        emailAddress: [],
        postalAddress: []
      },
      documentNumber: this.clientFormData['documentNumber'],
      ldpd: !!this.clientFormData['autorizacion'],
      needsExtraStep: false
    }

    if (persona === null) {
      return;
    }

    if (!persona.postalAddress || persona.postalAddress.length === 0) {
      response.needsExtraStep = true
    }
    
    if (!persona.customerId) {
      response.needsExtraStep = true
    }

    if (!persona.emailAddress || persona.emailAddress.length === 0) {
      if(!persona.customerId || this.parametro.emailAlternativo['emailAlternativo'] === 'true') {
        response.emailAddress.push({
          orderNumber: '1',
          useCode: '',
          prefix: '',
          emailAddress: this.clientFormData['email'],
          extensionValue: '',
          processingDate: '',
          processingHour: ''
        })
      }
    }

    if (!persona.phoneInformation || persona.phoneInformation.length === 0) {
      const phone = {
        orderNumber: "1",
        contactStatus: "",
        secondaryId: "",
        useCode: "C",
        prefix: "051",
        phoneNumber: this.clientFormData['celular'],
        extensionNumber: "",
        processingDate: "",
        processingHour: ""
      }
      if (!persona.customerId) {
        response.phoneInformation.unshift(phone)
      } else {
        response.phoneInformation.push(phone)
      }
    
    }

    if (this._personalData) {
      response.personalData = this._personalData
      
    }

    if (this.extraAddresses.length > 0) {
      response.postalAddress = [
        ...response.postalAddress,
        ...this.extraAddresses
      ]
    }

    response.postalAddress = response.postalAddress ? response.postalAddress.filter(pa => {
      return !!pa.standardizedPostalAddress
    }) : []

    
    this._currentPerson = response

  }

  public async init () {

    // const queryParams = this.getQueryParams();
    const pepperUrlCombination = this.util.getPepperUrlCombination()

    let associateName = environment.services.pepperBackend.getEndpointAttr('contentHub', 'defaultAssociateName');
    
    if (typeof pepperUrlCombination.htienda === 'string') {
      associateName = pepperUrlCombination.htienda
    }

    const contentData$ = this.fetchData(associateName);
    this.contentData = await firstValueFrom(contentData$);

    if (pepperUrlCombination.hsede === null || ['online', 'fisico', 'cardless', 'recompra'].indexOf(pepperUrlCombination.hsede ?? '') === -1) {
      throw new InvalidFlowNameError(pepperUrlCombination.hsede ?? 'NULL')
    }

    if (pepperUrlCombination.hexp === null || (typeof pepperUrlCombination.hexp === 'string' && pepperUrlCombination.hexp !== 'multi' && pepperUrlCombination.hexp !== 'validar')) {
      throw new InvalidFlowNameError(pepperUrlCombination.hexp ?? 'NULL')
    }

    this.selectedFlow = pepperUrlCombination.hsede
    this.selectedStore = associateName
    this.selectedExp = pepperUrlCombination.hexp

    this.initializing = false;
  }

  public getEmailAlternativo() {
    return this.emailAlternativo;
  }

  public setEmailAlternativo(email: string){
    this.emailAlternativo = email;
  }

  public getValidacionBiometria() {
    return this.validacionBiometria;
  }

  public setValidacionBiometria(validacionBiometria: boolean) {
    this.validacionBiometria = validacionBiometria;
  }

  public getContentData () {
    return this.contentData;
  }

  public getSelectedStore() {
    return this.selectedStore;
  }

  public getSelectedFlow () {
    return this.selectedFlow?.toLowerCase();
  }

  public getSelectedExperience() {
    return this.selectedExp;
  }

  public validateCampaign() {
    return this.selectedExp === 'validar';
  }
  
  public getSelectedCard () {
    return this.selectedCard
  }

  public getSelectedCardData () {
    return this.selectedCardData
  }

  public setSelectedCard (card: any, data?: any) {
    this.selectedCard = card
    this.selectedCardData = data ? data : null
    this.updateCurrentPerson()
  }

  public setCurrentPerson () {
    this.updateCurrentPerson()
  }

  public setClientFormData (clientFormData: Record<string, string>) {
    this.clientFormData = clientFormData
    this.updateCurrentPerson()
  }

  public getClientFormData () {
    return this.clientFormData
  }

  public getDniPepperDebito() {
    return this.dniPepperDebito;
  }

  public setDniPepperDebito(dni: string | undefined) {
    this.dniPepperDebito = dni;
  }

  private fetchData (associateName: string): Observable<ContentHubResponse> {
    const options = {
      params: new HttpParams().set('associateName', associateName),
      headers: new HttpHeaders().set(environment.subscriptionApiHeader.key, environment.subscriptionApiHeader.value)
    }
    
    return this.http.get<ContentHubResponse>(environment.services.pepperBackend.getEndpointUrl('contentHub'), options)
      .pipe(
        catchError(this.handleError)
      );
  }

  

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      return throwError(() => new ContentHubBadRequestError());
    } else if (error.status === 400) {
      if (error.error === null) {
        return throwError(() => new ContentHubConnectionError());
      } else {
        return throwError(() => new ContentHubAssociateDataNotFoundError(error.error['errorMessage']));
      }
    } else {
      return throwError(() => new Error('Ocurrio un error inesperado'));
    }
  }

  public setPersonalData (data: {
    birthday: string,
    genre: string
  }) {
    this._personalData = data

    this.updateCurrentPerson()
  }
  public addAddress(data: {
    department: string,
    province: string,
    district: string,
    via: string,
    address: string,
    number: string,
    ubigeo: string,
    mz ? : string,
    lt ? : string,
    int ? : string
  }) {

    this.extraAddresses.push({
      orderNumber: "1",
      addressStatus: "",
      addressType: "",
      useCode: "PRINCI",
      standardizedAddressFlag: "S",
      standardizedPostalAddress: {
        streetType: data.via,
        streetName: data.address,
        streetNumber: data.number,
        blockIdentification: data.mz ? data.mz : '',
        lotNumber: data.lt ? data.lt : '',
        interiorNumber: data.int ? data.int : '',
        urbanizationNumber: "",
        referenceData: ""
      },
      streetName: data.address,
      deparmentCode: data.department,
      provinceCode: data.province,
      districtCode: data.district,
      geographicalLocation: data.ubigeo,
      postCode: "",
      country: "PERU",
      processingDate: "",
      processingHour: "",
      sequenceCode: ""
    })

    this.updateCurrentPerson()
  }

  public obtenerCorreoPersonal() {

    if (!this._currentPerson) throw new Error('')

    const listaCorreos = this._currentPerson?.emailAddress;
    if( listaCorreos!.length <= 1 ) return listaCorreos[0] ? listaCorreos[0].emailAddress : '';

    const emaper = listaCorreos?.find( email => email.useCode === 'EMAPER');
    return emaper!.emailAddress;
  }

}
