<div class="tc-lista-screen">
    <div class="container">
        <img src="../../../assets/icon/tc-exito.svg" alt="Éxito" class="center-block">
        <h3 class="heading-3 color-blue weight-medium">¡Listo, ya puedes realizar tu compra!</h3>
        <p class="text-1">Disfruta de tu <strong>{{ partnerBenefit }}</strong> para tu primera compra en <strong>{{ partnerName }}.</strong></p>
        <p class="text-1">Para realizar tu compra deberás brindarle al vendedor el <strong>código</strong> que te hemos enviado <strong>vía EMAIL a tu correo {{ email | lowercase | obfuscate:'emailForMessage' }}.</strong></p>
        <div class="notice warning">
            Considera que tu código de compra tiene una vigencia de máxima de 1 hora.
        </div>
        <p class="text-1">Recuerda que para tu primera compra no necesitas tu tarjeta física.</p>
        <hr class="separator">
        <p class="text-2 text-center">En los próximos días te contactaremos para la entrega de tu tarjeta física.</p>
        <p class="text-2 text-center">Si deseas realizar tu compra en otro momento, solicita tu nuevo código <a href="{{getUrl}}" class="color-green no-text-decoration"><strong>aquí</strong></a></p>
        <div class="notice info" *ngIf="outOfZone">Tu dirección no se encuentra en nuestro rango de cobertura para la entrega de tu tarjeta de crédito. No te preocupes, nosotros nos comunicaremos contigo para coordinar la entrega.</div>

        <!-- <div class="promotion-ticket center-block">
            <p class="title text-2">Usa tu cupón de descuento:</p>
            <p class="coupon text-1 weight-medium2">TELETICKET-20%-INTERBANK</p>
        </div> -->
    </div>
</div>
