<div class="ibk-footer">
    <div class="container">
        <section>
            <a href="#" target="_blank">
                <img src="../../assets/icon/librereclamos.svg" alt="Libro de reclamaciones">
            </a>
    
            <a href="#" target="_blank">
                <img src="../../assets/icon/compromiso.svg" alt="Hablemos mas simple">
            </a>
        </section>
        <p>© Copyright 2024 - Todos los derechos reservados</p>
    </div>
</div>
