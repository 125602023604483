<div class="page-error-horario">
    <div class="container">
        <img src="../../../assets/icon/advertencia.svg" alt="Error">
        <p class="text-1">
            <span class="title">Lo sentimos,</span> en este momento no podemos atender tu solicitud.
        </p>
        <p class="text-1">
            Nuestro <strong>horario de atención es de {{ from }} a {{ to }},</strong> todos los días de la semana.
        </p>
    </div>
</div>