import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private http: HttpClient
  ) {}

  private ip: string | undefined;
  async getCurrentIP (url: string): Promise<string> {
    if (typeof this.ip === 'string') return this.ip

    const ip$ = this.http.get(url, {responseType: 'text'})
    const ip = await firstValueFrom(ip$);
    this.ip = ip + ''
    return ip + '';
  }

  get ipLocal() {
    return this.ip + '';
  }

  encryptDocument(document: string): string {
    const key = 'v*+)y7%k^^-ch-0d7mh@wjl!i6yby8=rl2jmxmq%ct-xwv7@ox';
    const dataToHash = document + key;

    // Calcula el hash SHA-256 y convierte el resultado en may�sculas
    const hash = CryptoJS.SHA256(dataToHash).toString().toUpperCase();

    return hash;
  }

  public getQueryParams (): Record<string, string> {
    const htienda = window.sessionStorage.getItem('htienda')
    const hsede = window.sessionStorage.getItem('hsede')

    const urlSearchParams = new URLSearchParams(window.location.search);
    const query = Object.fromEntries(urlSearchParams.entries());
    return query
  }

  private pepperUrlCombination!: {hsede: string | null, htienda: string | null, hexp: string | null | undefined}
  public getPepperUrlCombination (): {hsede: string | null, htienda: string | null, hexp: string | null | undefined} {

    if (!this.pepperUrlCombination) {
      let htienda;
      let hsede;
      let hexp;
  
      const query = this.getQueryParams()
  
      if (!query['htienda'] && !query['hsede']) {
        htienda = window.sessionStorage.getItem('htienda')
        hsede = window.sessionStorage.getItem('hsede')
      } else {
        htienda = query['htienda']
        hsede = query['hsede']
        hexp = query['hexp']
  
        window.sessionStorage.setItem('htienda', htienda)
        window.sessionStorage.setItem('hsede', hsede)
      }
  
      this.pepperUrlCombination = {
        htienda,
        hsede,
        hexp
      }
    }

    return this.pepperUrlCombination
  }

  public parseNumberVia(number: string) {

    if(typeof number!='undefined' && number){
      const numberParsed = number.replace(/[^0-9]/g,'');
      return (numberParsed.length === 0)? 0: numberParsed;
    }

    return 0;
  }
// quitar tildes
  public removeDiacritics(textDiacritic: string) {
    return textDiacritic.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
}


  public removeSpecialCharacters(textCharacters: string) {
    var cleansedOutput = this.removeDiacritics(textCharacters);
    var safeReplaceChar = ' ';

    // Get rid of the special characters.
    cleansedOutput = cleansedOutput.trim().replace(/[^\w\d]/gi, safeReplaceChar);

    // Get rid of multiple instances of our special character.
    cleansedOutput = cleansedOutput.replace(/-{2,}/g, safeReplaceChar);

    // Make sure the first character is not the safe char (i.e. is alphanumeric)
    if (cleansedOutput.charAt(0) == safeReplaceChar) {
        cleansedOutput = (cleansedOutput.substring(1, cleansedOutput.length));
    }

    // Make sure the last character is not the safe char (i.e. is alphanumeric)
    if (cleansedOutput.charAt(cleansedOutput.length - 1) == safeReplaceChar) {
        cleansedOutput = (cleansedOutput.substring(0, cleansedOutput.length - 1));
    }

    // Return the cleansed and formatted data.
    return cleansedOutput.toString().toLowerCase();
}




  
  public detectBrowser(){

  var browser= "Unknown";
  var device= "Unknown";
  
        var chrome = /chrome/.test(navigator.userAgent.toLowerCase());
        var safari = /safari/.test(navigator.userAgent.toLowerCase());
          var firefox = /firefox/.test(navigator.userAgent.toLowerCase());
          var opera = /opr/.test(navigator.userAgent.toLowerCase());
          var msie = /msie/.test(navigator.userAgent.toLowerCase()) || 
          /trident/.test(navigator.userAgent.toLowerCase()) || /.net/.test(navigator.userAgent.toLowerCase());
          var edge = /edge/.test(navigator.userAgent.toLowerCase());
          var yandex = /yabrowser/.test(navigator.userAgent.toLowerCase());
          
          /* Detect Yandex */
          if(yandex){
              browser = "Yandex"
              safari = false;
              chrome = false;
          }
          
          /* Detect Opera */
          if(opera){
              browser = "Opera"
              safari = false;
              chrome = false;
          }
          
          /* Detect Edge */
          if(edge){
              browser = "Edge"
              safari = false;
              chrome = false;
          }
          
          /* Detect Chrome */
          if(chrome){
            browser = "Chrome"
              /* Finally, if it is Chrome then jQuery thinks it's 
                 Safari so we have to tell it isn't */
              safari = false;
          }
  
          /* Detect Safari */
          if(safari){
              browser = "Safari"
          }
          
           /* Detect Firefox */
          if(firefox){
              browser = "Firefox"
          }
          
          
          
          /* Detect Internet Explorer */
          if(msie){
              browser = "IExplorer"
          }
          
        var windows = /win/.test(navigator.platform.toLowerCase());
        var mac = /mac/.test(navigator.platform.toLowerCase());
        var linux = /linux/.test(navigator.platform.toLowerCase());
        var iphone = /iphone/.test(navigator.platform.toLowerCase());
        var android = /android/.test(navigator.platform.toLowerCase());
        
        if(windows) {
          device = "Windows";
        }else if(mac) {
          device = "Mac";
        }else if(linux) {
          device = "Linux";
        } else if(iphone) {
          device = "Iphone";
        }else if (android) {
          device = "Android";
        }
        
  
  if(navigator.userAgent.match('CriOS')){
    device = "chrome-IOS";
  }
        
        return device.concat("-").concat(browser);  
       
  }
  
}
