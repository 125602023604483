import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { NgxMaskModule, IConfig } from 'ngx-mask'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IbkHeaderComponent } from './ibk-header/ibk-header.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingScreenComponent } from './screens/loading-screen/loading-screen.component';
import { NavigateService } from './services/navigate/navigate.service';
import { HomeComponent } from './screens/home/home.component';
import { ErrorHorarioComponent } from './screens/error-horario/error-horario.component';
import { SeleccionTarjetaComponent } from './screens/seleccion-tarjeta/seleccion-tarjeta.component';
import { ErrorServicioComponent } from './screens/error-servicio/error-servicio.component';
import { UsuarioSinCampanaComponent } from './screens/usuario-sin-campana/usuario-sin-campana.component';
import { StepIndicatorComponent } from './components/step-indicator/step-indicator.component';
import { StickyNoticeBarComponent } from './components/sticky-notice-bar/sticky-notice-bar.component';
import { ValidarIdentidadComponent } from './screens/validar-identidad/validar-identidad.component';
import { VerificacionFacialComponent } from './screens/verificacion-facial/verificacion-facial.component';
import { OtpSendComponent } from './screens/otp/otp-send.component';
import { InputRadioComponent } from './components/input-radio/input-radio.component';
import { InputCheckboxComponent } from './components/input-checkbox/input-checkbox.component';
import { OtpValidarComponent } from './screens/otp-validar/otp-validar.component';
import { DatosPersonalesComponent } from './screens/datos-personales/datos-personales.component';
import { ConfigurarTcComponent } from './screens/configurar-tc/configurar-tc.component';
import { InputRangeComponent } from './components/input-range/input-range.component';
import { IbkFooterComponent } from './ibk-footer/ibk-footer.component';
import { BiometriaMainComponent } from './screens/biometria/biometria-main/biometria-main.component';
import { BiometriaSuccessComponent } from './screens/biometria/biometria-success/biometria-success.component';
import { BiometriaRetryComponent } from './screens/biometria/biometria-retry/biometria-retry.component';
import { BiometriaLoadingComponent } from './screens/biometria/biometria-loading/biometria-loading.component';
import { BiometriaErrorComponent } from './screens/biometria/biometria-error/biometria-error.component';
import { BiometriaTimeoutComponent } from './screens/biometria/biometria-timeout/biometria-timeout.component';
import { TcListaComponent } from './screens/tc-lista/tc-lista.component';
import { DatosTarjetaComponent } from './screens/datos-tarjeta/datos-tarjeta.component';
import { PepperModalComponent } from './components/pepper-modal/pepper-modal.component';
import { TcFrontComponent } from './components/tc-front/tc-front.component';
import { TcBackComponent } from './components/tc-back/tc-back.component';
import { TcTimerComponent } from './components/tc-timer/tc-timer.component';
import { RecibeNuevoCodigoComponent } from './screens/recibe-nuevo-codigo/recibe-nuevo-codigo.component';
import { EnviadoNuevoCodigoComponent } from './screens/enviado-nuevo-codigo/enviado-nuevo-codigo.component';
import { PepperService } from './services/pepper/pepper.service';
import { ErrorLoadingComponent } from './screens/error-loading/error-loading.component';
import { ParametrosService } from './services/parametros/parametros.service';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from "ng-recaptcha";
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { ApiAutenticacionService } from './services/api-autenticacion/api-autenticacion.service';
import { ApiOfertasService } from './services/api-ofertas/api-ofertas.service';
import { ApiPepperBackendService } from './services/api-pepper-backend/api-pepper-backend.service';
import { SafePipe } from './pipes/safe.pipe';
import { ObfuscatePipe } from './pipes/obfuscate.pipe';
import { UcwordsPipe } from './pipes/ucwords.pipe';
import { FormControlErrorPipe } from './pipes/form-control-error.pipe';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ApiCalificacionService } from './services/api-calificacion/api-calificacion.service';
import { ApiAltaService } from './services/api-alta/api-alta.service';
import { UtilsService } from './services/utils/utils.service';
import { ReportesService } from './services/reportes/reportes.service';
import { AntifraudeService } from './services/antifraude/antifraude.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { ErrorDatosIncompletosComponent } from './screens/error-screens/datos-incompletos/datos-incompletos.component';
import { ErrorOfertasComponent } from './screens/error-screens/error-ofertas/error-ofertas.component';
import { OfertasFalloDefinitivoComponent } from './screens/error-screens/error-ofertas/ofertas-fallo-definitivo/ofertas-fallo-definitivo.component';
import { ExpedienteOtroCanalComponent } from './screens/error-screens/expediente-otro-canal/expediente-otro-canal.component';
import { ValidationScreenComponent } from './screens/error-screens/validation-screen/validation-screen.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LoginComponent } from './screens/pepper-debito/login/login.component';
import { TipoTarjetaComponent } from './screens/pepper-debito/tipo-tarjeta/tipo-tarjeta.component';
import { PoliticaPrivacidadComponent } from './components/politica-privacidad/politica-privacidad.component';


export const options: Partial<IConfig> | (() => Partial<IConfig>) | null = null;

@NgModule({
  declarations: [
    AppComponent,
    IbkHeaderComponent,
    LoadingScreenComponent,
    HomeComponent,
    ErrorHorarioComponent,
    SeleccionTarjetaComponent,
    ErrorServicioComponent,
    //PANTALLAS DE ERROR
    ErrorDatosIncompletosComponent,
    ErrorOfertasComponent,
    OfertasFalloDefinitivoComponent,
    ExpedienteOtroCanalComponent,
    ValidationScreenComponent,
    //PANTALLAS DE PEPPER DEBITO
    LoginComponent,
    TipoTarjetaComponent,
    
    UsuarioSinCampanaComponent,
    StepIndicatorComponent,
    StickyNoticeBarComponent,
    ValidarIdentidadComponent,
    VerificacionFacialComponent,
    OtpSendComponent,
    InputRadioComponent,
    InputCheckboxComponent,
    OtpValidarComponent,
    DatosPersonalesComponent,
    ConfigurarTcComponent,
    InputRangeComponent,
    IbkFooterComponent,
    BiometriaMainComponent,
    BiometriaSuccessComponent,
    BiometriaRetryComponent,
    BiometriaLoadingComponent,
    BiometriaErrorComponent,
    BiometriaTimeoutComponent,
    TcListaComponent,
    DatosTarjetaComponent,
    PepperModalComponent,
    TcFrontComponent,
    TcBackComponent,
    TcTimerComponent,
    PoliticaPrivacidadComponent,
    RecibeNuevoCodigoComponent,
    EnviadoNuevoCodigoComponent,
    ErrorLoadingComponent,
    SafePipe,
    ObfuscatePipe,
    UcwordsPipe,
    FormControlErrorPipe
  ],
  imports: [
    NgxMaskModule.forRoot({thousandSeparator: ','}),
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgScrollbarModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-PZMT7DL',
    })
  ],
  providers: [
    NavigateService,
    PepperService,
    ParametrosService,
    ApiAutenticacionService,
    ApiOfertasService,
    ApiPepperBackendService,
    ApiCalificacionService,
    ApiAltaService,
    UtilsService,
    ReportesService,
    AntifraudeService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {siteKey: environment.recaptcha.siteKey } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "es"
    },
    // {
    //   provide: 'googleTagManagerId',
    //   useValue: 'GTM-PZMT7DL'
    // }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
