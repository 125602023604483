<div *ngIf="!load" class="page-error-ofertas">
    <div class="container">
        <img [src]="imagen" alt="Error">
        <p class="text-1">
            <span class="title">Lo sentimos,</span>
        </p>
        <p class="text-1">
            {{ message }}
        </p>
        
        <button  class="btn center-block fw--mobile" (click)="handleActionButtonClick()">{{ boton }}</button>
    </div>
</div>
<section *ngIf="load">
    <app-validation-screen></app-validation-screen>
</section>