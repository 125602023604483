import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biometria-main',
  templateUrl: './biometria-main.component.html',
  styleUrls: ['./biometria-main.component.scss']
})
export class BiometriaMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
