import { Component, AfterViewInit, Input, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pepper-modal',
  templateUrl: './pepper-modal.component.html',
  styleUrls: ['./pepper-modal.component.scss']
})

export class PepperModalComponent implements AfterViewInit {
  @ViewChild('modalRoot') modalRoot: any;

  @Input('active') activeAttr: boolean = false
  
  @Input('actionButton') actionButton: boolean = false
  @Input('actionButtonLabel') actionButtonLabel: string = 'Aceptar'
  @Input('input') id: string | undefined;
  
  @Output('close') closeEvent = new EventEmitter<void>();
  @Output('overlay') clickOverlayEvent = new EventEmitter<void>();
  @Output('actionButton') clickActionButtonEvent = new EventEmitter<void>();


  active: boolean = false;
  closing: boolean = false;
  opening: boolean = false;

  readonly animationDelay = 300

  constructor() {}

  handleClickOverlay(e: Event) {
    e.preventDefault()
    e.stopPropagation();
    this.clickOverlayEvent.emit()
  }
  handleClickClose(e: Event) {
    e.preventDefault()
    e.stopPropagation();
    this.closeEvent.emit()
  }

  private closeModal () {
    this.closing = true
  
    setTimeout (() => {
      this.active = false
      this.closing = false
    }, this.animationDelay)
  }

  private openModal () {
    this.opening = true
    this.active = true
  
    setTimeout (() => {
      this.opening = false;
    }, this.animationDelay)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activeAttr'].currentValue) {
      this.openModal()
    } else {
      this.closeModal()
    }
  }

  ngAfterViewInit(): void {
  }

  handleActionButtonClick (e: Event) {
    e.preventDefault()
    e.stopPropagation()
    this.clickActionButtonEvent.emit()
  }

}
