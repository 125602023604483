import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InternalService } from 'src/app/common/InternalService';
import { environment } from 'src/environments/environment';
import { ParametrosService } from '../parametros/parametros.service';
import { PepperService } from '../pepper/pepper.service';
import { FingerprintService } from '../fingerprint/fingerprint.service';
import { UtilsService } from '../utils/utils.service';

declare const MPFingerprint: any;

@Injectable({
  providedIn: 'root'
})
export class ApiAltaService extends InternalService {

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient,
    private pepper: PepperService,
    private util: UtilsService,
    private fingerprintService: FingerprintService
  ) {
    super(parametros, http, environment.services.pepper);
  }

  private altaResponse: any

  private creditLine: string = '0';

  get cardData () {
    return this.altaResponse
  }

  get credito () {
    return this.creditLine;
  }

  public async registerAlta (data: {
    comprasIntExt: boolean
    dispEfectivo: boolean
    sobregiro: boolean

    grabarLDPD: boolean

    horaFin: string
    codigoDepartamento: string
    codigoDistrito: string
    direccion: string
    fechaEntrega: string
    codigoProvincia: string
    email: string
    horaInicio: string
    numeroTelefono: string

    apellidoPaterno: string
    segundoNombre: string
    nombreVia: string
    fechaNacimiento: string
    nombreUrbanizacion: string
    idManzana: string
    provincia: string
    codigoUnico?: string
    apellidoMaterno: string
    numeroCalle: string
    numeroDocumento: string
    idInterior: string
    distrito: string
    codigoUbigeo: string
    primerNombre: string
    tipoVia: string
    departamento: string
    sexo: string
    idLote: string

    idCampania: string

    importeLinea: string
    importeLineaPepper: string
    codigoMarca: string
    indicadorTipo: string,
    indicadorTipoCliente: string,
    diaPago: string
    codigoVendedor: string
    cardBrand: string
    cardType: string,
    ip: string,
    idExpediente: string,
    revolvingRateOffered: string,
    indicadorDevolucionCashback: string,
    codigoConvenio: string
  }) {

    const endpointName = this.getEndpointName()
    const pepperUrlCombination = this.util.getPepperUrlCombination()
    let fingerprint = await MPFingerprint.getData(true, true);
    const correoExitente = this.pepper.currentPerson().emailAddress.find((item) => item.emailAddress === this.pepper.getEmailAlternativo())
    const homForm = this.pepper.getClientFormData();

    const payload: any= {
      serviciosAsociados: {
        comprasIntExt: data.comprasIntExt ? 'S' : 'N',
        dispEfectivo: data.dispEfectivo ? 'S' : 'N',
        sobregiro: data.sobregiro ? 'S' : 'N'
      },
      informacionEntrega: {
          tipoDireccion: "2",
          horaFin: data.horaFin,
          codigoDepartamento: data.codigoDepartamento,
          codigoDistrito: data.codigoDistrito,
          direccion: data.direccion,
          fechaEntrega: data.fechaEntrega,
          codigoProvincia: data.codigoProvincia,
          emailAlternativo: correoExitente ? '' : this.pepper.getEmailAlternativo(),
          horaInicio: data.horaInicio,
          numeroTelefono: data.numeroTelefono,
          referencia: ''
      },
      grabarLPD: {
        tipoConsentimiento: "01",
        flagCliente: "1",
        flagLPD: data.grabarLDPD ? 'S' : 'N'
      },
      datosCliente: {
          apellidoPaterno: data.apellidoPaterno,
          segundoNombre: data.segundoNombre,
          referenciaUbicacion: "",
          nombreVia: data.nombreVia,
          fechaNacimiento: data.fechaNacimiento,
          codigoPaisNacimiento: "4028",
          nombreUrbanizacion: data.nombreUrbanizacion,
          codigoOcupacion: null,
          codigoSituacionLaboral: "3",
          codigoPaisResidencia: "4028",
          idManzana: data.idManzana,
          provincia: data.provincia,
          apellidoMaterno: data.apellidoMaterno,
          numeroCalle: data.numeroCalle,
          codigoPaisNacionalidad: "4028",
          numeroDocumento: data.numeroDocumento,
          tipoNacionalidad: '0',
          email: correoExitente ? this.pepper.getEmailAlternativo() : data.email,
          idInterior: data.idInterior,
          numeroTelefono: data.numeroTelefono,
          telefonoFormulario: homForm!['celular'],
          distrito: data.distrito,
          codigoUbigeo: data.codigoUbigeo,
          primerNombre: data.primerNombre,
          tipoVia: data.tipoVia,
          estadoCivil: "U",
          tipoDocumento: "01",
          departamento: data.departamento,
          sexo: data.sexo,
          idLote: data.idLote,
          maritalStatus: "SOLTERO",
          occupation: "OTRO",
          nombreEmpresa: null,
      },
      datosExpediente: {
          codigoPromotor: "BUSUSMDA",
          codigoPuntoVenta: "898",
          idExpediente: data.idExpediente,
          codigoCanal: "113",
          codigoTienda: "898",
          revolvingRateOffered: data.revolvingRateOffered,
      },
      datosCampania: {
          idCampania: data.idCampania
      },
      altaTCProvisional: {
          codigoMoneda: "1",
          importeLinea: '' + data.importeLinea,
          codigoCorrespondencia: "EMAPER",
          importeLineaPepper: '' + data.importeLineaPepper,
          codigoMarca: data.codigoMarca,
          codigoConvenio: data.codigoConvenio,
          indicadorTipo: data.indicadorTipo,
          diaPago: data.diaPago,
          indicadorTipoCliente: data.indicadorTipoCliente,
          indicadorDevolucionCashback: data.indicadorDevolucionCashback,
          codigoVendedor: data.codigoVendedor,
          currencyCode: "PEN",
          cardBrand: data.cardBrand,
          cardType: data.cardType,
          tienda: pepperUrlCombination.htienda
      },
      datosMonitor: {
          dispositivo: "1",
          ip: data.ip,
          sdk: JSON.stringify(await this.fingerprintService.sendFingerprint(fingerprint))
      }
    }

    if (environment.mode === 'dev') {
      payload.altaTCProvisional.diaPago = '5'
    }

    if (this.pepper.getSelectedFlow() === 'fisico') {
      const cfData = this.pepper.getClientFormData()
      if (cfData === undefined) throw new Error('No client form data available')
      payload.datosToken = {
          operador: cfData['operador']
      }
    } else {
      const parameterLine = this.parametros.lineaParametro;
      if (data.codigoUnico) {
        this.creditLine = parameterLine['TopeLineaCliente'] > data.importeLineaPepper ? data.importeLineaPepper : parameterLine['TopeLineaCliente'];
      } else {
        this.creditLine = parameterLine['TopeLineaNoCliente'] > data.importeLineaPepper ? data.importeLineaPepper : parameterLine['TopeLineaNoCliente'];
      }
    }

    if (data.codigoUnico){
      payload.datosCliente.codigoUnico = data.codigoUnico
    } else { 
      if(this.pepper.getEmailAlternativo()){
        payload.datosCliente.email = this.pepper.getEmailAlternativo()
      } else {
        payload.datosCliente.email = data.email
      }
      payload.informacionEntrega.emailAlternativo = ''
    }


    this.altaResponse = await this.doRequestToEndpoint(endpointName, {
      body: payload
    })
  }

  private getEndpointName () {
    switch (this.pepper.getSelectedFlow()) {
      case 'online': return 'alta_online'
      case 'fisico': return 'alta_classic'
      case 'cardless': return 'alta_cardless'
      default: return ''
    }
  }
}
