<div class="ibk-modal" [class.closing]="closing" [class.opening]="opening" *ngIf="active" #modalRoot>
    <div class="overlay" (click)="handleClickOverlay($event)"></div>
    <div class="container">
        <button class="close" (click)="handleClickClose($event)" id="close_modal"></button>
        <ng-scrollbar barClass="scrollbar" viewClass="pr-125" visibility="always">
            <ng-content></ng-content>
        </ng-scrollbar>
        <div *ngIf="actionButton">
            <button style="padding-left: 4rem; padding-right: 4rem; margin-top: 1.4rem;" class="btn fw--mobile center-block" (click)="handleActionButtonClick($event)">{{ actionButtonLabel }}</button>
        </div>
    </div>
</div>