<section class="recibe-codigo-main">
    <aside>
        <div class="wrapper">
            <h2 class="heading-2">Solicita un <strong>nuevo código y realiza tu primera compra</strong> con tu nueva tarjeta de crédito Interbank</h2>
        </div>
    </aside>
    <section>
        
        <form id="foemRecompra" *ngIf="recompraOTPForm !== undefined" [formGroup]="recompraOTPForm" #formDir="ngForm" (ngSubmit)="handleSubmit()" class="form">
            <h3 class="heading-3 weight-light">Ingresa tus datos y recibe tu nuevo código</h3>
            <label>
                <span>Número de DNI</span>
                <input class="input" type="text" mask="00000000" formControlName="dni" id="inputDni" [readOnly]="loading">
                <span class="error-msj">{{recompraOTPForm.controls['dni'].errors | formControlErrorMessage:'número de documento'}}</span>
            </label>
            <label>
                <span>E-mail</span>
                <input class="input" type="email" formControlName="email" id="inputEmail" [readOnly]="loading">
                <span class="error-msj">{{recompraOTPForm.controls['email'].errors | formControlErrorMessage:'email'}}</span>
            </label>
            <div class="i-group">
                <label>
                    <span>Operador</span>
                    <ng-select  class="input" formControlName="operador" id="inputOperador" placeholder="Selecciona ..." [clearable]="false">
                        <ng-option *ngFor="let operador of operators | keyvalue" [value]="operador.key">{{operador.value}}</ng-option>
                    </ng-select>
                    <span class="error-msj">{{ recompraOTPForm.controls['operador'].errors | formControlErrorMessage:'':{'required': 'Selecciona tu operador telefónico'} }}</span>
                </label>
                <label>
                    <span>Número de celular</span>
                    <input class="input" type="tel" mask="000000000" [readOnly]="loading" formControlName="celular" id="inputCelular">
                    <span class="error-msj">{{recompraOTPForm.controls['celular'].errors | formControlErrorMessage:'número de celular':{'notLength': 'El celular debe contar con 9 caracteres'} }}</span>
                </label>
            </div>
            <div class="row">
                <label class="checkbox mb-075 checkbox-label">
                    <input type="checkbox" formControlName="autorizacion" id="inputAutorizacion">
                    <span class="checkbox"></span>
                    <p>Autorizo a Interbank <a  (click)="openPersonalDataModal($event)" class="no-text-decoration color-green weight-medium2" href="#" id="triggerPersonalDataModal">el tratamiento de Datos Personales</a>.</p>
                </label>
            </div>
            
            <div class="row mb-125" *ngIf="recaptcha.enabled" id="recaptchaWrapper">
                <re-captcha [siteKey]="recaptcha.key" (resolved)="handleResolvedCaptcha($event)"></re-captcha>
                <p class="text-3 mt-050">Este sitio esta protegido por reCAPTCHA. Aplican la <a class="color-green no-text-decoration" href="https://policies.google.com/privacy?hl=es" rel="nofollow" target="_blank">Política de Privacidad</a> de Google y los <a class="color-green no-text-decoration" href="#" id="triggerTycDataModal" (click)="openTycDataModal($event)">Términos del Servicio.</a></p>
                <div class="notice error mt-050" *ngIf="isRecaptchaTouched && !isRecaptchaValid">No se validó el recaptcha con éxito.</div>
            </div>

            <button type="submit" class="btn fw--mobile" [disabled]="loading || (!recompraOTPForm.valid || !isRecaptchaValid)">{{ loading ? 'Cargando ...' : 'Comencemos' }}</button>
        </form>
    </section>
</section>

<pepper-modal class="modal-type" [active]="personalDataModal" (close)="handlePersonalDataCloseModal()" (overlay)="handlePersonalDataCloseModal()" [actionButton]="true" actionButtonLabel="Entiendo" (actionButton)="handlePersonalDataCloseModal()">
    <div class="container page-modal" [innerHTML]="contentPoliticaDatoPersonal | safe:'html'"></div>
</pepper-modal>

<pepper-modal class="modal-type" [active]="tycDataModal" (close)="handleTycDataCloseModal()" (overlay)="handleTycDataCloseModal()" [actionButton]="true" actionButtonLabel="Entiendo" (actionButton)="handleTycDataCloseModal()">
    <div class="container page-modal" [innerHTML]="contentTerminosyCondiciones | safe:'html'"></div>
</pepper-modal>