export class BypassToCalificacionError extends Error {
    public selectedOffer: any;
    public qualifiedProposal: any;
    public expedientNumber: string;

    constructor (expedientNumber: string, selectedOffer: any, qualifiedProposal: any) {
        super('Client has reentry logic')
        
        this.expedientNumber = expedientNumber
        this.selectedOffer = selectedOffer;
        this.qualifiedProposal = qualifiedProposal;
    }
}