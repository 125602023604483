import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpError } from '@microsoft/signalr';
import { InternalService } from 'src/app/common/InternalService';
import { environment } from 'src/environments/environment';
import { ParametrosService } from '../parametros/parametros.service';

@Injectable({
  providedIn: 'root'
})
export class AntifraudeService extends InternalService{

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient
  ) {
    super(parametros, http, environment.services.antifraud)
  }

  public async isSuspiciousRequest (ip: string, phone: string, email: string, document: string) {
    try {
      await this.doRequestToEndpoint('risk', {
        body: {
          "businessFlow": "TC",
          "parameters": [{
            "name": "ip",
            "value": ip
          }, {
            "name": "phone",
            "value": phone
          }, {
            "name": "email",
            "value": email
          }]
        },
        template: {
          documentNumber: document
        }
      })
      return false
    } catch (e) {
      console.error(e)
      if (e instanceof HttpErrorResponse && e.error.httpCode === 403) {
        return true
      }
      throw new Error('Unexpected error calling antifraude')
    } 
  }
}
