import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InternalService } from 'src/app/common/InternalService';
import { environment } from 'src/environments/environment';
import { ParametrosService } from '../parametros/parametros.service';

@Injectable({
  providedIn: 'root'
})
export class CalificacionPepperAssiService extends InternalService {

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient
  ) {
    super(parametros, http, environment.services.pepper);
  }

  async consultaExpediente (id: string) {
    return this.doRequestToEndpoint<any>('expediente', {
      params: {
        expedientNumber: id
      }
    })
  }
}

