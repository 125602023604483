<div class="page-error-definitivo">
    <div class="container">
        <img [src]="logo" alt="Error">
        <p class="text-1">
            <span class="title">Lo sentimos,</span>
        </p>
        <p class="text-1">
            {{ description }}
        </p>
       <div class="contacto">
        <div class="icon">
            <img src="../../../assets/icon/error/audifonos.svg" alt="">
        </div>
        <div class="text">
            <p>{{ contact }}</p>
        </div>
       </div>
        <p class="text-3">
            <span class="schedule">Lunes a viernes de 9am a 6pm</span>
            <span class="schedule">Sábados de 9am a 1pm</span>
        </p>

        <button class="btn center-block fw--mobile" (click)="reloadApp()">Volver al inicio</button>
    </div>
</div>