import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-biometria-error',
  templateUrl: './biometria-error.component.html',
  styleUrls: ['./biometria-error.component.scss']
})
export class BiometriaErrorComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  title = ''
  message = ''

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      this.title = data['title']
      this.message = data['message']
    })
  }

}
