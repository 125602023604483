<step-indicator [current]="extraStep ? 4 : 3" title="Identifícate"></step-indicator>
<sticky-notice-bar></sticky-notice-bar>
<section class="screen-otp">
  <form class="form container" [formGroup]="otpForm" #formDir="ngForm" (ngSubmit)="handleSubmit()">
    <h3 class="heading-3 text-center--mobile"><strong id="title">Hola {{ persona.nombre | ucwords }},</strong></h3>

    <p *ngIf="!addEmail" class="text-1 text-center--mobile">Te enviaremos un código de verificación para que
      continúes. Elige dónde quisieras recibirlo:</p>
    <p *ngIf="addEmail" class="text-1 text-center--mobile">Agregar el correo en donde quieres recibir el código</p>

    <div *ngIf="!addEmail">
      <label class="radio" *ngFor="let correo of persona.correos">
        <input type="radio" class="available_email" formControlName="contact_otp" [value]="correo">
        <span class="radio"></span>
        <p>Enviar a {{ correo['value'] | lowercase | obfuscate:'email' }}</p>
      </label>
    </div>

    <div *ngIf="addEmail">
      <label class="email">
        <span>Correo electrónico</span>
        <input class="input" type="email" formControlName="email" id="input_email" [readOnly]="loading">
        <span class="error-msj">{{otpForm.controls['email'].errors | formControlErrorMessage:'email'}}</span>
      </label>
    </div>

    <button id="submit_button" class="btn fw--mobile center-block--mobile" [disabled]="loading || !otpForm.valid">{{ addEmail ?
      'Listo' : 'Sigamos' }} </button>

      <p class="correo"><a *ngIf="!addEmail && showOptionEmailAlternativo()" (click)="ingresarCorreo()"
          class="no-text-decoration color-green weight-medium2">¿No reconoces este correo?</a></p>
  </form>
</section>