import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InternalService } from 'src/app/common/InternalService';
import { environment } from 'src/environments/environment';
import { ApiOfertasService } from '../api-ofertas/api-ofertas.service';
import { ParametrosService } from '../parametros/parametros.service';
import { PepperService } from '../pepper/pepper.service';
import { QradarService } from '../qradar/qradar.service';
import { AuthRequest } from './requests/AuthRequest';
import { AuthResponse } from './responses/AuthResponse';
// import { OfertasRequest } from './requests/OfertasRequest';
// import { OfertasResponse } from './responses/OfertasResponse';

@Injectable({
  providedIn: 'root'
})
export class ApiAutenticacionService extends InternalService {

  private authResponse: AuthResponse | undefined;

  private captcha: string = ''
  private isCustomer: string = ''

  private targetEmail: string | undefined
  public formEmail: string | undefined

  private previousAutorizationRequest: Record<string, any> | null = null

  get target() {
    return {
      email: this.targetEmail
    }
  }

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient,
    private pepper: PepperService,
    private qradar: QradarService,
    private ofertas: ApiOfertasService,
  ) {
    super(parametros, http, environment.services.pepper);
  }

  public async regenerateToken() {
    try {
      if (this.previousAutorizationRequest === null) throw new Error('Trying to regenerate authentication token without sending it after');
      this.authResponse = await this.doRequestToEndpoint<AuthResponse>('authenticationAuth', {
        body: this.previousAutorizationRequest
      })

      this.captcha = this.authResponse.captcha
    } catch (error) {
      const err = error as HttpErrorResponse
      if ( err.status && err.status !== 403) {
        const data = {
          parametros: [
            {
              clave: 'DETALLEERROR',
              valor: 'ERROR Authentication - '.concat(err.status ? err.status.toString() : 'interno' ,' - ',err.error ? err.error['errorDetail'] ? err.error['errorDetail']: '' : '')
            }
          ]
        }
        await this.qradar.addToRegister('authenticationAuth', data);
      }
      throw new Error('Error executing Autenticacion API.');
    }

  }

  public async generateToken(email: string): Promise<void> {
    try {
      const homeForm = this.pepper.getClientFormData()
      if (homeForm === undefined) throw new Error();

      const persona = this.ofertas.getPerson()

      enum AuthMethots {
        Email = "0",
        SMS = "1",
        Ambos = "2"
      }

      this.previousAutorizationRequest = {
        documentType: "1",
        documentNumber: homeForm['documentNumber'],
        isCustomer: persona.customerId ? 'S' : 'N',
        telephone: homeForm['celular'],
        email: email,
        operator: homeForm['operador'],
        method: AuthMethots.Email
      }

      this.authResponse = await this.doRequestToEndpoint<AuthResponse>('authenticationAuth', {
        body: this.previousAutorizationRequest
      })

      this.targetEmail = email
      this.isCustomer = persona.customerId ? 'S' : 'N'
      this.captcha = this.authResponse.captcha

      const clienteData = this.pepper.getClientFormData();
      this.formEmail = clienteData? clienteData['email'] : "email-no-ingresado";

    } catch (error) {
      const err = error as HttpErrorResponse
      if ( err.status && err.status !== 403) {
        const data = {
          parametros: [
            {
              clave: 'DETALLEERROR',
              valor: 'ERROR Authentication - '.concat(err.status ? err.status.toString() : 'interno' ,' - ',err.error ? err.error['errorDetail'] ? err.error['errorDetail']: '' : '')
            }
          ]
        }
        await this.qradar.addToRegister('authenticationAuth', data);
      }
      throw new Error('Error executing Autenticacion API.');
    }

  }

  public async marcarIntento(token?: string): Promise<void> {
    // const persona = this.ofertas.getPerson()
    const homeForm = this.pepper.getClientFormData()
    if (homeForm === undefined) throw new Error();

    let serviceName = 'intentosOTPDNI'
    let body = {
      document: homeForm['documentNumber']
    } as Record<string, any>

    if (token) {
      serviceName = 'intentosOTP'
      body['otp'] = token
    }

    await this.doRequestToEndpoint<any>(serviceName, {
      template: body
    })
  }

  public async verificarToken(token: string): Promise<any> {
    try {
      const validationResponse = await this.doRequestToEndpoint<AuthResponse>('authenticationValidate', {
        body: {

          isCustomer: this.isCustomer,
          token: this.captcha,
          key: token,
          operation: null,
          equifax: null
        }
      })

      return validationResponse
    } catch (error) {
      const err = error as HttpErrorResponse
      if ( err.status && err.status !== 403) {
        const data = {
          parametros: [
            {
              clave: 'DETALLEERROR',
              valor: 'ERROR Validacion - '.concat(err.status ? err.status.toString() : 'interno' ,' - ',err.error ? err.error['errorDetail'] ? err.error['errorDetail']: '' : '')
            }
          ]
        }
        await this.qradar.addToRegister('authenticationValidate', data);
      }
      throw new Error('Error executing Autenticacion API.');
    }
  }

}
