import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { BiometriaReachedIntentsLimitError } from 'src/app/errors/BiometriaReachedIntentsLimitError';
import { environment } from 'src/environments/environment';
import { ApiPepperBackendService } from '../api-pepper-backend/api-pepper-backend.service';
import { BiometriaOauthTokenResponse } from '../api-pepper-backend/responses/BiometriaOauthTokenResponse';

type fnHandler = (...args: any[]) => void

@Injectable({
  providedIn: 'root'
})

export class BiometriaService {

  private hubConnection: HubConnection | undefined;
  private oauth: BiometriaOauthTokenResponse | undefined;

  constructor(
    private backend: ApiPepperBackendService,
  ) {}

  private async buildConnection(): Promise<void> {
    if (this.hubConnection !== undefined) throw new Error('Connection with Hub already set')
    
    const oauth = await this.backend.generateBiometriaOauthTokens()
    if (oauth === null) throw new Error('Cannot get Biometria oAuth tokens ')

    const verify = await this.backend.biometriaIntentosFallidos()

    if (verify === null) throw new Error('')
    
    if (verify.enrollmentTrace.block) throw new BiometriaReachedIntentsLimitError()
    

    this.oauth = oauth

    const signalrURI = oauth.signalr.uri
    const signalrAccessToken = oauth.signalr.accessToken

    this.hubConnection = new HubConnectionBuilder()
        .withUrl(
          signalrURI,
          {
              accessTokenFactory: () => signalrAccessToken
          }
        )
        .configureLogging(LogLevel.Information)
        .build()
  }

  private async connectSignalR(handler: fnHandler): Promise<void> {
    return new Promise((resolve, reject) => {
      (this.hubConnection as HubConnection)
        .start()
        .then(() => {
            this.subscribeEvents(handler)
            resolve()
        })
        .catch(err => {
            console.warn(err)
            reject()
        })
    });
  }

  private subscribeEvents(handler: fnHandler): void {
    (this.hubConnection as HubConnection).on("NOTIFICATION_TRAY", handler)
  }

  public async connect(eventHandler: fnHandler) {
    await this.buildConnection()
    this.connectSignalR(eventHandler)
  }

  public getBiometriaFrontendURL () {
    if (!this.oauth) throw new Error('Biometria not yet connected')
    return environment.biometriaIframeUrl + this.oauth.transactionId
  }
}
