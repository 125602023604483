import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ParametrosService } from "../services/parametros/parametros.service";
import { Service } from "./Service";
import { lastValueFrom } from "rxjs";

export abstract class InternalService {
    private ready: boolean = false

    constructor (
        protected parametros: ParametrosService,
        private http: HttpClient,
        private service: Service
    ) {}
    protected isReady(): boolean {
        return this.ready
    }

    protected getService(): Service {
        return this.service
    }

    protected async doRequestToEndpoint <T> (endpointName: string, config?: Partial<{body: any, params: Record<string, string | number | boolean | string[] | number[] | boolean[]>, template: Record<string, string>, extraHeaders: Record<string, string | number | boolean> | undefined}>) {
        const service = this.getService()

        if (!service.endpointExists(endpointName)) throw new Error('Endpoint ' + endpointName + ' does not exist on service ' + service.name);

        const options = {} as Record<string, any>

        const headerName = service.getEndpointAttr(endpointName, 'parameterHeadersName')

        let endpointURL = this.getService().getEndpointUrl(endpointName)

        if (typeof headerName === 'string') {
            options['headers'] = this.parametros.getHeaders(headerName)
        }
        
        if (config?.body) {
            options['body'] = config.body
        }
        
        if (config?.params) {
            options['params'] = new HttpParams()

            const keys = Object.keys(config.params)
            const values = Object.values(config.params)
            
            keys.forEach((key, ix) => {
                options['params'] = options['params'].set(key, values[ix]);
            })
        }
        
        if (config?.template) {

            const template = config.template

            if (template === undefined) throw new Error('')

            const keys = Object.keys(template)
            
            keys.forEach(key => {
                endpointURL = endpointURL.replace(`{${key}}`, template[key])
            })
        }
        
        if (config?.params) {
            options['params'] = new HttpParams()

            const keys = Object.keys(config.params)
            const values = Object.values(config.params)
            
            keys.forEach((key, ix) => {
                options['params'] = options['params'].set(key, values[ix]);
            })
        }
        
        if (config?.extraHeaders) {
            if (options['headers'] instanceof HttpHeaders === false) {
                options['headers'] = new HttpHeaders()
            }


            const keys = Object.keys(config.extraHeaders)
            const values = Object.values(config.extraHeaders)
            
            keys.forEach((key, ix) => {
                options['headers'] = options['headers'].set(key, values[ix]);
            })
        }

        const request$ = this.http.request<T>(this.getService().getEndpointMethod(endpointName), endpointURL, options)
        const response = await lastValueFrom(request$);
        return response

    }
}