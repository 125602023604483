import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InternalService } from 'src/app/common/InternalService';
import { BypassToCalificacionError } from 'src/app/errors/BypassToCalificacionError';
import { RejectedClientError } from 'src/app/errors/RejectedClientError';
import { environment } from 'src/environments/environment';
import { CalificacionPepperAssiService } from '../assi/calificacion-pepper-assi.service';
import { ParametrosService } from '../parametros/parametros.service';
import { QradarService } from '../qradar/qradar.service';
import { OfertasRequest } from './requests/OfertasRequest';
import { Additional, MultiOfertasResponse, OfertasResponse, Person, ProposalOferta, RetryLogic } from './responses/OfertasResponse';

export interface Oferta {
  id: string,
  campaignId: string
  cardCode: string,
  type?: string
  incomeFlow?: string,
  marcaTarjeta: string
  tipoTarjeta: string
  monto: string | number
  tasa?: string
  tipoCliente: string
  nombreMarca: string
  nombreTipo: string
  membresia: string
  tea: string
  desgravamen: string
  montoMinimo: string
  diasPago: Array<string>
}

type AdditionalKey = keyof Additional

@Injectable({
  providedIn: 'root'
})
export class ApiOfertasService extends InternalService {

  private persona: Person | null;
  private emailAlternativo: string = '';
  private validacionBiometria: boolean = false;
  private isCustomCampaign: boolean | null;
  private ofertas: Oferta[];
  private listsProposal: ProposalOferta[];
  private ofertasCalificacion: Oferta[];
  private retryLogic: RetryLogic = {
    "hasReentryLogic": false
  }
  private multiOferts: MultiOfertasResponse | null;

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient,
    private assi: CalificacionPepperAssiService,
    private qradar: QradarService
  ) {
    super(parametros, http, environment.services.pepper);
    this.persona = null
    this.isCustomCampaign = null
    this.ofertas = []
    this.multiOferts = null;
    this.listsProposal = []
    this.ofertasCalificacion = []
  }

  get person() {
    return this.persona
  }

  get proposals() {
    return this.listsProposal
  }

  get correoAlternativo() {
    return this.emailAlternativo
  }

  get validarBiometria() {
    return this.validacionBiometria;
  }

  get offers() {
    return this.ofertas
  }

  get multiOffers() {
    return this.multiOferts;
  }

  get offersCalificacion() {
    return this.ofertasCalificacion
  }

  get reentryLogic() {
    return this.retryLogic
  }

  private parseAdditionals(additionals: Additional): Record<AdditionalKey, Array<string>> {
    const parsed = {} as Record<string, Array<string>>

    Object.keys(additionals).forEach((additionalKey: string) => {
      parsed[additionalKey] = additionals[additionalKey as AdditionalKey].split(/[\*;]/)
    })

    return parsed
  }

  private parsePersonaFromOfertasResponse(ofertasResponse: OfertasResponse): void {
    this.persona = ofertasResponse.person
  }

  private async parseOfertasResponse(ofertasResponse: OfertasResponse): Promise<void> {

    this.persona = ofertasResponse.person
    this.isCustomCampaign = ofertasResponse.isCustomCampaign

    if (!ofertasResponse.campaign || !ofertasResponse.campaign.lead) throw new RejectedClientError()

    const rootCampaign = ofertasResponse.campaign.lead
    const campaignId = ofertasResponse.campaign.lead.campaign.number
    const adicionales = this.parseAdditionals(rootCampaign.additional)

    this.ofertas.push({
      id: rootCampaign.id,
      campaignId,
      cardCode: `${rootCampaign.cardBrandId}${rootCampaign.cardTypeId}`,
      type: rootCampaign.type,
      incomeFlow: rootCampaign.incomeFlow,
      marcaTarjeta: rootCampaign.cardBrandId,
      tipoTarjeta: rootCampaign.cardTypeId,
      monto: parseFloat(rootCampaign.amount).toFixed(0),
      tipoCliente: rootCampaign.customerType,
      nombreMarca: this.parametros.cardNames.type[rootCampaign.cardBrandId],
      nombreTipo: this.parametros.cardNames.subtype[rootCampaign.cardTypeId].replace(new RegExp('_', 'g'), ' '),
      membresia: adicionales.field8[0],
      tea: adicionales.field8[1],
      desgravamen: adicionales.field8[2],
      montoMinimo: adicionales.field8[3],
      diasPago: adicionales.field7
    })

    if (adicionales.field9[0].trim().length > 0 && adicionales.field10[0].trim().length > 0) {
      this.ofertas.push({
        id: rootCampaign.id,
        campaignId,
        cardCode: `${adicionales.field9[0]}${adicionales.field9[1]}`,
        incomeFlow: rootCampaign.incomeFlow,
        marcaTarjeta: adicionales.field9[0],
        tipoTarjeta: adicionales.field9[1],
        monto: parseFloat(adicionales.field9[2]).toFixed(0),
        tasa: adicionales.field9[3],
        tipoCliente: adicionales.field9[4],
        nombreMarca: adicionales.field9[5],
        nombreTipo: adicionales.field9[6],
        membresia: adicionales.field10[0],
        tea: adicionales.field10[1],
        desgravamen: adicionales.field10[2],
        montoMinimo: adicionales.field10[3],
        diasPago: adicionales.field7,
      })
    }

    if (adicionales.field1[0].trim().length > 0 && adicionales.field2[0].trim().length > 0) {
      this.ofertas.push({
        id: rootCampaign.id,
        campaignId,
        cardCode: `${adicionales.field1[0]}${adicionales.field1[1]}`,
        incomeFlow: rootCampaign.incomeFlow,
        marcaTarjeta: adicionales.field1[0],
        tipoTarjeta: adicionales.field1[1],
        monto: parseFloat(adicionales.field1[2]).toFixed(0),
        tasa: adicionales.field1[3],
        tipoCliente: adicionales.field1[4],
        nombreMarca: adicionales.field1[5],
        nombreTipo: adicionales.field1[6],
        membresia: adicionales.field2[0],
        tea: adicionales.field2[1],
        desgravamen: adicionales.field2[2],
        montoMinimo: adicionales.field2[3],
        diasPago: adicionales.field7,
      })
    }
    
    if (ofertasResponse.retryLogic) {
      if (ofertasResponse.retryLogic.hasReentryLogic) {
        let callNumber = null
        let result: string = '';

        if (
          Array.isArray(ofertasResponse.retryLogic.qualifications) &&
          ofertasResponse.retryLogic.qualifications.length > 0
        ) {
          const qLastIndex = ofertasResponse.retryLogic.qualifications.length - 1
          callNumber = ofertasResponse.retryLogic.qualifications[qLastIndex].callNumber
          result = ofertasResponse.retryLogic.qualifications[qLastIndex].result
        }

        if (callNumber === null) {
          if (ofertasResponse.retryLogic.expedient) {
            if (ofertasResponse.retryLogic.expedient.channelCode === '113') {
              this.retryLogic = ofertasResponse.retryLogic
            } else {
              throw new Error('ERROR_NO_CANAL')
            }
          } else {
            if (ofertasResponse.retryLogic.id === '4') {
              throw new Error('ERROR_NO_CANAL')
            } else if (ofertasResponse.retryLogic.id === '5') {
              throw new Error('ERROR_NO_INGRESA_EXPEDIENTE')
            }
          }
        } else if (callNumber === 1) {
          if (result === 'RECHAZADO') throw new RejectedClientError()
          if (!ofertasResponse.retryLogic.expedient) throw new Error('No expedient number found')
          const expediente = await this.assi.consultaExpediente(ofertasResponse.retryLogic.expedient.expedientNumber)
          this.retryLogic = ofertasResponse.retryLogic
          this.retryLogic.id = expediente.id
        } else if (callNumber === 2) {
          if (result === 'RECHAZADO') throw new RejectedClientError()
          if (!ofertasResponse.retryLogic.expedient) throw new Error('No expedient number found')

          if (!ofertasResponse.retryLogic.qualifications) {
            throw new RejectedClientError()
          }

          const qualifiedProposal = (function (q) {
            for (let i = 0; i < q.length; i++) {
              const qualification = q[i]
              for (let j = 0; j < qualification.products.length; j++) {
                const proposal = qualification.products[j].proposals
                if (Array.isArray(proposal) && proposal.length > 0) {
                  return proposal;
                }
              }
            }

            return undefined;
          })(ofertasResponse.retryLogic.qualifications)

          if (qualifiedProposal === undefined) throw new RejectedClientError()

          this.listsProposal = qualifiedProposal
          const tarjetasCalificadas = this.buscarOfertas()
          if (tarjetasCalificadas.length <= 0) throw new RejectedClientError()
          this.setOfertasCalificacion(tarjetasCalificadas)

          const expediente = await this.assi.consultaExpediente(ofertasResponse.retryLogic.expedient.expedientNumber)
          this.emailAlternativo = expediente.emailAlternativo;
          this.validacionBiometria = expediente.validacionBiometria;

          throw new BypassToCalificacionError(ofertasResponse.retryLogic.expedient.expedientNumber, '', qualifiedProposal[0])
        }
      }
    }
  }

  public buscarOfertas() {
    const codigos = this.listsProposal.map(proposal => ({
      productTypeCode: this.parametros.cardNames.typeFlipped[proposal.productType],
      subProductCode: this.parametros.cardNames.subtypeFlipped[proposal.subProduct],
      maxLine: parseInt(proposal.maxLine + '')
    }));

    const filtro = this.offers.filter(oferta =>
      codigos.some(codigo =>
        oferta.marcaTarjeta === codigo.productTypeCode && oferta.tipoTarjeta === codigo.subProductCode
      )
    );

    filtro.forEach(offer => {
      const codigoCorrespondiente = codigos.find(cod =>
        offer.marcaTarjeta === cod.productTypeCode && offer.tipoTarjeta === cod.subProductCode
      );
      if (codigoCorrespondiente) offer.monto = codigoCorrespondiente.maxLine
    })
    return filtro;
  }

  public async fetchOfertas(req: OfertasRequest): Promise<void> {
    const response = await this.doRequestToEndpoint<OfertasResponse>('ofertasOffers', {
      body: req
    })

    await this.parseOfertasResponse(response)

  }

  public async multiOfertas(req: OfertasRequest): Promise<void> {
    const response = await this.doRequestToEndpoint<MultiOfertasResponse>('multiOfertas', {
      body: req
    })
    this.multiOferts = response;
  }

  public async fetchPersona(req: OfertasRequest): Promise<void> {
    try {
      const response = await this.doRequestToEndpoint<OfertasResponse>('ofertasOffers', {
        body: req
      })

      this.parsePersonaFromOfertasResponse(response)
    } catch (e) {
      if (this.person !== null) {
        return;
      }
      const err = e as HttpErrorResponse
      if (err.status && err.status !== 403) {
        const data = {
          parametros: [
            {
              clave: 'DETALLEERROR',
              valor: 'ERROR Offers - '.concat(err.status ? err.status.toString() : 'interno', ' - ', err.error ? err.error['errorDetail'] ? err.error['errorDetail'] : '' : '')
            }
          ]
        }
        await this.qradar.addToRegister('ofertasOffers', data);
      }
      throw new Error('Error executing Ofertas API.');
    }
  }

  private ofertasHasToBeFetched(): void {
    if (!this.ofertas || !this.persona) throw new Error('Ofertas API was not excecuted properly');
  }

  public hasCampaign(): boolean {
    this.ofertasHasToBeFetched()
    return this.ofertas.length > 0
  }

  public setOfertasCalificacion(ofertas: Oferta[]) {
    this.ofertasCalificacion = ofertas;
  }

  public userIsCustomer(): boolean {
    this.ofertasHasToBeFetched()
    return typeof this.persona?.customerId !== 'undefined';
  }

  public getCampaigns(): Oferta[] {
    this.ofertasHasToBeFetched()
    return this.ofertas
  }

  public getPerson(): Person {
    this.ofertasHasToBeFetched()
    if (this.persona === null) throw new Error('Person has a null value')
    return this.persona
  }

  public getIsCustomCampaign(): boolean {
    this.ofertasHasToBeFetched()
    if (this.isCustomCampaign === null) throw new Error('isCustomCampaign has a null value')
    return this.isCustomCampaign
  }
}
