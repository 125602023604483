import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import moment from 'moment';
import { InternalService } from 'src/app/common/InternalService';
import { environment } from 'src/environments/environment';
import { ParametrosService } from '../parametros/parametros.service';

@Injectable({
  providedIn: 'root'
})
export class ReportesService extends InternalService {

  constructor(
    @Inject(ParametrosService) parametros: ParametrosService,
    @Inject(HttpClient) http: HttpClient
  ) {
    super(parametros, http, environment.services.parametros)
  }

  reqId: string | undefined;

  lastProperties = {}

  async addToReport(payload: Record<string, any>) {

    if (payload['properties']) {
      this.lastProperties = Object.assign({}, this.lastProperties, payload['properties'])
      payload['properties'] = this.lastProperties
    }

    let requestPayload = {
      body: payload
    } as any

    let endpoint = 'createReporte'

    if (this.reqId) {

      requestPayload.body.updated_at = moment().format('YYYY/MM/DD')
      requestPayload.body.properties.updated = moment().format('DD/MM/YYYY HH:mm:ss')
      requestPayload.template = {
        req_id: this.reqId
      }

      endpoint = 'updateReporte'
    }

    requestPayload.body['properties'] = JSON.stringify(requestPayload.body['properties'])

    const response = await this.doRequestToEndpoint<{ req_id: string }>(endpoint, requestPayload)

    if (this.reqId === undefined) {
      this.reqId = response.req_id
    }

    return response
  }

  public getReqId() {
    return this.reqId;
  }

  public restartReqId() {
    this.reqId = undefined;
    this.lastProperties = {};
  }

}
