export enum HttpMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

interface EndpointAttributes {
    parameterHeadersName?: string;
    [key: string]: any;
}

class ServiceEndpoint {
    constructor (
        private _name: string,
        private _endpoint: string,
        private _method: HttpMethods,
        private _attrs: EndpointAttributes = {}
    ) {}
    
    get name (): string { return this._name }
    get method (): string { return this._method }
    get endpoint (): string { return this._endpoint }

    getAttrs (): EndpointAttributes {
        return this._attrs;
    }

    getAttr (propName: string): string {
        return this._attrs[propName];
    }

    addAttr (name: string, value: string): void {
        this._attrs[name] = value;
    }
}

interface IServiceEndpoint {
    name: string;
    method: HttpMethods;
    endpoint: string;
    attrs?: EndpointAttributes
}

export class Service {

    private _endpoints: Record<string, ServiceEndpoint>;

    constructor (
        private _name: string,
        private _baseUrl: string,
        _endpoints: IServiceEndpoint[] = [],
    ) {
        this._endpoints = {}  
        let rootExists = false
        _endpoints.forEach(endpoint => {
            if (endpoint.name === '_root') { rootExists = true }
            this._endpoints[endpoint.name] = new ServiceEndpoint(endpoint.name, endpoint.endpoint, endpoint.method, endpoint.attrs)
        })

        if (!rootExists) {
            this._endpoints['_root'] = new ServiceEndpoint('_root', '', HttpMethods.GET)
        }
    }

    get name (): string { return this._name }

    public endpointExists (endpointName: string) {
        return typeof this._endpoints[endpointName] !== 'undefined'
    }

    public getEndpointAttr (endpointName: string, attrName: string): string {
        return this._endpoints[endpointName === null ? '_root' : endpointName].getAttr(attrName)
    }
    public getEndpointAttrs (endpointName: string): EndpointAttributes {
        return this._endpoints[endpointName === null ? '_root' : endpointName].getAttrs()
    }

    public getEndpointUrl(endpointName: string): string {
        return this._baseUrl + this._endpoints[endpointName === null ? '_root' : endpointName].endpoint;
    }
    public getEndpointMethod(endpointName: string | null): string {
        return this._endpoints[endpointName === null ? '_root' : endpointName].method;
    }
    
    public getRootEndpointUrl (): string {
        return this._baseUrl;
    }
}