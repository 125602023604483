<div class="modal-politica">
  <div>
    <h3 class="heading-3 mb-100">Política de Privacidad de Interbank</h3>
    <p class="text-3">En Interbank, nos preocupamos por la privacidad de tus datos personales. Por eso, queremos
      informarte y explicarte cómo tratamos tu información de acuerdo con nuestras regulaciones actuales.
    </p>
    <p class="text-3">Con ese objetivo, hemos creado esta Política de Privacidad (“Política de Privacidad”), a través de
      la cual
      se detallan nuestras prácticas de recopilación y uso de tus datos personales, así como las acciones que puedes
      tomar
      con relación a ellos.
    </p>
    <div class="list-order">
      <ol type="1">
        <li>
          <p class="text-3 sub-title">Identidad y domicilio del responsable del tratamiento de los datos personales</p>
          <p class="text-3">Interbank es el responsable del tratamiento de tus datos personales</p>
          <ul class="list">
            <li type="circle">
              <p class="text-3">Banco Internacional del Perú S.A.A. – “Interbank”,</p>
            </li>
            <li class="text-3">
              RUC N° 20100053455
            </li>
            <li class="text-3">
              Domicilio: Av. Carlos Villarán N° 140, Urb. Santa Catalina, La Victoria, Lima, Perú
            </li>
          </ul>
        </li>
        <li>
          <p class="text-3 sub-title">¿A quién va dirigida esta Política de Privacidad?</p>
          <p class="text-3">Esta Política va dirigida tanto a nuestros clientes, posibles clientes,
            sus representantes legales, tutores o curadores; así como a usuarios que visiten nuestro sitio web o
            nuestras aplicaciones y aquellos que hayan tenido algún contacto con nosotros, incluso a través de nuestras
            redes sociales.</p>
        </li>
        <li>
          <p class="text-3 sub-title">¿Quiénes deben cumplir esta Política de Privacidad?</p>
          <p class="text-3">Tanto nuestros colaboradores, proveedores y socios comerciales están
            obligados a cumplir con los lineamientos establecidos en esta Política de Privacidad.</p>
        </li>
        <li>
          <p class="text-3 sub-title">Marco normativo</p>
          <p class="text-3">En Interbank, nos comprometemos a proteger tus datos personales siguiendo
            los estándares de seguridad establecidos por la normativa peruana, en particular:</p>
          <ul class="list">
            <li>
              <p class="text-3">Ley N° 29733, Ley de Protección de Datos Personales</p>
            </li>
            <li>
              <p class="text-3">Decreto Supremo N° 003-2013-JUS, Reglamento de la Ley N° 29733</p>
            </li>
            <li>
              <p class="text-3">Directiva de Seguridad de la Información, aprobada por la Resolución
                Directoral N° 019-2013-JUS/DGPDP.</p>
            </li>
            <li>
              <p class="text-3">Directiva para el Tratamiento de datos personales mediante Sistemas
                de Videovigilancia, aprobada por la Resolución Directoral N° 02-2020-JUS/ DGTAIPD.</p>
            </li>
          </ul>
          <p class="text-3">En ese sentido, hemos implementado las medidas técnicas, legales y
            organizativas exigidas por la normativa para garantizar la seguridad y protección de la información que nos
            proporcionas. Nos enfocamos en prevenir la pérdida, el mal uso, la alteración, el acceso no autorizado y el
            robo de dicha información. Además, cumplimos estrictamente con el deber de confidencialidad en relación con
            tus datos personales.</p>
        </li>
        <li>
          <p class="text-3 text-3 sub-title">¿Cuándo y cómo recopilamos los datos personales?
          </p>
          <p class="text-3">Recopilamos tu información de diversas formas, ya sea que nos la
            proporciones directamente o la recolectemos por ti. Algunos ejemplos son:</p>
          <ul class="list">
            <li>
              <p class="text-3">Cuando te pones en contacto con nosotros a través de nuestros
                canales, como agencia, tiendas, WhatsApp AVI, banca por Internet, contact center, entre otros.</p>
            </li>
            <li>
              <p class="text-3">Cuando contratas y utilizas productos y servicios ofrecidos por
                Interbank.</p>
            </li>
            <li>
              <p class="text-3">Si instalas nuestras aplicaciones y nos autorizas a recopilar
                información necesaria para su funcionamiento, como geolocalización o datos de su dispositivo.</p>
            </li>
            <li>
              <p class="text-3">Cuando navegues por nuestro sitio web o utilices nuestras
                aplicaciones.</p>
            </li>
            <li>
              <p class="text-3">Si eres beneficiario o tienes interés en una transacción bancaria
                realizada por otro cliente, como transferencias entre cuentas, emisión de recibos bancarios o descuento
                de cheques.</p>
            </li>
            <li>
              <p class="text-3">Si eres socio, administrador, abogado o representante legal de una
                empresa que es cliente o mantiene cualquier otra relación con Interbank.</p>
            </li>
            <li>
              <p class="text-3">También podemos obtener tus datos a través de fuentes públicas y
                lícitas.</p>
            </li>
          </ul>
        </li>
        <li>
          <p class="text-3 text-3 sub-title">Datos Personales Obligatorios</p>
          <p class="text-3">Para poder brindarte nuestros servicios y cumplir con nuestras
            obligaciones es necesario que nos proporciones cierta información personal, dependiendo del producto o
            servicio que desees contratar, te pediremos los siguientes datos:</p>
          <ul class="list">
            <li>
              <p class="text-3"><span class="weight-medium2">Datos de carácter
                  identificativo:</span>: nombres, apellidos, documento de identidad, dirección de domicilio, teléfono,
                correo electrónico, voz, imagen en fotografía o video -independientemente del soporte (videovigilancia,
                videollamada), firma y firma electrónica.</p>
            </li>
            <li>
              <p class="text-3"><span class="weight-medium2">Datos de características
                  personales</span>: estado civil, fecha de nacimiento, nacionalidad, sexo, edad y profesión.</p>
            </li>
            <li>
              <p class="text-3"><span class="weight-medium2">Datos económicos – financieros</span>:
                datos bancarios, créditos, préstamos, avales, historial de créditos, información tributaria, seguros,
                tarjetas de crédito, bienes patrimoniales, hipotecas, beneficios recibidos de programas sociales y
                deudas.</p>
            </li>
            <li>
              <p class="text-3"><span class="weight-medium2">Datos de carácter social</span>:
                Características de la vivienda.</p>
            </li>
            <li>
              <p class="text-3"><span class="weight-medium2">Datos sensibles</span>: ingresos
                económicos y datos biométricos (como huella dactilar y reconocimiento facial). Queremos aclarar que el
                tratamiento de estos datos sensibles es necesario para preparar, celebrar y ejecutar la relación
                contractual, ya que tienen como finalidad evaluar los riesgos crediticios (ingresos económicos) así como
                validar la identidad y documentar la manifestación de voluntad (datos biométricos).</p>
            </li>
            <li>
              <p class="text-3"><span class="weight-medium2">Datos personales de menores de
                  edad</span>: en Interbank, comprendemos la importancia de proteger los datos personales de los menores
                de edad. Por lo tanto, solo utilizaremos los datos personales de menores de edad con el consentimiento
                previo de sus padres, tutores o representantes legales, en situaciones donde los productos y servicios
                estén destinados a niños, niñas y/o adolescentes. Si algún menor de edad ingresa sus datos personales a
                través de nuestros canales, deberá solicitar el permiso correspondiente a sus padres, tutores o
                representantes legales, quienes serán considerados responsables de todos los actos realizados por los
                menores a su cargo.</p>
            </li>
            <li>
              <p class="text-3"><span class="weight-medium2">Datos del dispositivo</span>:
                identificador del dispositivo (como IMEI o UDID, según la versión del sistema del dispositivo).</p>
            </li>
            <li>
              <p class="text-3"><span class="weight-medium2">Datos de red y uso del servicio</span>:
                dirección IP, IMSI, log de registro, inicio/cierre de sesión y actividades de uso.<br>Los datos del
                dispositivo y de la red y usos del servicio serán recopilados por Interbank cuando el cliente opere a
                través de nuestros canales digitales, tales como (Interbank App, Banca por Internet y/u otro que
                Interbank implemente para efectuar operaciones por medios virtuales o digitales), con la finalidad de
                prevenir fraudes.</p>
            </li>
          </ul>
          <p class="text-3">En Interbank, confiamos en que la información que nos brindas es veraz,
            exacta, completa, vigente y auténtica. Te recordamos que es tu obligación mantenerla actualizada, aunque
            nosotros también podremos actualizarla, validarla o complementar a partir de fuentes legítimas públicas, en
            cumplimiento del Principio de Calidad establecido en la Ley de Protección de Datos Personales.</p>
          <p class="text-3">En virtud del Principio de Calidad y a fin de mantener su información
            actualizada para el cumplimiento de las obligaciones contractuales, Interbank podrá obtener tus datos
            personales de manera lícita a través de información recibida de terceros o que sea obtenida de fuentes
            públicas y lícitas. En esa línea, esta información podrá ser utilizada para las finalidades adicionales
            descritas en esta Política siempre que el cliente haya autorizado dichos usos, así como también podrá ser
            transferida a los terceros que se encuentran identificados en esta Política para cumplir con las finalidades
            autorizadas.</p>
        </li>
        <li>
          <p class="text-3 sub-title">¿Para qué utilizamos los datos personales?</p>
          <ol>
            <li>
              <p class="text-3 weight-medium2">Finalidades Necesarias (principales)</p>
              <p class="text-3">En Interbank, utilizamos tus datos personales para diversas
                finalidades necesarias relacionadas con los servicios que ofrecemos. A continuación, te explicamos cómo
                utilizamos tus datos personales:</p>
              <p class="text-3 weight-medium2">Respecto del Banco de Datos denominado
                “Clientes”:</p>
              <ul class="list">
                <li>
                  <p class="text-3">Verificar y validar tu identidad como cliente y del potencial
                    cliente.</p>
                </li>
                <li>
                  <p class="text-3">Enviarte, a través de cualquier medio de comunicación (teléfono,
                    correo electrónico, SMS o medios similares), información, cotizaciones y/o propuestas de los
                    productos y/o servicios ofertados por Interbank, solicitados previamente.</p>
                </li>
                <li>
                  <p class="text-3">Formalizar y gestionar los productos y servicios que contrates
                    con Interbank.</p>
                </li>
                <li>
                  <p class="text-3">Enviarte los estados de cuenta e información relacionada con los
                    productos y servicios que has contratado con Interbank.</p>
                </li>
                <li>
                  <p class="text-3">Evaluar los riesgos de tu crédito y llevar a cabo la cobranza de
                    deudas.</p>
                </li>
                <li>
                  <p class="text-3">Atender y gestionar tus reclamos, requerimientos, quejas,
                    consultas y/o solicitudes que realices a través de los canales autorizados por Interbank.</p>
                </li>
                <li>
                  <p class="text-3">Prevenir o detectar fraudes o actividades delictivas, así como
                    gestionar y resolver cualquier pérdida real o potencial asociada a un delito o fraude.</p>
                </li>
                <li>
                  <p class="text-3">Prevenir o detectar actividades relacionadas a delitos como
                    lavado de activos, financiamiento del terrorismo, corrupción o para gestionar cualquier información
                    que soliciten las entidades legitimadas y reguladores nacionales e internacionales.</p>
                </li>
                <li>
                  <p class="text-3">Compartir tus datos personales con proveedores de servicios para
                    cumplir con nuestras obligaciones.</p>
                </li>
                <li>
                  <p class="text-3">Compartir tus datos personales con la institución en la que
                    trabajas o de la que recibe tu pensión, en caso de gestionar productos de préstamos por convenio.
                  </p>
                </li>
                <li>
                  <p class="text-3">Alojar, almacenar y procesar tu información en empresas
                    especializadas, nacionales o internacionales, que brinden servicios basados en infraestructura en la
                    nube.</p>
                </li>
                <li>
                  <p class="text-3">Garantizar la seguridad de las instalaciones y de las personas
                    que se encuentran en ellas mediante cámaras de videovigilancia.</p>
                </li>
                <li>
                  <p class="text-3">Realizar análisis estadísticos para la elaboración de estrategias
                    comerciales, de manera anonimizada o disociada.</p>
                </li>
                <li>
                  <p class="text-3">Cumplir con nuestras obligaciones legales.</p>
                </li>
              </ul>
              <p class="text-3 weight-medium2">Respecto del Banco de Datos denominado
                “Videovigilancia”:</p>
              <ul class="list">
                <li>
                  <p class="text-3">Garantizar la seguridad de nuestras sedes (instalaciones
                    internas) y en las tiendas (oficinas financieras) de Interbank, así como de las personas que se
                    encuentran en ellas, mediante las cámaras de videovigilancia.</p>
                </li>
                <li>
                  <p class="text-3">Verificar el cumplimiento de obligaciones y deberes laborales de
                    nuestros colaboradores durante su atención.</p>
                </li>
              </ul>
              <p class="text-3">
                Para obtener más información sobre el tratamiento de los datos personales (imágenes) captados mediante
                los sistemas de videovigilancia, te invitamos a revisar la<span class="weight-medium2"> “Hoja
                  Informativa sobre el tratamiento de datos personales mediante Sistemas de Videovigilancia”</span>
                adjunta como<span class="weight-medium2"> Anexo III.</span></p>
              <p class="text-3 weight-medium2">Respecto del Banco de Datos denominado
                “Control de accesos y gestión de visitas a las Sedes Interbank”:</p>
              <ul class="list">
                <li>
                  <p class="text-3">Gestionar los controles de acceso por medidas de seguridad de las
                    visitas en Sedes de Interbank.</p>
                </li>
              </ul>
              <p class="text-3">
                Para obtener más información sobre el tratamiento de los datos personales de los visitantes, te
                invitamos a revisar la<span class="weight-medium2"> “Hoja Informativa sobre el tratamiento de datos
                  personales para el control de accesos y gestión de visitas a las Sedes Interbank”</span> adjunta
                como<span class="weight-medium2"> Anexo IV.</span></p>
            </li>
            <li>
              <p class="text-3 weight-medium2">Finalidades Opcionales (requiere consentimiento
                expreso)</p>
              <p class="text-3">Además, de manera opcional, tus datos personales podrán ser
                utilizados por Interbank con fines opcionales, previo consentimiento expreso de tu parte. Estas
                finalidades opcionales incluyen:</p>
              <ul class="list">
                <li>
                  <p class="text-3">Realizar estudios de mercado, obtención de información a través
                    de fuentes públicas y privadas, perfiles de compra y envío de publicidad, promociones y ofertas de
                    sus productos y/o servicios y/o de otras empresas con ocasión de alianzas comerciales, mediante
                    correo electrónico, llamadas telefónicas, mensajería instantánea u otros canales que se hayan
                    habilitado para dicho fin.</p>
                </li>
                <li>
                  <p class="text-3">Compartir con las empresas del Grupo Intercorp y/o sus socios
                    comerciales sus datos personales y, de ser el caso, información financiera, con la finalidad de
                    realizar estudios de mercado, perfiles de compra, envío de publicidad, promociones y ofertas de sus
                    productos y/o servicios que estas ofrecen.</p>
                </li>
              </ul>
              <p class="text-3">
                Es importante mencionar que, para las finalidades opcionales, Interbank podrá compartir tus datos
                personales con socios comerciales y empresas pertenecientes al Grupo Intercorp en los rubros de retail,
                restaurantes, entretenimiento, hoteles e inmobiliarias, salud, seguros, educación y financieras. La
                lista actualizada de estas empresas se encuentra detallada en el <span class="weight-medium2">Anexo
                  I</span> adjunto, denominado "Lista de Empresas del Grupo Intercorp y socios comerciales". En caso de
                ser necesario, esta lista será actualizada oportunamente por Interbank por esta vía.</p>
            </li>
          </ol>
        </li>
        <li>
          <p class="text-3 sub-title">Consecuencia de proporcionar los datos personales o
            su negativa a hacerlo</p>
          <p class="text-3">Si decides no proporcionar tus datos personales obligatorios para las
            finalidades necesarias, es importante que tengas en cuenta que no podremos brindarte el servicio o producto
            que deseas contratar o que ya has contratado.</p>
          <p class="text-3">No obstante, queremos asegurarte de que tienes la opción y libertad de
            otorgarnos tu consentimiento y autorización para utilizar tus datos personales con fines opcionales
            adicionales, distintos a los necesarios.</p>
        </li>
        <li>
          <p class="text-3 sub-title">¿Con quiénes compartimos los datos personales?</p>
          <ol>
            <li>
              <p class="text-3 weight-medium2">Destinatarios Necesarios</p>
              <p class="text-3">Para cumplir con las finalidades necesarias y, de ser el caso, las
                opcionales descritas anteriormente, Interbank podrá tratar tus datos de manera directa o a través de
                terceros (proveedores), ya sea a nivel nacional o internacional. Estos terceros se encuentran
                comprometidos a brindar el servicio de acuerdo con nuestras directrices de protección de datos y con la
                normativa peruana aplicable.</p>
              <p class="text-3">Algunos de los destinatarios necesarios con quienes podríamos
                compartir tus datos son:</p>
              <ul class="list">
                <li>
                  <p class="text-3">Servicios de adquirencia</p>
                </li>
                <li>
                  <p class="text-3">Proveedores de hosting</p>
                </li>
                <li>
                  <p class="text-3">Proveedores de servicio de mensajería.</p>
                </li>
                <li>
                  <p class="text-3">Proveedores de servicios de distribución de valorados</p>
                </li>
                <li>
                  <p class="text-3">Proveedores de servicio de embozado de tarjetas</p>
                </li>
                <li>
                  <p class="text-3">Proveedores de servicio de contact center</p>
                </li>
                <li>
                  <p class="text-3">Proveedores de servicios de cobranza</p>
                </li>
                <li>
                  <p class="text-3">Proveedores de servicios de consultoría</p>
                </li>
                <li>
                  <p class="text-3">Proveedores de servicios de análisis estadísticos y porcentual
                  </p>
                </li>
                <li>
                  <p class="text-3">Proveedores publicitarios</p>
                </li>
                <li>
                  <p class="text-3">Proveedores tecnológicos</p>
                </li>
                <li>
                  <p class="text-3">Proveedores de servicio de custodia documental</p>
                </li>
              </ul>
              <p class="text-3">
                Puedes encontrar más detalle sobre nuestros destinatarios necesarios en el <span
                  class="weight-medium2">Anexo II</span> adjunto, denominado <span class="weight-medium2">“Lista de
                  Proveedores”.</span> En caso sea necesario, esta lista será actualizada de manera oportuna por
                Interbank por este mismo medio.</p>
              <p class="text-3"> Por otro parte, queremos reafirmar nuestro compromiso de no
                compartir tus datos personales, sin tu debida autorización, a excepción de los siguientes casos:</p>
              <ul class="list">
                <li>
                  <p class="text-3">Solicitudes de información realizadas por autoridades o
                    reguladores en ejercicio de sus funciones y dentro del ámbito de sus competencias.</p>
                </li>
                <li>
                  <p class="text-3">Solicitudes de información amparadas por órdenes judiciales.</p>
                </li>
                <li>
                  <p class="text-3">Solicitudes de información conforme a disposiciones legales
                    aplicables (como centrales privadas de riesgos).</p>
                </li>
              </ul>
            </li>
            <li>
              <p class="text-3 weight-medium2">Destinatarios Opcionales</p>
              <p class="text-3">Además, si has brindado tu consentimiento previo, podemos transferir tus datos personales a las
                empresas del Grupo Intercorp y/o a nuestros socios comerciales mencionados en el Anexo I, tanto dentro
                como fuera del territorio peruano, con el fin de llevar a cabo actividades relacionadas con las
                finalidades opcionales.</p>
            </li>
            <li>
              <p class="text-3 weight-medium2">Transferencia fuera del Perú</p>
              <p class="text-3">Aunque nuestra sede se encuentra en Perú, contamos con proveedores de
                servicios y socios estratégicos que tienen presencia en el extranjero. En estos casos, algunas
                actividades o procesamiento de datos personales pueden llevarse a cabo fuera de Perú. (En las listas
                descritas en los <span class="weight-medium2"> Anexos I y II</span> podrás encontrar el país de dichas
                empresas)</p>
              <p class="text-3"> Queremos asegurarte de que hemos implementado los procedimientos
                necesarios para garantizar que estas empresas también cumplan con las normas de protección de datos
                personales y la legislación aplicable en el país donde se realiza el procesamiento. Además, nos
                comprometemos a informar oportunamente a la Autoridad de Protección de Datos Personales aquellos flujos
                transfronterizos conforme lo exige nuestra normativa vigente.</p>
            </li>
          </ol>
        </li>
        <li>
          <p class="text-3 sub-title">¿Cómo cuidamos los datos personales? </p>
          <p class="text-3">En Interbank, nos esforzamos constantemente por ofrecerte el mejor
            servicio y, para lograrlo, estamos comprometidos en implementar continuamente nuevas tecnologías y procesos
            que fortalezcan la protección de tus datos personales. En este sentido, hemos establecido y seguimos
            rigurosamente las siguientes medidas de seguridad técnicas, legales y organizativas:</p>
          <ul class="list">
            <li>
              <p class="text-3">Adoptamos medidas de seguridad apropiadas para proteger tu
                información personal contra el acceso no autorizado, alteración, divulgación, pérdida o destrucción,
                siguiendo los parámetros establecidos en la normativa aplicable de protección de datos personales.</p>
            </li>
            <li>
              <p class="text-3">Nuestro personal que accede a tu información personal está sujeto al
                deber de confidencialidad y a las obligaciones establecidas en la normativa aplicable de protección de
                datos personales, pudiendo su incumplimiento estar sujeto a medidas disciplinarias, incluyendo el
                despido y pago de indemnización, de ser el caso, por el uso o divulgación no autorizada de tus datos
                personales.</p>
            </li>
            <li>
              <p class="text-3">De igual manera, nuestros socios y proveedores que tengan acceso a tu
                información estarán sujetos al deber de confidencialidad mediante la suscripción de acuerdos de
                confidencialidad o cláusulas de confidencialidad; pudiendo su incumplimiento generar la resolución
                automática del contrato de servicio, descalificación, acciones legales y asunción de costos y
                penalidades.</p>
            </li>
            <li>
              <p class="text-3">Requerimos de nuestros proveedores y socios comerciales un nivel
                suficiente de protección de datos personales equiparable a lo previsto en nuestra regulación o por lo
                estándares internacionales en la materia, exigiéndole adoptar medidas técnicas, organizativas y legales
                que garanticen su seguridad y eviten su alteración, pérdida, tratamiento o acceso no autorizado.</p>
            </li>
            <li>
              <p class="text-3">Aplicamos medidas adecuadas para garantizar la seguridad, la
                confidencialidad permanente, la integridad, la disponibilidad y la resistencia de los sistemas y
                servicios de la actividad de procesamiento.</p>
            </li>
            <li>
              <p class="text-3">Brindamos formación específica a nuestro personal en materia de
                protección de datos personales y los actualizamos periódicamente, como parte de nuestros programas de
                formación obligatoria.</p>
            </li>
            <li>
              <p class="text-3">Procuramos que, al procesar tus datos fuera del Perú, se mantenga el
                nivel de protección garantizado por nuestra regulación y no se vea comprometido.</p>
            </li>
            <li>
              <p class="text-3">Notificamos cualquier incidente de seguridad y tomamos las medidas
                correctivas correspondientes.</p>
            </li>
            <li>
              <p class="text-3">Aunque no podemos garantizar la invulnerabilidad total de Internet,
                trabajamos para proteger tus datos de accesos no autorizados por parte de terceros.</p>
            </li>
          </ul>
        </li>
        <li>
          <p class="text-3 sub-title">Banco de Datos</p>
          <p class="text-3">En Interbank, queremos asegurarnos de que entiendas cómo manejamos tus
            datos personales. A continuación, te explicamos en qué bancos de datos almacenamos tu información:</p>
          <ul class="list">
            <li>
              <p class="text-3">Los datos personales de los clientes y potenciales clientes serán
                conservados en el banco de datos personales denominado “Clientes” inscrito en el Registro Nacional de
                Protección de Datos Personales con Código 246.</p>
            </li>
            <li>
              <p class="text-3">Las imágenes registradas a través de nuestro sistema de
                videovigilancia serán almacenadas en el banco de datos personales denominado “Videovigilancia” e
                inscrito en el Registro Nacional de Protección de Datos Personales con Código 8691.</p>
            </li>
            <li>
              <p class="text-3">Los datos personales de las visitas serán almacenados en el banco de
                datos personales denominado “Control de acceso y gestión de visitas” e inscrito en el Registro Nacional
                de Protección de Datos Personales con Código 23174.</p>
            </li>
          </ul>
        </li>
        <li>
          <p class="text-3 sub-title">Plazo de almacenamiento</p>
          <ul class="list">
            <li>
              <p class="text-3">Los datos personales registrados en el banco de datos de “Clientes”
                serán conservados por un plazo de diez (10) años posteriores a la terminación de la relación
                contractual, según lo dispuesto por la Ley 26702, Ley General del Sistema Financiero y del Sistema de
                Seguros y Orgánica de la Superintendencia de Banca y Seguros; o mientras no revoque su consentimiento
                para tratamiento de finalidades opcionales.</p>
            </li>
            <li>
              <p class="text-3">Las imágenes que sean captadas por el sistema de videovigilancia de
                nuestras Tiendas y Sedes y, registradas en el banco de datos de “Videovigilancia” serán conservadas
                durante un plazo máximo de sesenta (60) días, salvo disposición legal en contrario. Para más información
                sobre el tratamiento de los datos personales (imágenes) captados mediante los sistemas de
                Videovigilancia, los invitamos a revisar la <span class="weight-medium2">“Hoja Informativa sobre el
                  tratamiento de datos personales mediante Sistemas de Videovigilancia”</span> que se adjunta como <span
                  class="weight-medium2"> Anexo III.</span></p>
            </li>
            <li>
              <p class="text-3"> Los datos personales registrados en el banco de datos “Control de
                acceso y gestión de visitas” serán almacenados por un (1) año, para el control de accesos por medidas de
                seguridad. Para más información sobre el tratamiento de los datos personales de los visitantes, los
                invitamos a revisar la <span class="weight-medium2">“Hoja Informativa sobre el tratamiento de datos
                  personales para el control de accesos y gestión de visitas a las Sedes Interbank”</span> que se
                adjunta como <span class="weight-medium2"> Anexo IV.</span></p>
            </li>
          </ul>
        </li>
        <li>
          <p class="text-3 sub-title">Ejercicio de los derechos ARCO</p>
          <p class="text-3">Puedes ejercer tus derechos de acceso, rectificación, cancelación y
            oposición (ARCO) de tus datos personales; así como revocar tu consentimiento para las finalidades
            opcionales, presentando una solicitud en cualquiera de las tiendas de Interbank o solicitar la rectificación
            y/o modificación de sus datos personales a través de nuestra Banca por Internet o llamando a nuestra Banca
            Telefónica al 3119000.</p>
          <p class="text-3">Ten en cuenta que el derecho de rectificación no aplica a las imágenes
            capturadas mediante nuestros sistemas de videovigilancia. Para obtener más información consulta el documento
            <span class="weight-medium2">“Hoja Informativa sobre el tratamiento de datos personales mediante Sistemas
              de Videovigilancia”</span> adjunto como <span class="weight-medium2">Anexo III.</span>
          </p>
          <p class="text-3">Para brindarte una atención adecuada en el ejercicio de tus derechos
            ARCO, hemos preparado un <span class="weight-medium2">"Manual de Atención de los derechos ARCO"</span> que
            detalla el proceso paso a paso. Puedes consultarlo en el <span class="weight-medium2">Anexo IV.</span>
            adjunto.</p>
          <p class="text-3">Si consideras que no hemos atendido tus derechos ARCO de manera adecuada,
            puedes presentar un reclamo ante la Autoridad Nacional de Protección de Datos Personales, dirigiéndose a la
            Mesa de Partes del Ministerio de Justicia y Derechos Humanos: Calle Scipion Llona 350, Miraflores, Lima,
            Perú llenando el formulario publicado en el siguiente enlace: <a
              class="no-text-decoration color-green weight-medium2" target="_blank"
              href="https://www.minjus.gob.pe/wp-content/uploads/2018/12/FORMULARIO-DE-PROCEDIMIENTO-TRILATERAL-DE-TUTELA.pdf">
              https://www.minjus.gob.pe/wp-content/uploads/2018/12/FORMULARIO-DE-PROCEDIMIENTO-TRILATERAL-DE-TUTELA.pdf</a>
          </p>
        </li>
        <li>
          <p class="text-3 sub-title">Colaboradores y Proveedores</p>
          <p class="text-3">En Interbank nos tomamos muy en serio el tratamiento de los datos
            personales; en tal sentido, queremos garantizarte que todas las personas que acceden a tu información
            personal ya sean colaboradores internos o proveedores externos, están sujetas a estrictas obligaciones de
            confidencialidad y cumplen con las regulaciones de protección de datos vigentes. Esto se encuentra
            respaldado por nuestros Códigos de Ética, los cuales establecen principios éticos y normas de conducta que
            todos nuestros colaboradores y proveedores deben seguir. Estos códigos buscan garantizar el respeto a la
            privacidad de todas las personas, considerando la información a la que se tiene acceso a través de nuestras
            actividades.</p>
          <p class="text-3">El incumplimiento de estos principios éticos y normas de conducta
            relacionados con la privacidad puede acarrear consecuencias disciplinarias, como despidos o terminación de
            la relación laboral. Además, en el caso de proveedores, el incumplimiento de las directrices de seguridad y
            confidencialidad puede resultar en la terminación automática del contrato de servicio, descalificación,
            acciones legales y asunción de costos y penalidades. Además de estas medidas disciplinarias detalladas,
            Interbank se reserva el derecho de tomar cualquier medida administrativa o legal que corresponda, según sea
            necesario.</p>
          <p class="text-3">Además, también hemos implementado estrictas medidas de seguridad
            internas para garantizar la confidencialidad, integridad y disponibilidad de los datos personales de
            nuestros colaboradores y proveedores, contando con bancos de datos personales debidamente inscritos en el
            Registro Nacional de Protección de Datos Personales (Códigos 247 y 249) para cada uno de ellos. </p>
        </li>
        <li>
          <p class="text-3 sub-title">Política de Cookies</p>
          <p class="text-3">Las cookies son pequeños archivos de texto generados durante su
            navegación por Internet, utilizados para la autenticación, seguridad y personalización del servicio.</p>
          <p class="text-3">En nuestros canales, es posible que utilicemos cookies. Para obtener más
            información sobre el uso de cookies en www.interbank.pe, te invitamos a consultar nuestras Políticas de
            cookies que se adjunta como <span class="weight-medium2">VI.</span></p>
        </li>
        <li>
          <p class="text-3 sub-title">Modificación en la Política de Privacidad</p>
          <p class="text-3">Interbank se reserva el derecho a modificar, actualizar o completar esta
            Política de Privacidad en cualquier momento y sin previo aviso. Cualquier cambio o modificación a la
            presente Política de Privacidad podrá entrará en vigencia y tendrá efectos frente a los terceros desde su
            publicación en esta página web.</p>
        </li>
        <li>
          <p class="text-3 sub-title">Anexos</p>
          <ul class="list">
            <li>
              <p class="text-3">Anexo I: Lista de Empresas del Grupo Intercorp y socios comerciales.
              </p>
            </li>
            <li>
              <p class="text-3">Anexo II: Lista de Proveedores.</p>
            </li>
            <li>
              <p class="text-3">Anexo III: Hoja Informativa sobre el tratamiento de datos personales
                mediante Sistemas de Videovigilancia.</p>
            </li>
            <li>
              <p class="text-3">Anexo IV: Hoja Informativa sobre el tratamiento de datos personales
                para el control de accesos y gestión de visitas a las Sedes Interbank.</p>
            </li>
            <li>
              <p class="text-3">Anexo V: Manual de Atención de los derechos ARCO</p>
            </li>
            <li>
              <p class="text-3">Anexo VI: Política de Cookies</p>
            </li>
          </ul>
          <p class="text-3">Puedes consultar la información adicional y detallada sobre como tratamos
            sus datos en Política de Privacidad en nuestro sitio web.&nbsp;<a
              class="no-text-decoration color-green weight-medium2" href="https://interbank.pe/avisos-legales"
              target="_blank">https://interbank.pe/avisos-legales.</a></p>
          <p class="text-3 weight-medium2 text-right">Actualizado al mes de julio del 2023.</p>
        </li>
      </ol>
    </div>
  </div>
</div>