import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'formControlErrorMessage'
})
export class FormControlErrorPipe implements PipeTransform {

  transform(errorsObject: ValidationErrors | null, fieldTitle: string, customMessages: Record<string, string> = {}): string | null {
    if (!errorsObject) return null;

    const keys = Object.keys(errorsObject)
    switch (keys[0]) {
      case 'required': return customMessages['required'] ? customMessages['required'] : 'Ingresa tu ' + fieldTitle.toLowerCase()
      case 'notLength': return customMessages['notLength'] ? customMessages['notLength'] : 'El ' + fieldTitle.toLowerCase() + ' debe contar con ' + errorsObject[keys[0]] + ' caracteres'
      case 'isDocument': return customMessages['isDocument'] ? customMessages['isDocument'] : 'El DNI debe contar con 8 caracteres'
      case 'isEmail': return customMessages['isEmail'] ? customMessages['isEmail'] : 'Ingresa un email válido'
      case 'isPhone': return customMessages['isPhone'] ? customMessages['isPhone'] : 'El celular ingresado no es válido'
      case 'codigoInvalido': return customMessages['codigoInvalido'] ? customMessages['codigoInvalido'] : 'El código no es válido'
      default: return  customMessages['_default'] ? customMessages['_default'] : 'Este campo es inválido'
    }
  }

}
