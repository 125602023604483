import { Component, OnInit } from '@angular/core';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PepperService } from 'src/app/services/pepper/pepper.service';
import { environment } from 'src/environments/environment';
import { ApiOfertasService } from 'src/app/services/api-ofertas/api-ofertas.service';
import { cellphoneValidator, DocumentTypes, documentValidator, emailValidator, lengthValidator } from 'src/utils/formControlValidators';
import { ApiPepperBackendService } from 'src/app/services/api-pepper-backend/api-pepper-backend.service';
import { ParametrosService } from 'src/app/services/parametros/parametros.service';
import { OtpService } from 'src/app/services/otp/otp.service';

@Component({
  selector: 'app-recibe-nuevo-codigo',
  templateUrl: './recibe-nuevo-codigo.component.html',
  styleUrls: ['./recibe-nuevo-codigo.component.scss']
})
export class RecibeNuevoCodigoComponent implements OnInit {

  slider_beneficios = []
  slider_paginas: Array<number> = []
  currentBeneficiosAbs = 0;
  legales = ''

  recompraOTPForm: UntypedFormGroup | undefined

  clientInitialValue: any = {
    dni: '',
    email: '',
    operador: undefined,
    celular: '',
    autorizacion: false
  }

  loading: boolean;
  isRecaptchaValid: boolean;
  isRecaptchaTouched: boolean;
  personalDataModal: boolean;
  tycDataModal: boolean;

  recaptcha = {
    enabled: false,
    key: ''
  }

  contentPoliticaDatoPersonal = ''
  contentTerminosyCondiciones = ''

  constructor(
    private pepper: PepperService,
    private parametros: ParametrosService,
    private ofertas: ApiOfertasService,
    private navigate: NavigateService,
    private pepperBackend: ApiPepperBackendService,
    private otp: OtpService
  ) {


    this.loading = false

    if (parametros.config['Recaptcha'] === true) {
      this.isRecaptchaValid = (environment as any)?.overrides?.home?.isRecaptchaValid ? ((environment as any).overrides.home.isRecaptchaValid as boolean) : false;

      this.recaptcha.key = parametros.config['SiteKeyPepper']
      this.recaptcha.enabled = true

    } else {
      this.isRecaptchaValid = true
    }

    this.isRecaptchaTouched = false

    this.personalDataModal = false
    this.contentPoliticaDatoPersonal = parametros.config['PoliticaDatoPersonal']


    this.tycDataModal = false
    this.contentTerminosyCondiciones = parametros.config['TerminosyCondiciones']

    // Overrides
    if ((environment as any)?.overrides?.home?.clientInitialValue) {
      this.clientInitialValue = (environment as any).overrides.home.clientInitialValue
    }
  }

  get operators() {
    return this.parametros.operators
  }

  async handleSubmit() {
    if (this.recompraOTPForm?.valid) {
      this.loading = true

      try {
        const { dni, email, operador, celular } = this.recompraOTPForm?.value

        this.pepper.setClientFormData({
          documentNumber: dni,
          email,
          operador,
          celular,
          documentType: 'DNI'
        })

        await this.ofertas.fetchPersona({
          documentNumber: dni,
          email,
          documentType: '01',
          telephone: celular + ''
        })

        this.pepper.setCurrentPerson()

        if (this.ofertas.userIsCustomer()) {

          if (!this.ofertas.person) throw new Error()

          const user = this.pepper.currentPerson()

          if (user === null) throw new Error('Empty person')

          await this.otp.generateOTP(user.customerId as string, celular + '', operador, email)

          this.navigate.to('nuevo-codigo/enviado')
        } else {
          console.log('Client is no customer')
          this.navigate.toServiceError('ERROR_USUARIO_NO_ES_CLIENTE', false) // TODO: Revisar, nohay error para esto, por sefault se pone servicio
        }
      } catch (error) {
        console.error(error)

        this.navigate.toServiceError('ERROR_RECOMPRA', false)
      } finally {
        this.loading = false
      }

    }
  }

  async handleResolvedCaptcha(token: string | null) {
    if (token === null) {
      this.isRecaptchaValid = false;
      this.isRecaptchaTouched = false;
      return;
    }

    const response = await this.pepperBackend.validateRecaptcha(token)

    if (response.success) {
      this.isRecaptchaValid = true;
    }

    this.isRecaptchaTouched = true
  }

  openPersonalDataModal(e: Event) {
    e.preventDefault()
    this.personalDataModal = true
  }

  openTycDataModal(e: Event) {
    e.preventDefault()
    this.tycDataModal = true
  }

  handlePersonalDataCloseModal() {
    this.personalDataModal = false
  }

  handleTycDataCloseModal() {
    this.tycDataModal = false
  }

  ngOnInit(): void {

    this.recompraOTPForm = new UntypedFormGroup({
      dni: new UntypedFormControl(this.clientInitialValue.dni, [
        Validators.required,
        documentValidator(DocumentTypes.DNI)
      ]),

      email: new UntypedFormControl(this.clientInitialValue.email, [
        Validators.required,
        emailValidator()
      ]),

      operador: new UntypedFormControl(this.clientInitialValue.operador, [
        Validators.required
      ]),

      celular: new UntypedFormControl(this.clientInitialValue.celular, [
        Validators.required,
        lengthValidator(9),
        cellphoneValidator()
      ]),

      autorizacion: new UntypedFormControl(this.clientInitialValue.autorizacion, [])
    });

  }


}
