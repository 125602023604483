import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-horario',
  templateUrl: './error-horario.component.html',
  styleUrls: ['./error-horario.component.scss']
})
export class ErrorHorarioComponent implements OnInit {

  constructor() { }

  from = '7 de la mañana'
  to = '10 de la noche'

  ngOnInit(): void {
  }

}
