<step-indicator [current]="2" title="Ingresa tus datos"></step-indicator>
<sticky-notice-bar *ngIf="!errorCalificacion"></sticky-notice-bar>

<section *ngIf="initializing && !errorCalificacion" class="loader full-width">
    <img src="../../../../assets/preloader.svg" class="center-block">
</section>    

<section *ngIf="!initializing && !errorCalificacion" class="screen-datos-personales">
    <div class="container">
        <form id="formDatosPersonales" *ngIf="datosPersonalesForm" [formGroup]="datosPersonalesForm" (ngSubmit)="handleSubmit()" class="form">
            <div>
                <h3 class="heading-4 weight-light">Datos personales</h3>
                <div class="datos-personales-form">
                    <label>
                        <span>Fecha de nacimiento</span>
                        <input class="input" type="text" formControlName="fechaDeNacimiento" id="inputFechaDeNacimiento" mask="d0/M0/0000" placeholder="DD/MM/AAAA" [leadZeroDateTime]="true">
                    </label>
        
                    <label>
                        <span>Sexo</span>
                        <ng-select [clearable]="false"  class="input" formControlName="sexo" id="inputSexo" >
                            <ng-option *ngFor="let genero of generos" [value]="genero.id">{{genero.name}}</ng-option>
                        </ng-select>
                    </label>
                </div>
                <hr class="separator">
            </div>
    
            <h3 class="heading-4 weight-light">Datos de tu domicilio</h3>
            <div class="datos-domicilio-form">
                <label>
                    <span>Departamento</span>
                    <ng-select [clearable]="false" [loading]="departamentosLoading" (change)="handleChangeDepartamento()" class="input" formControlName="departamento" id="inputDepartamento" >
                        <ng-option *ngFor="let departamento of departamentos" [value]="departamento">{{departamento.ubigeoName}}</ng-option>
                    </ng-select>
                </label>
                
                <label>
                    <span>Provincia</span>
                    <ng-select [clearable]="false" [loading]="provinciasLoading" [disabled]="provinciasLoading" (change)="handleChangeProvincia()" class="input" formControlName="provincia" id="inputProvincia" >
                        <ng-option *ngFor="let provincia of provincias" [value]="provincia">{{provincia.ubigeoName}}</ng-option>
                    </ng-select>
                </label>
                
                <label>
                    <span>Distrito</span>
                    <ng-select [clearable]="false" [loading]="distritosLoading" [disabled]="distritosLoading"  class="input" formControlName="distrito" id="inputDistrito" >
                        <ng-option *ngFor="let distrito of distritos" [value]="distrito">{{distrito.ubigeoName}}</ng-option>
                    </ng-select>
                </label>
                
                <label>
                    <span>Tipo de vía</span>
                    <ng-select [clearable]="false"  class="input" formControlName="tipoDeVia" id="inputTipoDeVia" >
                        <ng-option *ngFor="let via of vias" [value]="via.id">{{via.name}}</ng-option>
                    </ng-select>
                </label>
                
                <label class="nombre-via">
                    <span>Nombre de via</span>
                    <input class="input" type="text" maxlength="100" formControlName="nombreDeVia" id="inputNombreDeVia" >
                    <span class="error-msj">{{datosPersonalesForm.controls['nombreDeVia'].errors | formControlErrorMessage:'nombre De Via'}}</span>
                </label>
    
                <div class="group-detalle">
                    <label>
                        <span>Número</span>
                        <input class="input" type="text" maxlength="5" formControlName="numero" id="inputNumero" >
                        <span class="error-msj">{{datosPersonalesForm.controls['numero'].errors | formControlErrorMessage:'número'}}</span>
                    </label>
                    
                    <label>
                        <span>Manzana</span>
                        <input class="input" type="text" maxlength="10" formControlName="manzana" id="inputManzana" >
                        <span class="error-msj">{{datosPersonalesForm.controls['manzana'].errors | formControlErrorMessage:'manzana'}}</span>
                    </label>
                    
                    <label>
                        <span>Lote</span>
                        <input class="input" type="text" maxlength="10" formControlName="lote" id="inputLote" >
                        <span class="error-msj">{{datosPersonalesForm.controls['lote'].errors | formControlErrorMessage:'lote'}}</span>
                    </label>
                    
                    <label>
                        <span>Interior</span>
                        <input class="input" type="text" maxlength="10" formControlName="interior" id="inputInterior" >
                    </label>
                    
                </div>
            </div>
    
            <button id="submitButton" type="submit" class="btn fw--mobile center-block" [disabled]="datosPersonalesForm.invalid || loading">Continuar</button>
        </form>
    </div>
</section>

<section *ngIf="!errorCalificacion" class="text-legales">
    <div class="container">
        <small>{{txtLegales}}</small>
    </div>
</section>

<section *ngIf="errorCalificacion">
    <app-error-ofertas [message]="errorCalificacionMessage" [load]="loadCalificacion" (retry)="tryAgain()"></app-error-ofertas>
</section>