export const CARACTERISTICAS_DEBITO = [
    {
      icono: '../../../../assets/icon/debito/carac-transf-debito.svg',
      titulo: 'Transfiere gratis',
      descripcion: 'Entre tus cuentas o a otros bancos cuando lo necesites'
    },
    {
      icono: '../../../../assets/icon/debito/carac-compra-debito.svg',
      titulo: 'Compra al instante',
      descripcion: 'Accede a una Tarjeta Digital en 4 pasos con la que podrás realizar tu compra HOY'
    },
    {
      icono: '../../../../assets/icon/debito/carac-retira-debito.svg',
      titulo: 'Retira efectivo sin tarjeta',
      descripcion: 'Para que tú o tus contactos puedan retirarlo desde cualquier cajero <strong>GlobalNet.</strong>'
    },
    {
      icono: '../../../../assets/icon/debito/carac-paga-debito.svg',
      titulo: 'Paga servicios y tarjetas sin comisión',
      descripcion: 'Los recibos de tus servicios y tarjeta de credito, cuantas veces quieras desde tu Interbank APP'
    },
  ];

export const CARACTERISTICAS_CREDITO = [
    {
      icono: '../../../../assets/icon/debito/carac-compra-debito.svg',
      titulo: 'Compra al instante',
      descripcion: 'Accede a una Tarjeta Digital en 4 pasos con la que podrás realizar tu compra HOY'
    },
    {
      icono: '../../../../assets/icon/debito/membresia.svg',
      titulo: 'Membresía gratuita',
      descripcion: 'Solo tienes que usar tu tarjeta todos los meses ¡No hay monto mínimo!'
    },
    {
      icono: '../../../../assets/icon/debito/descuento.svg',
      titulo: 'Descuentos exclusivos',
      descripcion: 'En conciertos, moda, restaurantes y muchas categorías más'
    },
  ];

export const TARIFAS_DEBITO = [
    {
      titulo: '¿Existe algún costo de membresía?',
      descripcion: 'Tenemos dos opciones de Tarjeta de Débito:<br><br><strong> Visa Débito Benefit:</strong> Costo de membresía S/4.50 o $2 al mes.<br><br><strong>Visa Débito Clásica:</strong> Sin costo de membresía'
    },
    {
      titulo: '¿Me cobran comisión por comprar y/o pagar con mi tarjeta de Débito Clásica?',
      descripcion: 'No, no existen comisiones adicionales.<br>El monto consumido con tu Tarjeta de Débito es exactamente igual al monto que pagas si lo hicieras en efectivo.'
    }
  ];

export const TARIFAS_CREDITO = [
    {
      titulo: '¿A que tasa de interés aplico?',
      descripcion: 'La tasa de interés puede variar acorde al tipo de tarjeta y a la calificación que tenga el cliente.'
    },
    {
      titulo: '¿Es obligatorio pagar el cobro de membresía?',
      descripcion: 'Sí, es obligatorio. Pero puedes exonerarte de ella realizando como mínimo, 1 consumo en cada mes del año.'
    },
    {
      titulo: '¿Qué es el Seguro de Desgravamen?',
      descripcion: 'El Seguro de Desgravamen es un seguro de vida que cubre el pago de tu deuda en caso de fallecimiento o invalidez total y permanente.'
    },
    {
      titulo: 'Si consumo con mi Tarjeta de Crédito y pago al instante ¿me cobrarán el Seguro de Desgravamen?',
      descripcion: 'Dependerá si tienes saldo pendiente de pago al día de cierre de tu facturación. Si se pagan todos los consumos al instante y no tienes ningún tipo de deuda al cierre de tu facturación, no se realizará el cobro por Seguro de Desgravamen.'
    }
  ];

export const PREGUNTAS = [
    {
      titulo: '¿Cómo realizó la compra?',
      respuesta: 'Te brindamos los datos de tu tarjeta para que puedas culminar tu compra online, en caso obtuviste tu tarjeta a través del QR de un comercio físico, te brindaremos un código con el que podrás pagar en caja.',
      visible: true,
    },
    {
      titulo: '¿Cómo recibo mi tarjeta física?',
      respuesta: 'Te enviaremos tu tarjeta física a la dirección indicada en un plazo de 3 a 7 días, previo a ello te contactaremos para validar tus horarios.',
      respuestaDebito: 'Acércate a cualquiera de nuestras tiendas Interbank para obtener tu Tarjeta física.',
      visible: true,
    },
    {
      titulo: '¿Puedo realizar más de una compra?',
      respuesta: 'Podrás realizar una única compra con la Tarjeta Digital hasta que recibas tu Tarjeta Física.',
      visible: true,
    },
    {
      titulo: '¿Cómo puedo realizar el pago de mi tarjeta?',
      tituloDebito: '¿Qué pasa si se me pierde, deteriora y/o sufro el robo de mi Tarjeta de Débito?',
      respuesta: 'Una vez recibas tu tarjeta física podrás realizar el pago a través de cualquiera de nuestros canales',
      respuestaDebito: 'Debes comunicarte inmediatamente con nuestra Banca Telefónica para bloquearla y acércate a cualquiera de nuestras Tiendas Interbank en el ámbito nacional para solicitar su reposición.',
      visible: true,
    },
  ];