<section>
    <step-indicator [current]="extraStep ? 3 : 2" title="Elige tu tarjeta"></step-indicator>
    <sticky-notice-bar></sticky-notice-bar>

    <section *ngIf="loading" class="loader full-width">
        <img src="../../../../assets/preloader.svg" class="center-block">
    </section>    
    
    <section [ngClass]="{'hidden': loading}" class="seleccion-tarjeta-screen">
        <h3><strong>Hola {{ personName | ucwords }}</strong>, escoge tu tarjeta de crédito</h3>
        <div class="wrapper keen-slider" #cardsWrapper>
            <div class="keen-slider__slide" *ngFor="let offer of offers" [id]="'card_' + offer.cardCode">
                <!-- <div class="card" [class.millas]="card.bono_millas"> -->
                <div class="card">
                    <!-- <img src="../../../assets/card.png"> -->
                    <img *ngIf="cardData[offer.cardCode]" [src]="chAssetUrl + cardData[offer.cardCode].onboarding_image.url">
                    <div class="name_wrapper">
                        <p class="name">{{ offer.nombreTipo | titlecase }}</p>
                    </div>
    
                    <small>Línea de crédito hasta de</small>
    
                    <p class="amount">S/ {{ offer.monto | mask: 'separator' }}</p>
    
                    <button class="btn select-card-btn" (click)="seleccionarTarjeta(offer, cardData[offer.cardCode])">La quiero</button>

                    <div *ngIf="cardData[offer.cardCode]">
                        <span *ngFor="let benefit of cardData[offer.cardCode].onboarding_benefits.values.slice(0,3)">
                            <strong class="detail-category">{{ benefit.benefit_title.value }}</strong>
                            <p class="detail">{{ benefit.benefit_description.value }}</p>
                        </span>
                    </div>
    
                    <strong class="detail-category">SEGURO DESGRAVAMEN</strong>
                    <p class="detail">Se calcula multiplicando el saldo promedio diario por 0.39% Máx. S/20 o US$5.</p>
    
                    <strong class="detail-category">TEA</strong>
                    <p class="detail">{{ offer.tea }} %</p>
                </div>
            </div>
        </div>
    </section>
</section>
<section class="text-legales">
    <small>{{ legales }}</small>
</section>