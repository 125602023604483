import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biometria-loading',
  templateUrl: './biometria-loading.component.html',
  styleUrls: ['./biometria-loading.component.scss']
})
export class BiometriaLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
