export const VIAS = [
    { id: 'AG', name: 'AGRUPACION' },
    { id: 'AH', name: 'ASENTAMIENTO_HUMANO' },
    { id: 'AL', name: 'ALAMEDA' },
    { id: 'AN', name: 'ANEXO' },
    { id: 'AS', name: 'ASOCIACION' },
    { id: 'AV', name: 'AVENIDA' },
    { id: 'BA', name: 'BAJADA' },
    { id: 'BR', name: 'BARRIO' },
    { id: 'CC', name: 'COMUNIDAD_CAMPESINA' },
    { id: 'CH', name: 'CONJUNTO_HABITACIONAL' },
    { id: 'CI', name: 'COMUNIDAD_INDIGENA' },
    { id: 'CL', name: 'CALLE' },
    { id: 'CM', name: 'CAMPAMENTO_MINERO' },
    { id: 'CO', name: 'COOPERATIVA' },
    { id: 'CP', name: 'CENTRO_POBLADO' },
    { id: 'CT', name: 'CARRETERA' },
    { id: 'FU', name: 'FUNDO' },
    { id: 'GA', name: 'GALERIA' },
    { id: 'HU', name: 'HABILITACION_URBANA' },
    { id: 'IB', name: 'INTERBANK' },
    { id: 'JR', name: 'JIRON' },
    { id: 'LO', name: 'LOTIZACION' },
    { id: 'MA', name: 'MALECON' },
    { id: 'OV', name: 'OVALO' },
    { id: 'PA', name: 'PASEO' },
    { id: 'PJ', name: 'PASAJE' },
    { id: 'PL', name: 'PLAZA' },
    { id: 'PN', name: 'PUEBLO_JOVEN' },
    { id: 'PO', name: 'PORTAL' },
    { id: 'PQ', name: 'PARQUE' },
    { id: 'PR', name: 'PROLONGACION' },
    { id: 'PZ', name: 'PLAZUELA' },
    { id: 'RE', name: 'RESIDENCIAL' },
    { id: 'RM', name: 'RAMAL' },
    { id: 'SD', name: 'SIN_DENOMINACION' },
    { id: 'SR', name: 'SUN_RAMAL' },
    { id: 'UA', name: 'UNIDAD_AGRARIA' },
    { id: 'UR', name: 'URBANIZACION' },
    { id: 'UV', name: 'UNIDAD_VECINAL' },
    { id: 'ZN', name: 'MONUMENTAL' }
];