import { Component, OnInit } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { NavigateService } from "src/app/services/navigate/navigate.service";
import { ReportesService } from "src/app/services/reportes/reportes.service";
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
    selector: 'app-ofertas-fallo-definitivo',
    templateUrl: './ofertas-fallo-definitivo.component.html',
    styleUrls: ['./ofertas-fallo-definitivo.component.scss']
  })
export class OfertasFalloDefinitivoComponent implements OnInit {

  private data

  get logo (): string {
    if(typeof this.data['logo'] !== 'string') return ''
    return this.data['logo']
  }

  get contact (): string {
    if(typeof this.data['contact'] !== 'string') return ''
    return this.data['contact']
  }

  get description (): string {
    if(typeof this.data['description'] !== 'string') return ''
    return this.data['description']
  }

  constructor(
    private navigate: NavigateService,
    private reporte: ReportesService,
    private utils: UtilsService) 
    { 
      this.data = this.navigate.getCurrentRouteData()
    }

    async ngOnInit(): Promise<void> {
      if (!this.data['codigo_error']) return;
  
      await this.reporte.addToReport({
        properties: {
          pagina: this.data['codigo_error'],
          device: this.utils.detectBrowser()
        }
      })
    }
  
    reloadApp(): void {
      window.location.reload()
    }

}