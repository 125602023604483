import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ibk-footer',
  templateUrl: './ibk-footer.component.html',
  styleUrls: ['./ibk-footer.component.scss']
})
export class IbkFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
