import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiCalificacionService } from 'src/app/services/api-calificacion/api-calificacion.service';
import { ApiOfertasService } from 'src/app/services/api-ofertas/api-ofertas.service';
import { PepperService } from 'src/app/services/pepper/pepper.service';
import { environment } from 'src/environments/environment';
import { Ubigeo } from 'src/app/services/parametros/responses/Ubigeo';
import { ParametrosService } from 'src/app/services/parametros/parametros.service';
import { padStart } from 'lodash-es';
import { NavigateService } from 'src/app/services/navigate/navigate.service';
import { UnqualifiedProposalError } from 'src/app/errors/UnqualifiedProposalError';
import { alphanumericExtended, alphanumericExtended1, numeric, validBirthdate } from 'src/utils/formControlValidators';
import { CurrentPersona } from 'src/app/services/pepper/responses/CurrentPersona';
import { PostalAddress } from 'src/app/services/api-ofertas/responses/OfertasResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import moment from 'moment';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { VIAS } from 'src/app/common/constants/vias.constant';


@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.scss']
})
export class DatosPersonalesComponent implements OnInit {

  datosPersonalesForm: FormGroup | undefined;

  departamentos: Ubigeo[] = []
  departamentosLoading: boolean = false

  provincias: Ubigeo[] = []
  provinciasLoading: boolean = false

  distritos: Ubigeo[] = []
  distritosLoading: boolean = false

  loading = false
  initializing = true

  loadCalificacion = false
  errorCalificacion = false
  intentosCalificacion = 0
  errorCalificacionMessage = 'Hemos tenido un error al cargar el resultado de tu calificación, por favor inténtalo de nuevo.'

  get isCustomer(): boolean {
    const person = this.ofertas.getPerson()
    return !!person.customerId
  }

  get txtLegales() {
    return this.backend.getContentData()?.partner_legal['value']
  }

  constructor(
    private backend: PepperService,
    private ofertas: ApiOfertasService,
    private calificacion: ApiCalificacionService,
    private parametros: ParametrosService,
    private nav: NavigateService,
    private pepper: PepperService,
    private reporte: ReportesService,
    private utils: UtilsService
  ) { }

  generos = [
    {
      id: 'M',
      name: 'Masculino'
    },
    {
      id: 'F',
      name: 'Femenino'
    },
  ]

  vias = VIAS;

  private async handleBypassScreens(person: CurrentPersona, address: PostalAddress) {
    this.pepper.setPersonalData({
      birthday: person.birthdate,
      genre: person.genderType,
    })

    try {
      await this.calificacion.qualify(
        address.geographicalLocation,
        address.deparmentCode,
        address.provinceCode,
        address.districtCode,
      )
      this.errorCalificacion = false
      await this.reporte.addToReport({
        req_status: 'Proceso',
        properties: {
          flagCalificacion: 'SI',
          pagina: 'Calificación',
          device: this.utils.detectBrowser()
        }
      })
      this.nav.to('usuario/selecciona-tarjeta')
    } catch (error) {
      console.error(error)
      if (error instanceof UnqualifiedProposalError) {

        await this.reporte.addToReport({
          req_status: 'Proceso',
          properties: {
            flagCalificacion: 'NO',
            pagina: 'Calificación',
            device: this.utils.detectBrowser()
          }
        })

        this.nav.to('usuario/no-califica')
      } else if (error instanceof HttpErrorResponse) {
        if (error.error?.errorMessage && /(NO_CALIFICA_PERSONA|NO_CALIFICA_PRODUCTO)/.test(error.error?.errorMessage)) {
          await this.reporte.addToReport({
            req_status: 'Proceso',
            properties: {
              flagCalificacion: 'NO',
              pagina: 'Calificación',
              device: this.utils.detectBrowser()
            }
          })
          this.nav.to('usuario/no-califica')
        } else if (error.error?.errorDetail && /No retornó número de expediente/.test(error.error?.errorDetail)) {
          this.nav.toServiceError('ERROR_EXPEDIENT_NUMBER')
        } else {
          this.errorCalificacion = true
          if (this.intentosCalificacion > 1) this.nav.toServiceError('ERROR_API_CALIFICACION')
          this.intentosCalificacion++

          this.loadCalificacion = false
        }
      } else {
        this.errorCalificacion = true
        if (this.intentosCalificacion > 1) this.nav.toServiceError('ERROR_API_CALIFICACION')
        this.intentosCalificacion++

        this.loadCalificacion = false
      }
    }
  }

  async ngOnInit() {

    const currentPerson = this.pepper.currentPerson()
    if (currentPerson) {
      const firstAddress = currentPerson.postalAddress.find((address: PostalAddress) => {
        return address.standardizedAddressFlag === 'S'
      })

      if (firstAddress !== undefined) {
        this.handleBypassScreens(currentPerson, firstAddress)
        return;
      }
    }

    this.initializing = false

    let initialData = {
      departamento: '',
      provincia: '',
      distrito: '',
      tipoDeVia: '',
      nombreDeVia: '',
      numero: '',
      manzana: '',
      lote: '',
      interior: '',
      primerNombre: '',
      segundoNombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      fechaDeNacimiento: this.formatDateForMask(currentPerson?.birthdate as string),
      sexo: !!currentPerson?.genderType ? currentPerson.genderType : '',
    }

    if (environment.overrides && environment.overrides['personalData']) {
      initialData = environment.overrides['personalData']
    }

    let controls = {
      departamento: new FormControl(initialData['departamento'], [
        Validators.required
      ]),
      provincia: new FormControl(initialData['provincia'], [
        Validators.required
      ]),
      distrito: new FormControl(initialData['distrito'], [
        Validators.required
      ]),
      tipoDeVia: new FormControl(initialData['tipoDeVia'], [
        Validators.required
      ]),
      nombreDeVia: new FormControl(initialData['nombreDeVia'], [
        Validators.required,
        alphanumericExtended1(),
        Validators.maxLength(100)
      ]),
      numero: new FormControl(initialData['numero'], [
        Validators.required,
        numeric(),
        Validators.maxLength(6)
      ]),
      manzana: new FormControl(initialData['manzana'], [
        alphanumericExtended(),
        Validators.maxLength(10)
      ]),
      lote: new FormControl(initialData['lote'], [
        alphanumericExtended(),
        Validators.maxLength(10)
      ]),
      interior: new FormControl(initialData['interior'], [
        alphanumericExtended(),
        Validators.maxLength(10)
      ]),
      fechaDeNacimiento: new FormControl({
        value: initialData['fechaDeNacimiento'],
        disabled: !!currentPerson?.birthdate
      }, [
        Validators.required,
        validBirthdate()
      ]),
      sexo: new FormControl({
        value: initialData['sexo'],
        disabled: !!currentPerson?.genderType
      }, [
        Validators.required
      ])
    } as Record<string, FormControl>


    this.datosPersonalesForm = new FormGroup(controls)

    this.departamentosLoading = true
    this.departamentos = await this.parametros.getDepartamentos()
    this.departamentosLoading = false

    await this.parametros.populateCardNames()
  }

  private formatDateForMask(str: string) {
    try {
      const parts = str.split('-')
      return `${parts[2]}${parts[1]}${parts[0]}`
    } catch (e) {
      return ''
    }
  }

  private formatDateForStore(str: string) {
    try {
      const date = str.substring(0, 2)
      const month = str.substring(2, 4)
      const year = str.substring(4, 8)
      return `${year}-${month}-${date}`
    } catch (e) {
      return ''
    }
  }

  private getForm() {
    const form = this.datosPersonalesForm
    if (!form) throw new Error('The form is not initialized')
    return form
  }

  private getControl(controlName: string) {
    const form = this.getForm()

    const control = form.get(controlName)
    if (!control) throw new Error('Control ' + controlName + ' not found')

    return control
  }

  private parseUbigeoId(id: string | number) {
    return padStart(id + '', 2, '0')
  }

  private resetControl(controlName: string) {
    const control = this.getControl(controlName)
    control.setValue('')
    control.markAsPristine()
  }

  async handleChangeDepartamento() {
    const currentControl = this.getControl('departamento')
    this.resetControl('provincia')
    this.resetControl('distrito')

    this.provinciasLoading = true
    this.provincias = await this.parametros.getProvincias(this.parseUbigeoId(currentControl.value.ubigeoDepartmend))
    this.provinciasLoading = false
  }
  async handleChangeProvincia() {

    const prevControl = this.getControl('departamento')
    const currentControl = this.getControl('provincia')
    this.resetControl('distrito')


    this.distritosLoading = true
    this.distritos = await this.parametros.getDistritos(this.parseUbigeoId(prevControl.value.ubigeoDepartmend), this.parseUbigeoId(currentControl.value.ubigeoProvince))
    this.distritosLoading = false
  }

  async handleSubmit() {
    const form = this.getForm()

    if (form.valid) {
      const values = form.value
      const departamentoControl = this.getControl('departamento')
      const provinciaControl = this.getControl('provincia')
      const distritoControl = this.getControl('distrito')

      this.pepper.setPersonalData({
        birthday: this.formatDateForStore(values.fechaDeNacimiento),
        genre: values.sexo,
      })

      this.pepper.addAddress({
        department: departamentoControl.value.ubigeoName,
        province: provinciaControl.value.ubigeoName,
        district: distritoControl.value.ubigeoName,
        via: this.utils.removeSpecialCharacters(values.tipoDeVia),
        address: this.utils.removeSpecialCharacters(values.nombreDeVia),
        number: this.utils.parseNumberVia(values.numero).toString(),
        ubigeo: distritoControl.value.ubigeoCode,
        int: values.interior = '' ? undefined : values.interior,
        lt: values.lote = '' ? undefined : values.lote,
        mz: values.manzana = '' ? undefined : values.manzana
      })

      try {
        this.loading = true

        if (environment.overrides && environment.overrides['personalData']) {
          await this.calificacion.qualify(
            environment.overrides['personalData']['ubigeo'],
            environment.overrides['personalData']['departamento'],
            environment.overrides['personalData']['provincia'],
            environment.overrides['personalData']['distrito'],
          )
        } else {
          await this.calificacion.qualify(
            distritoControl.value.ubigeoCode,
            departamentoControl.value.ubigeoName,
            provinciaControl.value.ubigeoName,
            distritoControl.value.ubigeoName,
          )
          this.errorCalificacion = false
        }


        await this.reporte.addToReport({
          req_status: 'Proceso',
          properties: {
            flagCalificacion: 'SI',
            pagina: 'Calificación',
            device: this.utils.detectBrowser()
          }
        })


        this.nav.to('usuario/selecciona-tarjeta')
      } catch (error) {
        if (error instanceof UnqualifiedProposalError) {

          await this.reporte.addToReport({
            req_status: 'Proceso',
            properties: {
              flagCalificacion: 'NO',
              pagina: 'Calificación',
              device: this.utils.detectBrowser()
            }
          })

          this.nav.to('usuario/no-califica')
        } else if (error instanceof HttpErrorResponse) {

          if (error.error?.errorMessage && /(NO_CALIFICA_PERSONA|NO_CALIFICA_PRODUCTO)/.test(error.error?.errorMessage)) {
            await this.reporte.addToReport({
              req_status: 'Proceso',
              properties: {
                flagCalificacion: 'NO',
                pagina: 'Calificación',
                device: this.utils.detectBrowser()
              }
            })
            this.nav.to('usuario/no-califica')
          } else if (error.error?.errorDetail && /No retornó número de expediente/.test(error.error?.errorDetail)) {
            this.nav.toServiceError('ERROR_EXPEDIENT_NUMBER')
          } else {
            this.errorCalificacion = true
            if (this.intentosCalificacion > 1) this.nav.toServiceError('ERROR_API_CALIFICACION')
            this.intentosCalificacion++

            this.loadCalificacion = false
          }
        } else {
          this.errorCalificacion = true
          if (this.intentosCalificacion > 1) this.nav.toServiceError('ERROR_API_CALIFICACION')
          this.intentosCalificacion++

          this.loadCalificacion = false
        }
      }


      try {
        const currentPersona = this.pepper.currentPerson()
        if (currentPersona === undefined) throw new Error('No person data found')

        const flowName = this.pepper.getSelectedFlow()
        if (flowName === undefined) throw new Error('No flow Name data found')

        const storeName = this.pepper.getSelectedStore()
        if (storeName === undefined) throw new Error('No store data found')

        const cu_at = moment().format('YYYY-MM-DD HH:mm:ss')

      } catch (e) {
        this.nav.toServiceError('ERROR_REGISTRANDO_REPORTE')
      }

      this.loading = false
    }
  }

  async tryAgain() {
    this.loadCalificacion = true
    if (this.isCustomer) {
      await this.ngOnInit()
    } else {
      await this.handleSubmit()
    }
  }

}
