import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

@Component({
    selector: 'app-error-ofertas',
    templateUrl: './error-ofertas.component.html',
    styleUrls: ['./error-ofertas.component.scss']
  })
export class ErrorOfertasComponent implements OnChanges {
  
  @Input() message?: string;
  @Input() imagen?: string = '../../../assets/icon/error/error-ofertas.svg';
  @Input() load: boolean = false;
  @Input() boton: string = 'Volver a cargar'

  @Output('retry') clickActionButtonEvent = new EventEmitter<void>();

  handleActionButtonClick () {
    this.clickActionButtonEvent.emit()
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

}